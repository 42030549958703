import { createI18n } from 'vue-i18n';

const messages = {
  en: {
    login: {
      title: 'Sign in',
      email: 'Email address',
      password: 'Password',
      rememberMe: 'Remember me',
      forgotPassword: 'Forgot password?',
      signInButton: 'Sign-in',
      readyToUseAccounts: "Access your ready-to-use LinkedIn accounts and start prospecting today",
      satisfiedUsers: "Satisfied Users",
      activeAccounts: "Active Accounts",
      safetyGuarantee: "Safety guarantee",
      connections: "Connections",
      review1: "I use it to prospect on behalf of my clients. Very simple and practical solution allowing me to have LinkedIn accounts for the duration of the service, directly usable and customizable according to my needs. Since then, I save time and energy trying to get access to employees' LinkedIn accounts.",
      accountsNumber1: "Number of accounts <strong>10</strong>",
      verticalizeAccounts: "Verticalize accounts per clients",
      dedicatedAccount: "Use dedicated account",
      review2: "I use this tool for my outbound campaigns to protect my colleagues' LinkedIn accounts (already banned). The profiles are warm, customizable, and tailored to my personas. Contacts don't suspect anything. I can share them with my SDR team without issue, thanks to Gologin. Responsive and friendly team. Essential for scaling efficiently!",
      accountsNumber2: "Number of accounts <strong>14</strong>",
      scaleVolume: "Scale prospecting volume",
      secureOutbound: "Secure LinkedIn Outbound",
      enterEmail: "Enter your email so that we can send you a password reset link.",
      emailPlaceholder: "Enter your email",
      sendEmail: "Send Email",
      backToLogin: "Back to Login",
      "email_sent": "An email has been sent to your inbox. Please check.",
      "email_error": "An error occurred while sending the email. Please try again.",
      "password_updated": "Your password has been successfully updated.",
    },
    MainLayout: {
        accounts: 'Accounts',
        billing: 'Billing',
        developer: 'Developer',
        help: 'Help',
        academy: 'Academy',
        settings: 'Settings',
        language: 'Language',
        logout: 'Logout',
        retour: 'Back'
      },
      accounts: {
        title: 'Accounts',
        totalAccounts: '{count} accounts',
        getNewAccounts: 'GET NEW ACCOUNTS',
        filter: 'Filter',
        sortBy: 'Sort by',
        launchSession: 'Launch session',
        strike: 'Strike',
        detailsTitle: 'Details',
        fullName: 'Full name:',
        company: 'Company:',
        jobTitle: 'Jobtitle:',
        numberOfConnections: 'Number of connections:',
        email: 'Email:',
        password: 'Password:',
        subscriptionDate: 'Subscription date:',
        urlLinkedIn: 'Url LinkedIn:',
        viewLinkedInProfile: 'Link',
        actionsTitle: 'Actions',
        get2FA: 'Get the 2FA',
        invalidEmail: "Only for gmail are allowed.",
        testProxy: 'Test the proxy',
        proxyResponseTime: "response time",
        proxyError: "Error testing proxy",
        reportProblem: 'Report a problem',
        addAutomationTool: 'Add automation tool',
        removeFromSubscription: 'Remove from subscription',
        addAutomationTitle: "Add an Automation Tool",
        selectTool: "Select a Tool",
        submit: "Submit",
        cancel: "Cancel",
        reportProblemTitle: "Report a Problem",
        problemType: "Type of Problem",
        problemDescription: "Description",
        problemDescriptionPlaceholder: "Provide additional details about the problem...",  
        automationSuccess: "Automation tool added successfully!",
        automationError: "Failed to add automation tool.",
        problemSuccess: "Problem reported successfully!",
        problemError: "Failed to report the problem.",
        linksSent: "Links sent",
        connectionRequests: "Number of connection requests",
        profileVisits: "Number of profile visits",
        autoProfileVisits: "Auto profile visits?",
        automationToolUsed: "Automation tool used",
        enterNumber: "Enter a number",
        problemTypes: {
            inaccessibleAccount: "Account not accessible",
            gologinIssue: "GoLogin Issue",
            accountDisconnected: "Account Disconnected",
            other: "Other",
        },
        pendingAccount: "Pending account",
        preparingAccount: "Preparing...",
        sortAsc: 'A-Z',
        sortDesc: 'Z-A',
        addedSoon: 'Added soon',
        replaceAccountTitle: "Replace Account",
        replaceAccountInstruction: "Fill this form to request a replacement for your account",
        linksSentQuestion: "Have you sent external links in your LinkedIn messages?",
        manualOrAutomatic: "If yes, was it manually or automatically?",
        manual: "Manual",
        automatic: "Automatic",
        stopSubscriptionTitle: 'When do you want to stop the subscription?',
        renewalDate: 'Renewal date',
        removeOption: 'Select removal option',
        removeNow: 'Now',
        removeEndOfMonth: 'At the end of the month',
        removeWarning: 'Cancellations can take effect immediately or at the end of your subscription period. Current month\'s fees are non-refundable.',
        remove: 'Remove',
        subscriptionRemoved: 'Subscription successfully removed.',
        subscriptionRemovedNow: "The subscription has been removed immediately.",
        subscriptionRemovedEnd: "The subscription will be removed at the end of the current period.",
        subscriptionRemoveError: "An error occurred while removing the subscription.",
        noAccountSelected: "No account selected.",
        Search: "Search...",
        Replace: "Replace",
        back: "Back",
        filterLocation: "Location",
        filterGender: "Gender",
        allLocations: "All",
        europe: "Europe",
        usa: "USA",
        allGenders: "All",
        male: "Male",
        female: "Female",
        modalreasonLabel: "Reason for request",
        modaltitle: "Please complete this form to explain why you need access to the password.",
        proxyDetails: "Proxy details",
        viewproxyDetails: "See",
        proxyTextInfo: "Discover the details of your proxies in your favorite tools",
        "filterStatus": "Filter by Status",
        "salesNav": "Sales Navigator",
        "pending": "Pending",
        stopSubscriptionSureDesc: "This action is irreversible and will permanently revoke access for this account. Please note that any month started is payable in full.",
        stopSubscriptionSure: "Are you sure you want to remove this account from your subscription ?",
        automationSuccessDesc: "We will get back to you as soon as the automation is set up !",
        SuccessDesc: "We will contact you as soon as possibleProfile",
        automationSuccessTitle: "We have taken your request into account",
        "ticketError": "An error occurred while submitting the ticket. Please try again.",
        "ticketSuccess": "The ticket was successfully created!",
        "fillAllFields": "Please fill in all required fields before submitting.",
        proxySlowWarning: "We have received your report and are working to resolve the issue as soon as possible. You will be notified by email as soon as the proxy is corrected. Thank you for your patience.",
        descriptionOptional: "optional",
        "automationTool": "Automation tool",
        "noAutomationTool": "Not set",
        "cancelResiliation": "End of subscription: {resiliationDate}",
        "cancelremove": "Cancel removal",
        "subscriptionCancelRemovedEnd": "Your cancellation has been successfully reversed. You will continue to enjoy your accounts!",
        "subscriptionCancelRemovedEndError": "An error occurred while reversing the cancellation. Please try again or contact our customer service if the issue persists.",
        "stopCancelSubscriptionSure": "Are you sure you want to reverse the cancellation of this account in your subscription?",
        "stopCancelSubscriptionSureDesc": "This action will cancel the termination request and allow you to continue enjoying this account at the next renewal.",
        "compte" : "Account:",
        "copy_success": "Copied to clipboard!",
        "copy_error": "Unable to copy to clipboard.",
        "copy_unsupported": "Copy function is not supported by your browser.",
        CannotReplace: "Replacement pending"
      },
      common: {
            "yes": "Yes",
            "no": "No"
        },
      settings: {
        parameters: 'Parameters',
        myAccount: 'My Account',
        email: 'Email',
        password: 'Password',
        company: 'Company',
        language: 'Language',
        integrations: 'Integrations',
        apiKey: 'API Key',
        show: 'Show',
        hide: 'Hide',
        logout: 'Log out',
        "editName": "Edit Company Name",
        "newName": "New Name",
        "namePlaceholder": "Enter a new name",
        "cancel": "Cancel",
        "save": "Save",
        "languageUpdateSuccess": "Language updated successfully.",
        "languageUpdateError": "Error updating account language.",
        "nameUpdateSuccess": "Name updated successfully.",
        "nameUpdateError": "Error updating name."     
      },
      academy: {
        title: 'Academy',
        description:
          'Master LinkedIn prospecting with our academy: Learn from Frédéric, our co-founder and LinkedIn expert, to become a true expert in LinkedIn outreach and make the most of MirrorProfiles',
        accessButton: 'Access the training',
        moduleTitle: 'Video module: {title}',
        modules: {
          1: {
            title: 'Boost your Linkedin acquisition',
            description:
              'With over 6 years of experience in Linkedin prospecting, Frédéric, co-founder of MirrorProfiles, shares strategies, tips, and advice to maximize your Linkedin accounts usage. Get ready to learn a lot about Linkedin prospecting 🚀',
          },
          2: {
            title: 'Become an expert in Linkedin copywriting',
            description:
              'Being able to send thousands of messages on Linkedin is great! But with the right message, it makes all the difference. In this video, Frédéric shares examples of effective prospecting messages that convert. Ready your keyboards and prepare to understand the mysteries of an effective Linkedin campaign ⌨',
          },
          3: {
            title: 'Linkedin KPIs. What goals?',
            description:
              'Do I have a good acceptance rate, response rate? In short, is my Linkedin campaign performing well? Frédéric shares the right statistics to aim for on Linkedin and, of course, reveals his secrets to achieving your goals, both quantitative and especially qualitative. Learn how to analyze and improve your Linkedin campaigns 💪',
          },
          4: {
            title: 'Build a prospecting file with SalesNavigator',
            description:
              'The core of prospecting? It is the data, and it is the one topic where everyone agrees. To have quality prospect files, you need to learn the subtleties of Linkedin SalesNavigator. Ready to master SalesNavigator filters like a pro? Frédéric shares all his tips on this tool 🎯',
          },
          5: {
            title: 'Learn to handle responses on Linkedin',
            description:
              "Don't be too salesy, that's rule number one for prospecting on Linkedin. You need to reply to all prospects, whether they're interested, undecided, or not. In this training, Frédéric shows how to increase your meeting rate through simple but effective tips. Convert more prospects into quality client meetings. Let's go! 📈",
          },
          6: {
            title: 'The art of using MirrorProfiles autonomously',
            description:
              'Like any growth hacking tool, using MirrorProfiles autonomously requires a short training. In just a few minutes, Frédéric shares everything you need to know about our tool. Accelerate your Linkedin prospecting by multiplying the number of Linkedin accounts with MirrorProfiles.',
          },
        }
      },
      "problemTypes": {
        "accountInaccessible": "Account inaccessible",
        "gologinIssue": "GoLogin Issue",
        "accountDisconnection": "Account disconnection",
        "linkedinEmailIssue": "Email inaccessible on LinkedIn",
        "other": "Other"
      },
      routes: {
        "accounts": {
          "title": "Client Area - Manage your LinkedIn accounts with MirrorProfiles",
          "description": "Access all your MirrorProfiles accounts in your client area. View details, manage settings, and take necessary actions for effective and secure usage."
        },
        "academy": {
          "title": "MirrorProfiles Academy | Master LinkedIn and Boost Your Prospecting",
          "description": "Become an expert in LinkedIn prospecting with the MirrorProfiles Academy. Access exclusive training to learn how to use your accounts effectively, improve your campaigns, and maximize your results."
        },
        "settings": {
          "title": "Account Settings | MirrorProfiles",
          "description": "Easily manage your MirrorProfiles account settings. Update your personal information, adjust language preferences, and secure your access with ease."
        },
        "subscription": {
          "title": "Subscribe to Your MirrorProfiles LinkedIn Accounts",
          "description": "Choose the number and type of LinkedIn accounts suited to your prospecting needs with MirrorProfiles. European or American accounts, ready to use with 500+ connections and optimal configuration in just a few clicks."
        },
        "addons": {
          "title": "Add Add-ons to Your MirrorProfiles Accounts",
          "description": "Customize your LinkedIn accounts with exclusive add-ons: automation tools, centralized message management, and advanced features to boost your prospecting. Tailor your services to your needs in just a few clicks."
        },
        "summary": {
          "title": "Subscription Summary | MirrorProfiles",
          "description": "View the summary of your LinkedIn accounts and selected add-ons with MirrorProfiles. Review your choices and finalize your subscription with confidence."
        },
        "success": {
          "title": "Subscription Successful | MirrorProfiles",
          "description": "Your subscription to MirrorProfiles is confirmed! Your personalized LinkedIn accounts are being prepared. Receive all the details within 48 hours and start using them with ease."
        },
        "login": {
          "title": "Login to Your MirrorProfiles Dashboard",
          "description": "Log in to your MirrorProfiles client area to access your ready-to-use LinkedIn accounts. Manage your profiles, track information, and optimize your prospecting securely."
        },
        "changePassword": {
          "title": "Change Your Password | MirrorProfiles",
          "description": "Secure your MirrorProfiles access by changing your password. Update it in a few simple steps to protect your MirrorProfiles accounts."
        },
        "forgotPassword": {
          "title": "Forgot Password | MirrorProfiles",
          "description": "Forgot your password? Enter your email address to receive a reset link and securely regain access to your MirrorProfiles client area."
        },
        "paymentCancelled": {
          "title": "Payment Cancelled | MirrorProfiles",
          "description": "Your payment has been cancelled. If you have any questions or need assistance, our team is here to help. Retry your order with ease."
        },
        "createPassword": {
          "title": "Create Your Password | MirrorProfiles",
          "description": "Set up your password to access your MirrorProfiles client area. Complete your registration and start managing your LinkedIn accounts securely."
        }
      },
      checkout: {
        title: "Subscribe to a LinkedIn profile ready for automation in a few clicks",
        step1: "Add subscriptions",
        step2: "Choose Addons",
        step3: "Summary",
        step3Out: "Payment",
        demoQuestion: "Do you have any questions or want more information about our offers?",
        scheduleDemo: "SCHEDULE A DEMO",
        waitingListInfo2: "Current wait time: 15 days.",
        waitingListInfo: "We are currently on a waiting list. Subscribing gives you priority access to the next available LinkedIn accounts.",
        step1Title: "Choose the number of accounts",
        accountsSelected: "accounts selected",
        step2Title: "Choose the type of account",
        europeanProfile: "European Profile",
        europeanPrice: "100€/month",
        europeanFeature1: "Profile and IP located in <span>Europe</span>",
        europeanFeature2: "Adapted for targets in  <span>Europe</span>",
        europeanFeature3: "+500 connections and +3 months of warm-up",
        europeanFeature4: "Replaced within 24 hours if needed",
        americanProfile: "American Profile",
        americanPrice: "150$/month",
        americanFeature1: "Profile and IP localized to <span>United States</span>",
        americanFeature2: "Adapted for targets in the <span>United States</span>",
        americanFeature3: "+500 connections and +3 months of warm-up",
        americanFeature4: "Replaced within 24 hours if needed",
        proceed: "Choose",
        selectAccountTypeError: "Please select an account type before proceeding.",
        compatibilityMessage: "No worries, our profiles are compatible with all the tools on the market as long as they respect quotas and best practices. We can also recommend the best tool based on your needs.",
        pricePerMonth1: "So",
        pricePerMonth2: "/month",
        prospectingEurope: "Adapted for prospecting targets in Europe",
        selectAccounts: "Slide to select the number of accounts",
        prospectingUS: "Adapted for prospecting targets in the US",
        addAddons: "Add add-ons",
        optional: "(optional)",
        paymentAfterSubscription: "Payment made after <br> subscription on HeyReach",
        continueButton: "CONTINUE",
        valideButton: "CONFIRM NEW SUBSCRIPTION",
        europeProfile: "Profile located in a country",
        europeProfile2: "of Europe",
        europeIp: "Dedicated IP in",
        europeIp2: "Europe",
        warmup: "+500 connections and +3 months of warmup",
        replacement: "Replaced within 24 hours if needed",
        usProfile: "Profile located in the",
        usProfile2: "United States",
        usProfile3: "or Canada",
        usIp: "Dedicated IP in the",
        usIp2: "United States",
        usIp3: "or Canada",
        recapTitle: "Recap of added accounts and add-ons",
        immediatePayment: "Immediate Payment:",
        immediatePaymentDetails: "Details of Immediate Payment",
        seeDetailsButton: "SEE THE PAYMENT DETAILS",
        subscription: "Subscription",
        subscriptionDetails: "Details of Subscription",
        nextBilling: "Monthly subscription for the next billing date:",
        confirmPaymentButton: "CONFIRM AND PROCEED TO PAYMENT",
        paymentSuccess: "🎉 Payment successful. You have subscribed to MirrorProfiles!",
        preparingAccounts: "We are currently preparing your personalized LinkedIn accounts to ensure they are optimized and ready for use. You will receive an email with all the necessary details within 48 hours.",
        nextStepsTitle: "Next Steps",
        nextSteps: {
          step1: "Check your email inbox for the order confirmation.",
          step2: "Login into your MirrorProfiles client area where you will be able to manage your LinkedIn profiles.",
          step3: "Your accounts will be delivered within a maximum of 48 hours.",
          step4: "Connect your accounts to your preferred automation tool and start your campaigns."
        },
        support: {
          needHelp: "Need help or have any questions? Our team is here to assist you.",
          visitFaq: "VISIT OUR HELP CENTER",
          contactSupport: "BACK TO CUSTOMER AREA"
        },
        features: {
          feature1: "Secure and optimized profiles.",
          feature2: "Ready for automation.",
          feature3: "Fast and dedicated support."
        },
        callToAction: {
          waiting: "While waiting for your accounts, explore our resources to maximize your results.",
          viewGuides: "VIEW OUR GUIDES"
        },
        valider: "CONFIRM",     
        "genderDistributionTitle": "What distribution of Men and Women profiles do you want?",
        "male": "Man",
        "female": "Woman",
        "limitReached": "Limit reached",
        "cannotExceedAccounts": "You cannot exceed {count} accounts.",
        "orderConfirmed": "Your order has been successfully placed. We are carefully preparing it.",
        "automationToolLimit": "You are entitled to only one automation tool."
      },
      setPassword: {
        title: "Set a Password",
        instruction: "Hello, please set a password for your client portal.",
        newPassword: "New Password",
        confirmPassword: "Confirm Password",
        submit: "Submit",
        passwordMismatch: "Passwords do not match.",
        passwordTooShort: "Password must be at least 6 characters long.",
        success: "Password successfully set! You can now log in.",
        error: "An error occurred while setting the password."
      },
      "resetPassword": {
        "title": "Reset Password",
        "subtitle": "Please kindly set your new password.",
        "newPassword": "New Password",
        "newPasswordPlaceholder": "Enter your new password",
        "confirmPassword": "Re-Enter Password",
        "confirmPasswordPlaceholder": "Re-enter your password",
        "submitButton": "Reset Password"
      },
      "changePassword": {
        "title": "Change Password",
        "subtitle": "Please enter your current password and set a new password.",
        "currentPassword": "Current Password",
        "currentPasswordPlaceholder": "Enter your current password",
        "newPassword": "New Password",
        "newPasswordPlaceholder": "Enter your new password",
        "confirmPassword": "Re-Enter Password",
        "confirmPasswordPlaceholder": "Re-enter your password",
        "submitButton": "Change Password",
        "passwordMismatch": "Passwords do not match.",
        "error": "An error occurred while changing the password."
      }
  },
  fr: {
    login: {
      title: 'Se connecter',
      email: 'Adresse e-mail',
      password: 'Mot de passe',
      rememberMe: 'Se souvenir de moi',
      forgotPassword: 'Mot de passe oublié ?',
      signInButton: 'Connexion',
      readyToUseAccounts: "Accédez à vos comptes LinkedIn prêts à l'emploi et commencez à prospecter dès aujourd'hui",
      satisfiedUsers: "Utilisateurs satisfaits",
      activeAccounts: "Comptes actifs",
      safetyGuarantee: "Garantie de sécurité",
      connections: "Connexions",
      review1: "Je l'utilise pour prospecter au nom de mes clients. Solution très simple et pratique me permettant d'avoir des comptes LinkedIn le temps de la prestation, directement utilisables et personnalisables en fonction de mes besoins. Depuis, je m'économise du temps et de l'énergie à essayer de demander les accès des comptes LinkedIn des employés.",
      accountsNumber1: "Nombre de comptes <strong>10</strong>",
      verticalizeAccounts: "Verticaliser les comptes par clients",
      dedicatedAccount: "Utiliser un compte dédié",
      review2: "J'utilise cet outil pour mes campagnes outbound afin de protéger les comptes LinkedIn de mes collègues (déjà bannis). Les profils sont chauds, personnalisables et adaptés à mes personas. Les contacts ne se doutent de rien. Je peux les partager avec mon équipe SDR sans souci, grâce à Gologin. Équipe réactive et sympa. Indispensable pour scaler efficacement !",
      accountsNumber2: "Nombre de comptes <strong>14</strong>",
      scaleVolume: "Augmenter le volume de prospection",
      secureOutbound: "Sécuriser les campagnes LinkedIn Outbound",
      enterEmail: "Entrez votre e-mail pour que nous puissions vous envoyer un lien de réinitialisation du mot de passe.",
      emailPlaceholder: "Entrez votre e-mail",
      sendEmail: "Envoyer un e-mail",
      backToLogin: "Retour à la connexion",
      "email_sent": "Un e-mail a été envoyé dans votre boîte de réception. Veuillez vérifier.",
      "email_error": "Une erreur est survenue lors de l'envoi de l'e-mail. Veuillez réessayer.",
      "password_updated": "Votre mot de passe a été modifié avec succès."
    },
    MainLayout: {
        accounts: 'Comptes',
        billing: 'Facturation',
        developer: 'Développeur',
        help: 'Aide',
        academy: 'Académie',
        settings: 'Paramètres',
        language: 'Langue',
        logout: 'Déconnexion',
        retour: 'Retour'
      },
      accounts: {
        title: 'Comptes',
        totalAccounts: '{count} comptes',
        getNewAccounts: 'NOUVEAUX COMPTES',
        filter: 'Filtrer',
        sortBy: 'Trier par',
        launchSession: 'Lancer la session',
        strike: 'Strike',
        detailsTitle: 'Détails',
        fullName: 'Nom complet :',
        company: 'Entreprise :',
        jobTitle: 'Poste :',
        numberOfConnections: 'Nombre de relations :',
        email: 'Email :',
        password: 'Mot de passe :',
        subscriptionDate: "Date d'abonnement :",
        urlLinkedIn: 'URL LinkedIn :',
        viewLinkedInProfile: 'Lien',
        actionsTitle: 'Actions',
        get2FA: 'Obtenir le 2FA',
        invalidEmail: "Seulement pour gmail",
        testProxy: 'Tester le proxy',
        proxyResponseTime: "temps de réponse",
        proxyError: "Erreur lors du test du proxy",
        reportProblem: 'Signaler un problème',
        addAutomationTool: "Ajouter un outil d'automatisation",
        removeFromSubscription: "Supprimer de l'abonnement",
        addAutomationTitle: "Ajouter un outil d'automatisation",
        selectTool: "Sélectionnez un outil",
        submit: "Soumettre",
        cancel: "Annuler",
        reportProblemTitle: "Signaler un problème",
        problemType: "Type de problème",
        problemDescription: "Description",
        problemDescriptionPlaceholder: "Donnez des détails supplémentaires sur le problème...",
        automationSuccess: "Outil d'automatisation ajouté avec succès !",
        automationError: "Échec de l'ajout de l'outil d'automatisation.",
        problemSuccess: "Problème signalé avec succès !",
        problemError: "Échec de la soumission du problème.",
        problemTypes: {
            inaccessibleAccount: "Compte plus accessible",
            gologinIssue: "Problème GoLogin",
            accountDisconnected: "Déconnexion du compte",
            other: "Autre",
        },
        linksSent: "Liens envoyés",
        connectionRequests: "Nombre de demandes de connexions",
        profileVisits: "Nombre de visites de profils",
        autoProfileVisits: "Visite de profils auto ?",
        automationToolUsed: "Automation tool utilisé",
        enterNumber: "Entrez un nombre",
        pendingAccount: "Compte en attente",
        preparingAccount: "En cours de préparation...",
        sortAsc: 'A-Z',
        sortDesc: 'Z-A',
        addedSoon: 'Bientôt ajouté',
        replaceAccountTitle: "Remplacer le compte",
        replaceAccountInstruction: "Remplissez ce formulaire pour demander le remplacement de votre compte",
        linksSentQuestion: "Avez-vous envoyé des liens externes dans vos messages LinkedIn ?",
        manualOrAutomatic: "Si oui, était-ce manuel ou automatique ?",
        manual: "Manuel",
        automatic: "Automatique",
        stopSubscriptionTitle: 'Quand voulez-vous arrêter l’abonnement ?',
        renewalDate: 'Date de renouvellement',
        removeOption: 'Sélectionnez une option de suppression',
        removeNow: 'Maintenant',
        removeEndOfMonth: 'À la fin du mois',
        removeWarning: 'Les annulations peuvent prendre effet immédiatement ou à la fin de votre abonnement. Les frais du mois en cours ne sont pas remboursables.',
        remove: 'Supprimer',
        subscriptionRemoved: 'Abonnement supprimé avec succès.',
        subscriptionRemovedNow: "L'abonnement a été supprimé immédiatement.",
        subscriptionRemovedEnd: "L'abonnement sera supprimé à la fin de la période actuelle.",
        subscriptionRemoveError: "Une erreur est survenue lors de la suppression de l'abonnement.",
        noAccountSelected: "Aucun compte sélectionné.",
        Search: "Recherche...",
        Replace: "Remplacer",
        back: "Retour",
        filterLocation: "Localisation",
        filterGender: "Genre",
        allLocations: "Toutes",
        europe: "Europe",
        usa: "USA",
        allGenders: "Tous",
        male: "Homme",
        female: "Femme",
        modalreasonLabel: "Raison de la demande",
        modaltitle: "Veuillez remplir ce formulaire pour expliquer pourquoi vous avez besoin d'accéder au mot de passe.",
        proxyDetails: "Détails Proxy",
        viewproxyDetails: "Voir",
        proxyTextInfo: "Découvrez les détails de vos proxy à renseigner dans vos outils favoris",
        "filterStatus": "Filtrer par statut",
        "salesNav": "Sales Navigator",
        "pending": "En attente",
        stopSubscriptionSureDesc: "Cette action est irréversible et supprimera définitivement l'accès à ce compte. Veuillez noter que tout mois entamé est payable en totalité.",
        stopSubscriptionSure: "Êtes-vous sûr de vouloir supprimer ce compte de votre abonnement ?",
        automationSuccessDesc: "Nous vous recontacterons dès que l'automatisation sera mise en place !",
        SuccessDesc: "Nous vous recontacterons dès que possible",
        automationSuccessTitle: "Nous avons pris en compte votre demande",
        "ticketError": "Une erreur s'est produite lors de la soumission du ticket. Veuillez réessayer.",
        "ticketSuccess": "Le ticket a été créé avec succès !",
        "fillAllFields": "Veuillez remplir tous les champs requis avant de soumettre."  ,
        proxySlowWarning: "Nous avons bien reçu votre signalement et travaillons à résoudre le problème dans les plus brefs délais. Vous serez notifié par email dès que le proxy sera corrigé. Merci pour votre patience.",
        descriptionOptional: "facultatif",
        "automationTool": "Outil d'automatisation",
        "noAutomationTool": "Non défini",
        "cancelResiliation": "Fin de l'abonnement : {resiliationDate}",
        "cancelremove": "Annuler la résiliation",
        "subscriptionCancelRemovedEnd": "Votre résiliation a été annulée avec succès, vous continuerez à bénéficier de vos comptes !",
        "subscriptionCancelRemovedEndError": "Une erreur est survenue lors de l'annulation de la résiliation. Veuillez réessayer ou contacter notre service client si le problème persiste.",
        "stopCancelSubscriptionSure": "Êtes-vous sûr de vouloir annuler la résiliation de ce compte dans votre abonnement ?",
        "stopCancelSubscriptionSureDesc": "Cette action annulera la demande de résiliation et vous permettra de continuer à profiter de ce compte lors de la prochaine échéance.",
        "compte" : "Compte :",
        "copy_success": "Copié dans le presse-papier !",
        "copy_error": "Impossible de copier dans le presse-papier.",
        "copy_unsupported": "La fonction de copie n’est pas prise en charge par votre navigateur.",
        CannotReplace: "Remplacement en attente"
      },
      common: {
        yes: "Oui",
        no: "Non"
      },
      settings: {
        parameters: 'Paramètres',
        myAccount: 'Mon Compte',
        email: 'Email',
        password: 'Mot de passe',
        company: 'Entreprise',
        language: 'Langue',
        integrations: 'Intégrations',
        apiKey: 'Clé API',
        show: 'Afficher',
        hide: 'Masquer',
        logout: 'Déconnexion',
        "editName": "Modifier le nom de l'entreprise",
        "newName": "Nouveau nom",
        "namePlaceholder": "Saisissez un nouveau nom",
        "cancel": "Annuler",
        "save": "Enregistrer",
        "languageUpdateSuccess": "Langue mise à jour avec succès.",
        "languageUpdateError": "Erreur lors de la mise à jour de la langue du compte.",
        "nameUpdateSuccess": "Nom mis à jour avec succès.",
        "nameUpdateError": "Erreur lors de la mise à jour du nom."
      },
      academy: {
        title: 'Académie',
        description:
          "Devenez expert en prospection LinkedIn avec notre académie : apprenez grâce à Frédéric, notre co-fondateur et expert LinkedIn, à maîtriser la prospection et à exploiter tout le potentiel de MirrorProfiles.",
        accessButton: 'Accédez à la formation',
        moduleTitle: 'Module vidéo : {title}',
        modules: {
          1: {
            title: 'Boostez votre acquisition Linkedin',
            description:
              "Fort de plus de 6 ans d'expérience en prospection Linkedin, Frédéric, co-fondateur de MirrorProfiles, vous livre les statégies, les astuces et plein de conseils pour maximiser l'utilisation de vos comptes Linkedin. <br> <br> Préparez vous à en apprendre beaucoup sur la prospection Linkedin 🚀",
          },
          2: {
            title: 'Devenez expert en copywriting Linkedin',
            description:
              "Etre capable d'envoyer des milliers de messages sur Linkedin, c'est bien ! Mais avec le bon message, ça fait toute la différence. Dans cette vidéo, Frédéric, vous livre et vous présente des exemples de messages de prospection efficaces et qui transforment. <br><br> A vos claviers, préparez vous à comprendre les mystères d'une campagne Linkedin efficace ⌨",
          },
          3: {
            title: 'KPI Linkedin. Quels objectifs ?',
            description:
              "Ais-je un bon taux d'acceptation, de réponses ? Bref, est-ce que ma campagne Linkedin fonctionne bien ? Frédéric vous livre les bonnes statistiques à avoir sur Linkedin et bien sûre vous livre ses secrets pour atteindre vos objectifs, qu'ils soient quantitatif mais aussi et surtout qualitatif. <br><br> Apprenez à analyser et à améliorer vos campagnes Linkedin 💪",
          },
          4: {
            title: 'Construire un fichier de prospection avec SalesNavigator',
            description:
              "Le nerf de la prospection ? C'est la data, et c'est bien le seul sujet où tout le monde est d'accord. Or, pour avoir des fichiers de prospects de qualité, il vous faudra apprendre les subtilités de Linkedin SalesNavigator. <br><br> Prêt à maîtriser les filtres SalesNavigator comme un pro, Frédéric vous dévoile toutes ses astuces sur cet outil 🎯",
          },
          5: {
            title: 'Apprendre à gérer les réponses sur Linkedin',
            description:
              "Ne soyez pas trop commercial, c'est la règle n°1 de la prospection sur Linkedin. Il faut répondre à tous les prospects, que celui soit intéressé ou non, ou encore indécis. Frédéric vous montre dans cette formation, comment augmenter votre taux de rendez-vous grâce à des astuces simples mais efficaces à mettre en place. <br><br> Convertissez plus de prospects en rendez-vous client de qualités, let's go ! 📈",
          },
          6: {
            title: 'L’art d’utiliser MirrorProfiles en autonomie',
            description:
              "Comme tout outil de Growth hacking, utiliser au mieux MirrorProfiles en autonomie nécessite une petite formation. Frédéric vous livre en quelques minutes chrono, tout ce que vous devez savoir sur notre outil. <br><br> Accélérez votre prospection Linkedin en multipliant le nombre de comptes Linkedin grâce à MirrorProfiles",
          },
        },
      },
      "problemTypes": {
        "accountInaccessible": "Compte plus accessible",
        "gologinIssue": "Problème GoLogin",
        "accountDisconnection": "Déconnexion du compte",
        "linkedinEmailIssue": "Email non accessible sur Linkedin",
        "other": "Autres"
      },
      checkout: {
        title: "Souscrivez à un profil Linkedin prêt à l'automatisation en quelques clics",
        step1: "Ajouter des abonnements",
        step2: "Choisissez des Addons",
        step3: "Récapitulatif",
        step3Out: "Paiement",
        demoQuestion: "Vous avez des questions ou souhaitez des informations sur nos offres ?",
        scheduleDemo: "PRENEZ UNE DÉMO",
        waitingListInfo2: "Délais d'attente actuel : 15 jours.",
        waitingListInfo: "Nous sommes actuellement en liste d'attente. La souscription vous permet d'avoir les prochains comptes Linkedin disponibles en priorité.",
        step1Title: "Choisissez le nombre de comptes",
        accountsSelected: "comptes sélectionnés",
        step2Title: "Choisissez le type de compte",
        europeanProfile: "Profil Européen",
        europeanPrice: "100€/mois",
        europeanFeature1: "Profil et IP localisés en <span>Europe</span>",
        europeanFeature2: "Adapté pour des cibles en <span>Europe</span>",
        europeanFeature3: "+500 connexions et +3 mois de warmup",
        europeanFeature4: "Remplacé sous 24h en cas de besoin",
        americanProfile: "Profil Américain",
        americanPrice: "150$/mois",
        americanFeature1: "Profil et IP localisé aux <span>États-Unis</span>",
        americanFeature2: "Adapté pour des cibles aux <span>États-Unis</span>",
        americanFeature3: "+500 connexions et +3 mois de warmup",
        americanFeature4: "Remplacé sous 24h en cas de besoin",
        proceed: "Choisir",
        selectAccountTypeError: "Veuillez sélectionner un type de compte avant de continuer.",
        compatibilityMessage: "Pas d'inquiétude, nos profils sont compatibles avec tous les outils du marché tant qu'ils respectent les quotas et bonnes pratiques. Nous pouvons aussi vous recommander le meilleur outil en fonction de vos besoins.",
        pricePerMonth1: "Soit",
        pricePerMonth2: "/mois",
        prospectingEurope: "Adaptés pour des cibles de prospection en Europe",
        selectAccounts: "Glissez pour sélectionner le nombre de comptes",
        prospectingUS: "Adaptés pour des cibles de prospection au US",
        addAddons: "Ajoutez des add-ons ",
        optional: "(facultatif)",
        paymentAfterSubscription: "Paiement fait après la <br> souscription sur HeyReach",
        continueButton: "CONTINUER",
        valideButton: "VALIDER LE NOUVEL ABONNEMENT",
        europeProfile: "Profil localisé dans un pays",
        europeProfile2: "d'Europe",
        europeIp: "IP dédiée en",
        europeIp2: "Europe",
        warmup: "+500 connexions et +3 mois de warmup",
        replacement: "Remplacé sous 24h en cas de besoin",
        usProfile: "Profil localisé aux",
        usProfile2: "États-Unis",
        usProfile3: "ou Canada",
        usIp: "IP dédiée aux",
        usIp2: "États-Unis",
        usIp3: "ou Canada",
        recapTitle: "Récapitulatif des comptes ajoutés et des add-ons",
        immediatePayment: "Paiement immédiat :",
        immediatePaymentDetails: "Détails du Paiement Immédiat",
        seeDetailsButton: "VOIR LE DÉTAIL DU PAIEMENT",
        subscription: "Abonnement",
        subscriptionDetails: "Détails de l'Abonnement",
        nextBilling: "Abonnement mensuel à la prochaine échéance :",
        confirmPaymentButton: "CONFIRMER ET PROCÉDER AU PAIEMENT",
        paymentSuccess: "🎉 Paiement réussi. Vous venez de souscrire à MirrorProfiles !",
        preparingAccounts: "Nous préparons actuellement vos comptes LinkedIn personnalisés pour qu'ils soient optimisés et prêts à l'emploi. Vous recevrez un e-mail avec tous les détails nécessaires dans les 48 heures.",
        nextStepsTitle: "Prochaines étapes",
        nextSteps: {
          step1: "Vérifiez votre boîte de réception pour la confirmation de commande.",
          step2: "Connectez-vous à votre espace client MirrorProfiles pour gérer vos profils LinkedIn.",
          step3: "Vos comptes seront livrés sous un délai maximum de 48 heures.",
          step4: "Connectez vos comptes à votre outil d'automatisation préféré et lancez vos campagnes."
        },
        support: {
          needHelp: "Besoin d'aide ou de poser des questions ? Notre équipe est là pour vous aider.",
          visitFaq: "VISITEZ NOTRE CENTRE D'ASSISTANCE",
          contactSupport: "RETOUR ESPACE CLIENT"
        },
        features: {
          feature1: "Profils sécurisés et optimisés.",
          feature2: "Prêts pour l'automatisation.",
          feature3: "Support rapide et dédié."
        },
        callToAction: {
          waiting: "En attendant vos comptes, explorez nos ressources pour maximiser vos résultats.",
          viewGuides: "VOIR NOS GUIDES"
        },
        valider: "CONFIRMER",
        "genderDistributionTitle": "Quelle répartition de profils Hommes et Femmes souhaitez-vous ?",
        "male": "Homme",
        "female": "Femme",
        "limitReached": "Limite atteinte",
        cannotExceedAccounts: "Vous ne pouvez pas dépasser {count} comptes.",
        "orderConfirmed": "Votre commande a bien été prise en compte. Nous la préparons avec soin.",
        "automationToolLimit": "Vous avez droit à un seul outil d'automatisation."
      },    
      routes: {
        "accounts": {
          "title": "Espace client - Gérez vos comptes LinkedIn avec MirrorProfiles",
          "description": "Accédez à tous vos comptes MirrorProfiles dans votre espace client. Consultez les détails, gérez les paramètres et effectuez les actions nécessaires pour une utilisation efficace et sécurisée."
        },
        "academy": {
          "title": "Académie MirrorProfiles | Maîtrisez LinkedIn et boostez votre prospection",
          "description": "Devenez expert en prospection LinkedIn avec l’Académie MirrorProfiles. Accédez à des formations exclusives pour apprendre à utiliser vos comptes efficacement, améliorer vos campagnes et maximiser vos résultats."
        },
        "settings": {
          "title": "Paramètres de votre compte | MirrorProfiles",
          "description": "Gérez facilement les paramètres de votre compte MirrorProfiles. Modifiez vos informations personnelles, ajustez les préférences linguistiques et sécurisez votre accès en toute simplicité."
        },
        "subscription": {
          "title": "Souscrivez à vos profils LinkedIn MirrorProfiles",
          "description": "Choisissez le nombre et le type de profils LinkedIn adaptés à votre prospection avec MirrorProfiles. Profils européens ou américains, prêts à l’utilisation avec +500 connexions et une configuration optimale en quelques clics."
        },
        "addons": {
          "title": "Ajoutez des add-ons à vos profils MirrorProfiles",
          "description": "Personnalisez vos comptes LinkedIn avec des add-ons exclusifs : outils d’automatisation, gestion centralisée des messages et fonctionnalités avancées pour booster votre prospection. Adaptez vos services à vos besoins en quelques clics."
        },
        "summary": {
          "title": "Récapitulatif de votre souscription | MirrorProfiles",
          "description": "Visualisez le récapitulatif de vos comptes LinkedIn et des add-ons sélectionnés avec MirrorProfiles. Vérifiez vos choix et finalisez votre souscription en toute confiance."
        },
        "success": {
          "title": "Souscription réussie | MirrorProfiles",
          "description": "Votre souscription à MirrorProfiles est confirmée ! Vos comptes LinkedIn personnalisés sont en préparation. Recevez tous les détails sous 48h et commencez à les utiliser en toute simplicité."
        },
        "login": {
          "title": "Connexion à votre espace MirrorProfiles",
          "description": "Connectez-vous à votre espace client MirrorProfiles pour accéder à vos comptes LinkedIn prêts à l’emploi. Gérez vos profils, suivez les informations et optimisez votre prospection en toute sécurité."
        },
        "changePassword": {
          "title": "Changer votre mot de passe | MirrorProfiles",
          "description": "Sécurisez votre accès à MirrorProfiles en modifiant votre mot de passe. Changez-le en quelques étapes simples pour protéger vos comptes MirrorProfiles."
        },
        "forgotPassword": {
          "title": "Mot de passe oublié | MirrorProfiles",
          "description": "Vous avez oublié votre mot de passe ? Entrez votre adresse e-mail pour recevoir un lien de réinitialisation et retrouver l'accès à votre espace client MirrorProfiles en toute sécurité."
        },
        "paymentCancelled": {
          "title": "Paiement annulé | MirrorProfiles",
          "description": "Votre paiement a été annulé. Si vous avez des questions ou besoin d’assistance, notre équipe est là pour vous aider. Réessayez votre commande en toute simplicité."
        },
        "createPassword": {
          "title": "Créer votre mot de passe | MirrorProfiles",
          "description": "Configurez votre mot de passe pour accéder à votre espace client MirrorProfiles. Finalisez votre inscription et commencez à gérer vos comptes LinkedIn en toute sécurité."
        }
      },
      setPassword: {
        title: "Définir un mot de passe",
        instruction: "Bonjour, veuillez définir un mot de passe pour votre espace client.",
        newPassword: "Nouveau mot de passe",
        confirmPassword: "Confirmez le mot de passe",
        submit: "Enregistrer",
        passwordMismatch: "Les mots de passe ne correspondent pas.",
        passwordTooShort: "Le mot de passe doit contenir au moins 6 caractères.",
        success: "Mot de passe défini avec succès ! Vous pouvez maintenant vous connecter.",
        error: "Une erreur est survenue lors de la définition du mot de passe."
      },
      "resetPassword": {
        "title": "Réinitialiser le mot de passe",
        "subtitle": "Veuillez définir votre nouveau mot de passe.",
        "newPassword": "Nouveau mot de passe",
        "newPasswordPlaceholder": "Entrez votre nouveau mot de passe",
        "confirmPassword": "Confirmez le mot de passe",
        "confirmPasswordPlaceholder": "Re-saisissez votre mot de passe",
        "submitButton": "Réinitialiser le mot de passe"
      },
      "changePassword": {
        "title": "Modifier le mot de passe",
        "subtitle": "Veuillez saisir votre mot de passe actuel et définir un nouveau mot de passe.",
        "currentPassword": "Mot de passe actuel",
        "currentPasswordPlaceholder": "Entrez votre mot de passe actuel",
        "newPassword": "Nouveau mot de passe",
        "newPasswordPlaceholder": "Entrez votre nouveau mot de passe",
        "confirmPassword": "Confirmez le mot de passe",
        "confirmPasswordPlaceholder": "Re-saisissez votre mot de passe",
        "submitButton": "Modifier le mot de passe",
        "passwordMismatch": "Les mots de passe ne correspondent pas.",
        "error": "Une erreur est survenue lors de la modification du mot de passe."
      }
  },
};

const getBrowserLanguage = () => {
  const savedLang = localStorage.getItem('langue');
  if (savedLang) {
    return savedLang;
  }
  
  const lang = navigator.language || navigator.userLanguage;

  return lang.startsWith('fr') ? 'fr' : 'en';
};

const i18n = createI18n({
  locale: getBrowserLanguage(),
  fallbackLocale: 'en',
  messages,
});

export default i18n;
