<template>
    <div :class="['relative', isMobile ? 'p-[1.5rem] pt-[3.5rem]' : 'p-[8rem] pt-[4rem]']">
      <div class="header flex justify-between items-center mb-6">
        <div :class="['absolute', isMobile ? 'top-5 right-0 z-10' : 'top-8 right-8 z-10']">
          <div 
            class="bg-white custom-border text-blue-700 px-4 py-1 rounded-full flex items-center shadow-md cursor-pointer"
            @click="toggleLanguageDropdown"
          >
            <img :src="currentFlag" alt="Langue" class="w-8 h-8 mr-2" />
            <i class="fas fa-chevron-down"></i>
          </div>

          <div 
            v-if="showLanguageDropdown" 
            class="absolute right-0 mt-2 bg-white shadow-lg rounded-lg p-2 w-32"
          >
            <button
              @click="setLanguage('fr')"
              class="flex items-center justify-start w-full px-2 py-1 hover:bg-blue-100 rounded-md transition"
            >
              <img src="../../assets/france-flag.png" alt="Français" class="w-6 h-6 mr-2" />
              Français
            </button>
            <button
              @click="setLanguage('en')"
              class="flex items-center justify-start w-full px-2 py-1 hover:bg-blue-100 rounded-md transition"
            >
              <img src="../../assets/uk-flag.png" alt="English" class="w-6 h-6 mr-2" />
              English
            </button>
          </div>
        </div>
      </div>

      <div class="text-success text-center p-4 rounded-lg font-semibold mt-7">
        {{ $t('checkout.paymentSuccess') }}
      </div>

      <div class="immediate-section mt-[3rem]">
        <h3 class="next-step mb-7">  {{ $t('checkout.genderDistributionTitle') }}        </h3>
        
        <div class="flex justify-between items-center space-x-8 pl-[2rem] pr-[2rem]">
          <div class="flex items-center space-x-9">
            <span class="text-gender">{{ $t('checkout.male') }}</span>
            <div class="flex-input-container">
              <button 
                @click="decrease('male')" 
                class="px-3 py-1 button-selector font-bold"
                :disabled="isDisabledTotal"
              >
                -
              </button>
              <input 
                type="number" 
                class="no-arrows" 
                :value="maleCount" 
                readonly
              />
              <button 
                @click="increase('male')" 
                @mousedown="handleDisabledClick"
                class="px-3 py-1 button-selector font-bold"
                :disabled="isDisabled"
              >
                +
              </button>
            </div>
          </div>
          <div class="flex items-center space-x-9">
            <span class="text-gender">{{ $t('checkout.female') }}</span>
              <div class="flex-input-container">
                <button 
                  @click="decrease('female')" 
                  class="px-3 py-1 button-selector font-bold"
                  :disabled="isDisabledTotal"
                >
                  -
                </button>
                <input 
                  type="number" 
                  class="no-arrows" 
                  :value="femaleCount" 
                  readonly
                />
                <button 
                  @click="increase('female')" 
                  @mousedown="handleDisabledClick"
                  class="px-3 py-1 button-selector hover:bg-blue-200 font-bold"
                  :disabled="isDisabled"
                >
                  +
                </button>
              </div>
          </div>
        </div>
        <div class="mt-[2rem] text-center">
          <button 
            class="button-continu text-[1vw] text-white px-8 py-3 rounded-full font-semibold w-4/5 mx-auto"
            :disabled="isDisabledTotal"
            @click="updateGenderData"
          >
            {{ $t('checkout.valider') }}
          </button>
        </div>
      </div>



      <div class="mt-[3rem]">
        <hr class="w-full h-[2px] bg-gray-400 border-none mb-8" style="background: #BDBDBD;" />
        <p class="text-details-success">
          {{ $t('checkout.preparingAccounts') }}
        </p>
      </div>

      <div class="immediate-section mt-[3rem]">
        <h2 class="next-step">{{ $t('checkout.nextStepsTitle') }}</h2>
        <ul class="list-inside list-note mt-5 space-y-5">
          <li class="flex items-start">
            <span class="number-blue mr-3">1</span>
            <span class="flex-1">{{ $t('checkout.nextSteps.step1') }}</span>
          </li>
          <li class="flex items-start">
            <span class="number-blue mr-3">2</span>
            <span class="flex-1">{{ $t('checkout.nextSteps.step2') }}</span>
          </li>
          <li class="flex items-start">
            <span class="number-blue mr-3">3</span>
            <span class="flex-1">{{ $t('checkout.nextSteps.step3') }}</span>
          </li>
          <li class="flex items-start">
            <span class="number-blue mr-3">4</span>
            <span class="flex-1">{{ $t('checkout.nextSteps.step4') }}</span>
          </li>
        </ul>
      </div>


      <div class="recurring-section mt-[3rem]">
        <div class="flex flex-col space-y-4">
          <p class="text-help flex mb-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 28 28" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M14.3178 3.77414C12.3279 3.70788 10.3175 4.25077 8.58182 5.40934C6.63239 6.71197 5.27004 8.63557 4.64253 10.786C4.46924 10.7648 4.26173 10.7741 4.01533 10.8438C3.09537 11.1049 2.40346 11.8587 2.09235 12.5226C1.68884 13.3878 1.51866 14.5321 1.69817 15.7133C1.87644 16.8909 2.37391 17.8616 2.99613 18.4642C3.62022 19.0671 4.32488 19.2631 5.02488 19.116C6.06711 18.8935 6.58386 18.7271 6.43795 17.7561L5.73142 13.0493C5.87391 10.5019 7.19271 8.10388 9.39848 6.62921C12.3509 4.65708 16.2218 4.78339 19.038 6.9453C20.997 8.44734 22.1344 10.6979 22.2657 13.0605L21.7717 16.353C20.6697 19.3686 17.9379 21.4574 14.7767 21.7539H12.6717C12.1285 21.7539 11.6911 22.1913 11.6911 22.7339V23.2509C11.6911 23.7938 12.1285 24.2313 12.6717 24.2313H15.3258C15.8687 24.2313 16.3042 23.7938 16.3042 23.2509V22.9806C18.688 22.3988 20.7687 20.9338 22.1235 18.8907L22.9738 19.1163C23.6657 19.2958 24.3788 19.0671 25.0026 18.4645C25.6248 17.8616 26.1219 16.8912 26.3005 15.7137C26.4807 14.5324 26.3055 13.39 25.9067 12.5229C25.5063 11.6558 24.9099 11.1052 24.2214 10.9076C23.933 10.8245 23.62 10.7941 23.3515 10.786C22.784 8.84152 21.6158 7.07317 19.9321 5.78205C18.2767 4.51179 16.3076 3.83948 14.3178 3.77414Z" fill="white"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M17.4252 12.242C18.1267 12.242 18.6955 12.8107 18.697 13.5141C18.6955 14.2157 18.1267 14.7859 17.4252 14.7859C16.7218 14.7859 16.1515 14.2157 16.1515 13.5141C16.1515 12.811 16.7221 12.242 17.4252 12.242ZM13.9998 12.242C14.703 12.242 15.2717 12.8107 15.2717 13.5141C15.2717 14.2157 14.703 14.7859 13.9998 14.7859C13.2961 14.7859 12.7274 14.2157 12.7274 13.5141C12.7274 12.811 13.2961 12.242 13.9998 12.242ZM10.5758 12.242C11.2773 12.242 11.8476 12.8107 11.8476 13.5141C11.8476 14.2157 11.2773 14.7859 10.5758 14.7859C9.87265 14.7859 9.30363 14.2157 9.30363 13.5141C9.30363 12.811 9.87265 12.242 10.5758 12.242ZM13.9998 6.77823C10.2696 6.77823 7.26367 9.68525 7.26367 13.5141C7.26367 15.3531 7.95901 16.9783 9.09176 18.1708L8.68981 19.9728C8.55727 20.5657 8.96856 20.9646 9.50149 20.6678L11.2611 19.6862C12.0974 20.0493 13.0223 20.25 13.9998 20.25C17.7313 20.25 20.7354 17.3448 20.7354 13.5141C20.7354 9.68525 17.7313 6.77823 13.9998 6.77823Z" fill="white"/>
            </svg>
            <span class="ml-3">{{ $t('checkout.support.needHelp') }}</span>
          </p>
          <div class="flex space-x-4">
            <button class="visit-faq px-4 py-2" @click="redirectToHelp"
            target="_blank">
              {{ $t('checkout.support.visitFaq') }}
            </button>
            <button class="contact-us px-4 py-2" @click="redirectToAccounts">
              {{ $t('checkout.support.contactSupport') }}
            </button>
          </div>
        </div>
      </div>

      
    </div>
    <div 
      v-if="showToast" 
      class="fixed top-5 left-1/2 transform -translate-x-1/2 px-6 py-3 text-white font-semibold rounded-lg shadow-lg transition-opacity duration-300 bg-green-500"
      style="z-index: 9999;"
    >
      {{ toastMessage }}
    </div>

  </template>
  
  <script>
  import franceFlag from '../../assets/france-flag.png';
  import ukFlag from '../../assets/uk-flag.png';
  import axios from '../../plugins/axios';


  export default {
    name: 'SuccessView',
    data() {
    return {
      flags: {
        fr: franceFlag,
        en: ukFlag,
      },
      currentLanguage: localStorage.getItem('langue'),
      showLanguageDropdown: false,
      isMobile: false,
      maleCount: 0, 
      femaleCount: 0, 
      totalCount: localStorage.getItem('numberAccount') || 0,
      showToast: false,
      toastMessage: '',
      isSubmitting: false,
    };
  },
  computed: {
    currentFlag() {
      const languageKey = this.currentLanguage.split('-')[0];
      return this.flags[languageKey] || this.flags.en;
    },
    selectedTotal() {
      return this.maleCount + this.femaleCount;
    },
    isDisabled() {
      return this.selectedTotal == this.totalCount;
    },
    isDisabledTotal() {
      return this.isSubmitting;
    },
    
  },
  methods: {
    redirectToAccounts() {
      this.$router.push('/accounts'); 
    },
    redirectToHelp() {
      const baseUrl = 'https://help.mirrorprofiles.com';
      const languagePath = this.currentLanguage === 'fr' ? 'fr' : 'en';
      window.open(`${baseUrl}/${languagePath}/`, '_blank')
    },
    increase(type) {
      if (this.selectedTotal < this.totalCount) {
        if (type === 'male') {
          this.maleCount++;
        } else if (type === 'female') {
          this.femaleCount++;
        }
      } else {
        this.showToastMessage(
          this.$t('checkout.limitReached'),
          this.$t('checkout.cannotExceedAccounts', { count: this.totalCount })
        );
      }

      if(this.selectedTotal == this.totalCount){
        this.showToastMessage(
          this.$t('checkout.limitReached'),
          this.$t('checkout.cannotExceedAccounts', { count: this.totalCount })
        );
      }
    },
     decrease(type) {
      if (type === 'male' && this.maleCount > 0) {
        this.maleCount--;
      } else if (type === 'female' && this.femaleCount > 0) {
        this.femaleCount--;
      }
    },
    showToastMessage(title, message) {
      this.toastMessage = `${title}: ${message}`;
      this.showToast = true;
      setTimeout(() => {
        this.showToast = false;
        this.toastMessage = '';
      }, 3000);
    },
    updateIsMobile() {
        this.isMobile = window.innerWidth <= 768; 
      },
    toggleLanguageDropdown() {
      this.showLanguageDropdown = !this.showLanguageDropdown;
    },
    setLanguage(lang) {
      this.currentLanguage = lang;
      this.$i18n.locale = lang;
      this.showLanguageDropdown = false;
      localStorage.setItem('langue', lang)
      localStorage.setItem('lang', lang);
    },
    async updateGenderData() {
      try {
        this.isSubmitting = true;

        const response = await axios.post('/api/update-gender-data', {
          maleCount: this.maleCount,
          femaleCount: this.femaleCount,
        });

        this.showToastMessage(this.$t('checkout.orderConfirmed'),"");
        localStorage.setItem('numberAccount', null);
        console.log(response);
      } catch (error) {
        console.error('Erreur lors de la mise à jour des données de genre :', error.response?.data || error.message);

        this.isSubmitting = false;
      }
    },
  },
  mounted() {
     this.updateIsMobile();
     window.addEventListener("resize", this.updateIsMobile);
   },
  
  };
  </script>
  
  <style scoped>
.immediate-section{
  border-radius: 10px;
  padding: 2rem;
  background: #fff;
  box-shadow: 0px 20px 40px rgba(30, 147, 253, 0.2);
}

.recurring-section {
  color:#FFF;
  border-radius: 5px;
  padding: 2rem;
  background: var(--gradient, linear-gradient(90deg, #1E93FD 0%, #3154C8 100%));
  box-shadow: 0px 20px 40px rgba(30, 147, 253, 0.2);
}

.text-success{
  border-radius: 5px;
  background: #D2EAFF;
  color: #1A1A1A;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.text-details-success{
  color: #000;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; 
}

.next-step{
  color: #000;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; 
}

.list-note{
  color: #000;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; 
}

.text-help{
  color: #FFF;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  align-items: center;
}

.visit-faq {
  border-radius: 50px; 
  background: #FFF; 
  border: 2px solid #1E93FD; 
  font-weight: bold; 
  text-align: center; 
  font-size: 16px;
  color: #1E93FD; 
  background: #FFF;
}

.visit-faq span {
  background-image: linear-gradient(90deg, #1E93FD 0%, #3154C8 100%); 
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; 
}

.visit-faq:hover {
  background: linear-gradient(90deg, #1E93FD 0%, #3154C8 100%);
  color: #FFF;
  border: 2px solid #FFF;
}

.visit-faq:hover span {
  -webkit-text-fill-color:#FFF; 
}

.contact-us{
  border-radius: 50px;
  font-weight: bold; 
  text-align: center; 
  font-size: 16px;
  border: 2px solid #FFF;
}

.contact-us span{
  -webkit-text-fill-color:#FFF; 
}


.contact-us:hover {
  background: #FFF;
  color: #1E93FD;
  border: 2px solid #1E93FD;
}


.contact-us:hover span {
  background-image: linear-gradient(90deg, #1E93FD 0%, #3154C8 100%); 
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; 
}



.key-success{
  color: #000;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; 
}

.text-waiting{
  color: #000;
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; 
}

.button-our-guides{
  border-radius: 50px;
  background: linear-gradient(90deg, #1E93FD 0%, #3153C7 100%);
  color: #FFF;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out; 
}

.button-our-guides:hover {
  background: linear-gradient(90deg, #3153C7 0%, #1E93FD 100%);
  transform: scale(1.05); 
  box-shadow: 0px 4px 10px rgba(30, 147, 253, 0.4); 
}

.button-our-guides:disabled {
  background: linear-gradient(90deg, #cccccc 0%, #999999 100%);
  color: #666666;
  cursor: not-allowed;
  transform: none; 
  box-shadow: none;
  opacity: 0.6;
}

.button-our-guides:disabled:hover {
  transform: none; 
  background: linear-gradient(90deg, #cccccc 0%, #999999 100%);
  box-shadow: none;
}

.custom-border{
  border-radius: 62px;
  border: 1px solid var(--gradient, #1E93FD);
}

@media (max-width: 767px) {

}



.no-arrows::-webkit-inner-spin-button,
.no-arrows::-webkit-outer-spin-button {
-webkit-appearance: none;
margin: 0;
}

.no-arrows {
-moz-appearance: textfield;
}

.button-selector {
  background: var(--gradient, linear-gradient(90deg, #1E93FD 0%, #3154C8 100%));
  color: white;
  padding: 0.5rem 1rem;
  font-size: 17px; 
}


.flex-input-container {
  display: flex;
  align-items: center;
  border: 1px solid #1E93FD;
  border-radius: 10px;
  overflow: hidden;
}


.flex-input-container input {
  width: 50px; 
  text-align: center;
  border-left: 1px solid #d1d5db;
  border-right: 1px solid #d1d5db;
  outline: none;
  font-size: 14px;
  padding: 0.5rem;
}


.text-gender{
  background: var(--gradient, linear-gradient(90deg, #1E93FD 0%, #3153C7 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; 
}

.button-selector:disabled {
  background: var(--gradient, linear-gradient(90deg, #cccccc 0%, #999999 100%));
  cursor: not-allowed;
}

.number-blue {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  margin-right: 15px;
  background: linear-gradient(90deg, #1E93FD 0%, #3154C8 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
}

.button-continu {
  width: 20%;
  background: linear-gradient(90deg, #1E93FD 0%, #3154C8 100%);
  box-shadow: 0 4px 10px rgba(30, 147, 253, 0.3);
  transition: background 1s ease, box-shadow 1s ease;
  text-align: center; 
  display: flex;
  align-items: center; 
  justify-content: center; 
}

.button-continu:hover {
  background: linear-gradient(90deg, #3154C8 0%, #3154C8 100%);
  box-shadow: 0 4px 10px rgba(30, 147, 253, 0.3);
}

.button-continu:disabled {
  background: linear-gradient(90deg, #B0B0B0 0%, #8C8C8C 100%);
  box-shadow: none;
  cursor: not-allowed;
  color: #E0E0E0;
}

  </style>
  