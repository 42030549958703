<template>
  <div class="flex flex-col sm:flex-row h-screen">
    <div class="flex items-center justify-between w-full sm:hidden bg-white shadow px-4 py-2">
      <button @click="toggleSidebar" class="text-blue-700 relative focus:outline-none">
        <span
          :class="{
            'burger-line': true,
            'transform rotate-45 translate-y-[8px]': isSidebarOpen,
          }"
          class="absolute block w-6 h-0.5 bg-blue-700 transition-transform duration-300"
        ></span>
        <span
          :class="{
            'burger-line': true,
            'opacity-0': isSidebarOpen,
          }"
          class="absolute block w-6 h-0.5 bg-blue-700 transition-opacity duration-300"
        ></span>
        <span
          :class="{
            'burger-line': true,
            'transform -rotate-45 -translate-y-[8px]': isSidebarOpen,
          }"
          class="absolute block w-6 h-0.5 bg-blue-700 transition-transform duration-300"
        ></span>
      </button>
      <img src="../assets/main-logo.png" alt="Logo" class="h-8" />

      <div class="flex items-center gap-4">
      </div>
    </div>

    <transition name="slide-horizontal">
      <aside
        v-if="isSidebarOpen || isLargeScreen"
        class="w-full sm:w-1/5 bg-blue-700 text-white flex flex-col items-center p-4 sm:h-full"  :class="{
          'rounded-none': isSidebarOpen, 
          'rounded-lg': !isSidebarOpen,
          'fixed top-[5%]': isSidebarOpen,
          'sm:relative sm:top-auto sm:left-auto': !isSidebarOpen,
          'profile-box': !isSidebarOpen,
        }"
        style="z-index: 999999999;"
      >
        <img src="../assets/main-logo-white.png" alt="Mirror Profiles Logo" class="w-[10rem] mb-8 mx-auto margin-top-30">

        <nav   :class="computedNavClass"
        class="flex flex-col space-y-8 w-full margin-top-30">
          <router-link
            to="/accounts"
            class="flex items-center px-4 py-2 w-3/4 md:w-2/3 rounded nav-link"
            active-class="active-link"
            @click="closeSidebarOnMobile"
          >
            <i class="fas fa-user mr-[20px]"></i> {{ $t('MainLayout.accounts') }}
          </router-link>
          <a
            href="#"
            @click="redirectToBillingPortal"
            class="flex items-center px-4 py-2 w-3/4 md:w-2/3 rounded nav-link"
          >
            <i class="fas fa-credit-card mr-[20px]"></i> {{ $t('MainLayout.billing') }}
          </a>
          <!-- <router-link
            to="/developer"
            class="flex items-center px-4 py-2 w-3/4 md:w-2/3 rounded nav-link"
            active-class="active-link"
            @click="closeSidebarOnMobile"
          >
            <i class="fas fa-code mr-[20px]"></i> {{ $t('MainLayout.developer') }}
          </router-link> -->
          <a
            href="#"
            @click="redirectToHelp"
            target="_blank"
            class="flex items-center px-4 py-2 w-3/4 md:w-2/3 rounded nav-link"
          >
            <i class="fas fa-question-circle mr-[20px]"></i> {{ $t('MainLayout.help') }}
          </a>
          <router-link
            to="/academy"
            class="flex items-center px-4 py-2 w-3/4 md:w-2/3 rounded nav-link"
            active-class="active-link"
            @click="closeSidebarOnMobile"
          >
            <i class="fas fa-graduation-cap mr-[20px]"></i> {{ $t('MainLayout.academy') }}
          </router-link>
          <router-link
            to="/settings"
            class="flex items-center px-4 py-2 w-3/4 md:w-2/3 rounded nav-link"
            active-class="active-link"
            @click="closeSidebarOnMobile"
          >
            <i class="fas fa-cog mr-[20px]"></i> {{ $t('MainLayout.settings') }}
          </router-link>
        </nav>

        <div class="mt-auto p-4 w-full">
          <div class="flex items-center space-x-2">
            <img :src="selectedFlag" :alt="selectedLanguage" class="w-5 h-auto" />
            <select v-model="selectedLanguage" @change="changeLanguage" class="custom-select bg-transparent text-sm text-white focus:outline-none">
              <option value="en">English</option>
              <option value="fr">Français</option>
            </select>
          </div>
          <hr class="my-2 border-t border-blue-200 espace">
          <div class="flex items-center justify-between flex-wrap md:flex-nowrap">
            <div class="flex-1 min-w-0 mb-2 md:mb-0">
              <p class="text-sm font-semibold truncate">{{ nom }} {{ prenom }}</p>
              <p class="text-xs truncate email">{{ email }}</p>
            </div>
            <button @click="logout" class="text-white flex-shrink-0 mt-2 md:mt-0">
              <i class="fas fa-power-off"></i>
            </button>
          </div>
        </div>
      </aside>
    </transition>

    <main class="flex-1 p-6 overflow-y-auto bg-white">
      <router-view />
    </main>
  </div>
</template>

<script>
import axios from '../plugins/axios';
import franceFlag from '../assets/france-flag.png';
import ukFlag from '../assets/uk-flag.png';

export default {
  name: 'MainLayout',
  data() {
    return {
      isSidebarOpen: false,
      isLargeScreen: window.innerWidth >= 640,
      nom: localStorage.getItem('nom') || '',
      prenom: localStorage.getItem('prenom') || '',
      email: localStorage.getItem('email') || '',
      selectedLanguage: localStorage.getItem('langue') || this.$i18n.locale,
      currentPageTitle: "Comptes",
      windowWidth: window.innerWidth,
      flags: {
          fr: franceFlag,
          en: ukFlag,
        },
    };
  },
  computed: {
    selectedFlag() {
      return this.selectedLanguage === 'fr'
        ? this.flags.fr
        : this.flags.en;
    },
    computedNavClass() {
      if (this.isMobile || this.windowWidth >= 1280) {
        return 'items-center';
      }
      return 'items-start';
    }
  },
  methods: {
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
    },
    handleResize() {
      this.isLargeScreen = window.innerWidth >= 640;
      if (this.isLargeScreen) {
        this.isSidebarOpen = false;
      }
    },
    closeSidebarOnMobile() {
      if (!this.isLargeScreen) {
        this.isSidebarOpen = false;
      }
    },
    redirectToHelp() {
      const baseUrl = 'https://help.mirrorprofiles.com';
      const languagePath = this.selectedLanguage === 'fr' ? 'fr' : 'en';
      window.open(`${baseUrl}/${languagePath}/`, '_blank')
    },
    async redirectToBillingPortal() {
      try {
        const response = await axios.get('/api/billing-portal');
        if (response.data.url) {
          window.location.href = response.data.url;
        } else {
          console.error('URL du portail de facturation non reçue.');
        }
      } catch (error) {
        console.error('Erreur lors de la redirection vers le portail de facturation :', error);
        alert("Une erreur s'est produite lors de la tentative d'accès au portail de facturation.");
      }
    },
    initchangeLanguage() {
      this.selectedLanguage = this.selectedLanguage.split('-')[0];
      this.$i18n.locale = this.selectedLanguage;
      localStorage.setItem('langue', this.selectedLanguage)
      localStorage.setItem('lang', this.selectedLanguage);
    },
    changeLanguage() {
      this.selectedLanguage = this.selectedLanguage.split('-')[0];
      this.$i18n.locale = this.selectedLanguage;
      localStorage.setItem('langue', this.selectedLanguage)
      localStorage.setItem('lang', this.selectedLanguage);
      window.location.reload();
    },
    logout() {
      localStorage.removeItem('token');
      localStorage.removeItem('nom');
      localStorage.removeItem('prenom');
      localStorage.removeItem('langue');
      localStorage.removeItem('lang');
      this.$router.push('/login');
    },
    updateWindowWidth() {
      this.windowWidth = window.innerWidth;
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('resize', this.updateWindowWidth);
    this.initchangeLanguage()
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('resize', this.updateWindowWidth);
  },
};
</script>

<style scoped>
.nav-link {
  font-family: 'Montserrat', sans-serif;
  font-size: clamp(18px, 1vw, 28px);
  font-weight: 400;
  line-height: 16px;
  color: #ffffff;
  text-decoration: none;
  transition: color 0.3s ease, background-color 0.3s ease;
}

.nav-link:hover {
  background-color: #ffffff;
  color: #1E93FD;
  font-weight: 700;
}

.active-link {
  background-color: #ffffff;
  color: #1E93FD;
  font-weight: 700;
}

.espace {
  margin-top: 20px;
  margin-bottom: 10px;
}

.margin-top-30 {
  margin-top: 30px;
}

.email {
  font-family: Montserrat;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  opacity: 0.7;
}

.slide-horizontal-enter-active,
.slide-horizontal-leave-active {
  transition: transform 0.3s ease-in-out;
}

.slide-horizontal-enter-from {
  transform: translateX(-100%);
}

.slide-horizontal-leave-to {
  transform: translateX(-100%);
}

button {
  width: 32px;
  height: 32px;
  position: relative;
}
.burger-line {
  position: absolute;
  left: 0;
  width: 80%;
  height: 4px;
  background-color: #1d4ed8 var(--tw-gradient-to-position);
  transition: transform 0.3s ease, opacity 0.3s ease;
}
.burger-line:nth-child(1) {
  top: 6px;
}
.burger-line:nth-child(2) {
  top: 14px;
}
.burger-line:nth-child(3) {
  top: 22px;
}

@media (max-width: 1280px) {
  .nav-link {
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  aside.fixed {
    top: 5%;
    bottom: 0;
    left: 0;
    width: 100%;
  }
}

@media (min-width: 800px) and (max-width: 1279px) {
  .active-link {
    width: 100%;
  }
}


.custom-select {
  background-color: transparent; 
  border: none;
  color: #ffffff;
  font-family: 'Montserrat', sans-serif; 
  font-size: 14px; 
  padding: 0.5rem;
  cursor: pointer;
}

.custom-select option {
  background-color: #ffffff; 
  color: #1E93FD; 
  font-family: 'Montserrat', sans-serif; 
  font-size: 14px;
}



</style>
