<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import Intercom from '@intercom/messenger-js-sdk';

export default {
  name: 'App',
  mounted() {
    const intercom_app_id = localStorage.getItem('intercom_app_id'); 
    const intercom_email = localStorage.getItem('intercom_email');
    const intercom_user_hash = localStorage.getItem('intercom_user_hash');
    if (intercom_app_id && intercom_user_hash) {
      Intercom({
        app_id: intercom_app_id,
        email: intercom_email,
        user_hash: intercom_user_hash,
      });

      console.log('Intercom initialisé avec succès.');
    } else {
      console.log('Données utilisateur non disponibles pour initialiser Intercom.');
    }
  },
};
</script>

<style>
#app {
  background-color: white;
}
</style>
