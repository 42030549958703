<template>
  <div class="flex h-screen bg-white">
    <div v-if="isMobile" class="w-full flex flex-col items-center p-4 bg-white">
      <div class="login-element w-full max-w-md relative">
        <div class="mb-6 flex justify-center items-center">
          <img src="../assets/main-logo.png" alt="Mirror Profiles Logo" class="w-28 h-auto" />
        </div>
        <h2 class="login-title mb-6 text-center">{{ $t('changePassword.title') }}</h2>
        <p class="text-reset mb-4">{{ $t('changePassword.subtitle') }}</p>
        <form @submit.prevent="handleChangePassword" class="space-y-6">
          <div>
            <label for="newPassword" class="login-text">{{ $t('changePassword.newPassword') }}</label>
            <input
              v-model="newPassword"
              id="newPassword"
              type="password"
              :placeholder="$t('changePassword.newPasswordPlaceholder')"
              required
              class="mt-1 w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-bleu-mirrorProfiles focus:ring-1 focus:ring-bleu-mirrorProfiles"
            />
          </div>

          <div>
            <label for="confirmPassword" class="login-text">{{ $t('changePassword.confirmPassword') }}</label>
            <input
              v-model="confirmPassword"
              id="confirmPassword"
              type="password"
              :placeholder="$t('changePassword.confirmPasswordPlaceholder')"
              required
              class="mt-1 w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-bleu-mirrorProfiles focus:ring-1 focus:ring-bleu-mirrorProfiles"
            />
          </div>

          <button
            type="submit"
            class="button-login w-full py-2 px-4 text-white font-semibold rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-bleu-mirrorProfiles focus:ring-offset-2"
          >
            {{ $t('changePassword.submitButton') }}
          </button>
        </form>

        <p v-if="errorMessage" class="text-red-600 text-center mt-4">{{ errorMessage }}</p>
      </div>
      <h1 class="text-xl font-bold mb-8 mt-8 text-center">
        {{ $t('login.readyToUseAccounts') }}
      </h1>

      <div class="grid grid-cols-1 md:grid-cols-4 gap-6 text-center  mb-8">
          <div class="flex items-center justify-center bg-white text-blue-900 p-6 rounded-lg shadow-lg ">
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="31" viewBox="0 0 30 31" fill="none">
              <g clip-path="url(#clip0_412_1873)">
                <path d="M15.3843 4.3723L16.0538 6.43286H18.2204C18.3052 6.43286 18.3878 6.45971 18.4564 6.50955C18.525 6.5594 18.5761 6.62968 18.6023 6.71033C18.6285 6.79098 18.6285 6.87786 18.6023 6.95852C18.5761 7.03917 18.5251 7.10947 18.4565 7.15932L16.7036 8.43291L17.3731 10.4935C17.3994 10.5741 17.3994 10.661 17.3732 10.7417C17.347 10.8223 17.2959 10.8926 17.2273 10.9425C17.1586 10.9923 17.076 11.0192 16.9912 11.0192C16.9064 11.0191 16.8237 10.9923 16.7551 10.9424L15.0024 9.66881L13.2496 10.9424C13.181 10.9923 13.0984 11.0191 13.0136 11.0192C12.9288 11.0192 12.8461 10.9923 12.7775 10.9425C12.7089 10.8926 12.6578 10.8223 12.6316 10.7417C12.6054 10.661 12.6054 10.5741 12.6316 10.4935L13.3011 8.43278L11.5483 7.15919C11.4797 7.10934 11.4286 7.03904 11.4025 6.95839C11.3763 6.87773 11.3763 6.79085 11.4025 6.7102C11.4287 6.62955 11.4798 6.55927 11.5484 6.50943C11.617 6.45958 11.6996 6.43273 11.7844 6.43273H13.9509L14.6204 4.37217C14.6467 4.29151 14.6977 4.22123 14.7664 4.17139C14.835 4.12155 14.9176 4.09471 15.0024 4.09473C15.0872 4.09474 15.1699 4.1216 15.2385 4.17147C15.3071 4.22133 15.3581 4.29163 15.3843 4.3723Z" fill="#FBB540"/>
                <path d="M7.31112 8.1384L7.84124 9.77072H9.55755C9.6247 9.77076 9.69012 9.79204 9.74443 9.83152C9.79875 9.871 9.83919 9.92665 9.85994 9.99051C9.8807 10.0544 9.88072 10.1232 9.86 10.187C9.83928 10.2509 9.79887 10.3066 9.74458 10.3461L8.35604 11.3546L8.88617 12.987C8.9069 13.0508 8.90687 13.1197 8.88609 13.1835C8.86532 13.2474 8.82486 13.3031 8.77051 13.3425C8.71616 13.382 8.65071 13.4033 8.58354 13.4033C8.51637 13.4033 8.45092 13.382 8.39658 13.3425L7.00804 12.3337L5.61951 13.3425C5.56516 13.382 5.49973 13.4032 5.43257 13.4032C5.36541 13.4032 5.29998 13.3819 5.24565 13.3425C5.19132 13.303 5.15086 13.2474 5.13009 13.1835C5.10931 13.1196 5.10927 13.0508 5.12999 12.987L5.66011 11.3546L4.27157 10.3461C4.21728 10.3066 4.17688 10.2509 4.15616 10.1871C4.13543 10.1232 4.13545 10.0544 4.1562 9.99055C4.17695 9.92669 4.21737 9.87103 4.27168 9.83155C4.32599 9.79206 4.39139 9.77077 4.45854 9.77072H6.17485L6.70497 8.1384C6.72548 8.07412 6.7659 8.01804 6.82038 7.97824C6.87486 7.93844 6.94058 7.91699 7.00804 7.91699C7.07551 7.91699 7.14123 7.93844 7.19571 7.97824C7.25019 8.01804 7.29061 8.07412 7.31112 8.1384Z" fill="#FBB540"/>
                <path d="M3.12707 14.8615L3.6572 16.4939H5.37376C5.44091 16.4939 5.50631 16.5152 5.56062 16.5547C5.61493 16.5942 5.65535 16.6498 5.6761 16.7137C5.69685 16.7775 5.69687 16.8463 5.67614 16.9102C5.65542 16.9741 5.61502 17.0297 5.56073 17.0692L4.17219 18.0778L4.70231 19.7101C4.72303 19.774 4.72299 19.8428 4.70221 19.9066C4.68144 19.9705 4.64098 20.0261 4.58665 20.0656C4.53231 20.1051 4.46688 20.1263 4.39973 20.1264C4.33257 20.1264 4.26714 20.1051 4.21279 20.0657L2.82451 19.0573L1.43598 20.0662C1.38163 20.1057 1.31619 20.1269 1.24902 20.1269C1.18185 20.1269 1.1164 20.1057 1.06205 20.0662C1.0077 20.0267 0.967242 19.9711 0.946466 19.9072C0.925689 19.8433 0.925663 19.7745 0.946391 19.7106L1.47651 18.0783L0.0879796 17.0698C0.0336836 17.0303 -0.00671826 16.9746 -0.0274409 16.9107C-0.0481635 16.8468 -0.0481442 16.778 -0.0273858 16.7142C-0.00662735 16.6503 0.0338056 16.5947 0.0881237 16.5552C0.142442 16.5157 0.207859 16.4944 0.27501 16.4944H1.99157L2.5217 14.8621C2.54235 14.7981 2.58275 14.7422 2.63709 14.7026C2.69144 14.663 2.75695 14.6417 2.8242 14.6416C2.89145 14.6415 2.95699 14.6628 3.0114 14.7023C3.06582 14.7418 3.10631 14.7976 3.12707 14.8615Z" fill="#FBB540"/>
                <path d="M27.4347 14.8619L27.9648 16.4943H29.6814C29.7485 16.4943 29.8139 16.5156 29.8682 16.5551C29.9225 16.5946 29.963 16.6502 29.9837 16.7141C30.0045 16.7779 30.0045 16.8467 29.9838 16.9106C29.963 16.9745 29.9226 17.0301 29.8683 17.0696L28.4798 18.0782L29.0099 19.7105C29.0306 19.7744 29.0306 19.8432 29.0098 19.907C28.9891 19.9709 28.9486 20.0266 28.8943 20.066C28.8399 20.1055 28.7745 20.1267 28.7073 20.1268C28.6402 20.1268 28.5748 20.1055 28.5204 20.0661L27.1319 19.0572L25.7436 20.0666C25.6893 20.1061 25.6238 20.1273 25.5566 20.1273C25.4895 20.1273 25.424 20.1061 25.3697 20.0666C25.3153 20.0271 25.2749 19.9715 25.2541 19.9076C25.2333 19.8437 25.2333 19.7749 25.254 19.711L25.7841 18.0787L24.3956 17.0702C24.3413 17.0307 24.3009 16.975 24.2802 16.9111C24.2595 16.8472 24.2595 16.7784 24.2802 16.7146C24.301 16.6507 24.3414 16.5951 24.3957 16.5556C24.4501 16.5161 24.5155 16.4948 24.5826 16.4948H26.2989L26.8291 14.8625C26.8496 14.7984 26.89 14.7424 26.9444 14.7028C26.9988 14.6631 27.0644 14.6417 27.1317 14.6416C27.199 14.6415 27.2646 14.6628 27.3191 14.7024C27.3735 14.742 27.414 14.7979 27.4347 14.8619Z" fill="#FBB540"/>
                <path d="M23.298 8.13831L23.8281 9.77064H25.5447C25.6118 9.77068 25.6772 9.79197 25.7315 9.83146C25.7858 9.87094 25.8263 9.9266 25.847 9.99046C25.8678 10.0543 25.8678 10.1231 25.847 10.187C25.8263 10.2508 25.7859 10.3065 25.7316 10.346L24.3431 11.3545L24.8732 12.9869C24.8939 13.0507 24.8939 13.1195 24.8731 13.1834C24.8523 13.2473 24.8119 13.3029 24.7575 13.3424C24.7032 13.3819 24.6378 13.4031 24.5706 13.4031C24.5035 13.4031 24.438 13.3819 24.3837 13.3424L22.9952 12.3336L21.6069 13.343C21.5525 13.3824 21.4871 13.4037 21.4199 13.4037C21.3527 13.4037 21.2873 13.3824 21.233 13.343C21.1786 13.3035 21.1381 13.2478 21.1174 13.184C21.0966 13.1201 21.0966 13.0513 21.1173 12.9874L21.6474 11.3551L20.2589 10.3465C20.2046 10.307 20.1642 10.2513 20.1435 10.1875C20.1227 10.1236 20.1228 10.0548 20.1435 9.99094C20.1643 9.92708 20.2047 9.87143 20.259 9.83195C20.3133 9.79247 20.3788 9.77119 20.4459 9.77115H22.1622L22.6923 8.13883C22.7129 8.07473 22.7533 8.01881 22.8077 7.97913C22.8621 7.93944 22.9276 7.91803 22.995 7.91797C23.0623 7.91791 23.1279 7.93921 23.1823 7.97881C23.2368 8.0184 23.2773 8.07425 23.298 8.13831Z" fill="#FBB540"/>
                <path d="M7.76074 19.1387H11.8983V26.3794H7.76074V19.1387Z" fill="#0296E5"/>
                <path d="M21.2079 19.1391H17.0704V16.036C17.0704 15.6245 16.9069 15.2298 16.6159 14.9388C16.3249 14.6478 15.9303 14.4844 15.5188 14.4844H14.4844L14.2943 15.815C14.1934 16.5215 13.9227 17.193 13.5054 17.7719C13.0882 18.3508 12.5367 18.82 11.8984 19.1391V26.3798L12.3673 26.6924C13.0733 27.1631 13.9028 27.4142 14.7513 27.4142H20.6907C20.9651 27.4142 21.2282 27.3052 21.4221 27.1112C21.6161 26.9173 21.7251 26.6542 21.7251 26.3798C21.7251 26.1055 21.6161 25.8424 21.4221 25.6484C21.2282 25.4544 20.9651 25.3454 20.6907 25.3454C20.9651 25.3454 21.2282 25.2365 21.4221 25.0425C21.6161 24.8485 21.7251 24.5854 21.7251 24.3111C21.7251 24.0367 21.6161 23.7736 21.4221 23.5796C21.2282 23.3856 20.9651 23.2767 20.6907 23.2767C20.9651 23.2767 21.2282 23.1677 21.4221 22.9737C21.6161 22.7797 21.7251 22.5166 21.7251 22.2423C21.7251 21.9679 21.6161 21.7048 21.4221 21.5109C21.2282 21.3169 20.9651 21.2079 20.6907 21.2079H21.2079C21.4823 21.2079 21.7454 21.0989 21.9393 20.9049C22.1333 20.7109 22.2423 20.4478 22.2423 20.1735C22.2423 19.8992 22.1333 19.6361 21.9393 19.4421C21.7454 19.2481 21.4823 19.1391 21.2079 19.1391Z" fill="#FDC9A6"/>
              </g>
              <defs>
                <clipPath id="clip0_412_1873">
                  <rect width="30" height="30" fill="white" transform="translate(0 0.5)"/>
                </clipPath>
              </defs>
            </svg>
            <div class="text-left ml-2">
              <p class="text-indicator-login">300+</p>
              <p>{{ $t('login.satisfiedUsers') }}</p>
            </div>
          </div>
          <div class="flex items-center justify-center bg-white text-blue-900 p-6 rounded-lg shadow-lg">
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="31" viewBox="0 0 30 31" fill="none">
              <path d="M9.3452 9.86118C9.3452 6.90492 11.7654 4.5 14.74 4.5C17.7252 4.5 20.1539 6.9051 20.1539 9.86118C20.1539 12.8173 17.7252 15.2222 14.74 15.2222C11.7654 15.2222 9.3452 12.8173 9.3452 9.86118ZM18.4811 25.6426C18.2866 25.3635 18.1356 25.1978 17.928 25.0591C17.7389 24.933 17.5042 24.8303 17.1458 24.6862C16.6039 24.4701 16.1762 24.0504 15.9434 23.5047C15.7087 22.9589 15.6978 22.3537 15.9106 21.8008C16.2417 20.9416 17.0385 20.3868 17.9426 20.3868C18.2173 20.3868 18.4865 20.4391 18.7414 20.5399C19.0741 20.6732 19.4143 20.8209 19.7563 21.001C19.7819 20.9686 19.809 20.938 19.8347 20.9074C20.1567 20.5219 20.4985 20.1743 20.8623 19.8501L16.4691 17.0151C15.9707 16.6872 15.3758 16.5125 14.7482 16.5125C14.1224 16.5125 13.5239 16.6854 13.0182 17.0114L7.34434 20.6714L5.14865 22.0817C4.12266 22.741 3.74264 23.8397 4.17723 24.8753C4.58108 25.8624 5.63799 26.5 6.86773 26.5H19.6964C19.2124 26.3685 18.7794 26.0677 18.4811 25.6426ZM24.8948 19.6738C24.6867 19.3335 24.2393 19.2244 23.8955 19.4306C22.1981 20.4481 21.067 21.3288 20.1159 22.8969C19.5773 22.4007 18.9919 22.1077 18.2089 21.8038C17.8349 21.6581 17.4127 21.8409 17.2659 22.2114C17.1191 22.5819 17.3035 23 17.6777 23.1451C18.5791 23.4953 19.0692 23.7507 19.666 24.5778C19.8034 24.768 20.0244 24.8793 20.258 24.8793C20.2789 24.8793 20.2998 24.8784 20.3211 24.8766C20.5774 24.8546 20.8028 24.7003 20.9143 24.4706C21.7455 22.7559 22.5834 21.902 24.6494 20.6633C24.993 20.4573 25.1027 20.0142 24.8948 19.6738Z" fill="#1E93FD"/>
            </svg>
            <div class="text-left ml-2">
              <p class="text-indicator-login">2000+</p>
              <p>{{ $t('login.activeAccounts') }}</p>
            </div>
          </div>
          <div class="flex items-center justify-center bg-white text-blue-900 p-6 rounded-lg shadow-lg">
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="31" viewBox="0 0 30 31" fill="none">
              <path d="M24.2972 6.61795C22.7738 6.45656 21.2744 6.11766 19.8296 5.60816C18.5041 5.13976 17.2326 4.53095 16.0366 3.79213C15.7247 3.6011 15.366 3.5 15.0002 3.5C14.6344 3.5 14.2757 3.6011 13.9637 3.79213C12.7677 4.53097 11.4962 5.13977 10.1707 5.60816C8.72595 6.11767 7.22656 6.45658 5.70308 6.61799C5.2141 6.66818 4.76114 6.89804 4.4319 7.26304C4.10267 7.62804 3.92058 8.10223 3.9209 8.59378V14.4974C3.92168 16.6757 4.50457 18.8143 5.60924 20.6918C6.71391 22.5692 8.30023 24.1174 10.204 25.1761L14.0336 27.3033C14.3287 27.4688 14.6613 27.5557 14.9995 27.5558C15.3378 27.5559 15.6704 27.4692 15.9656 27.3039L19.7963 25.1761C21.7001 24.1174 23.2864 22.5692 24.3911 20.6918C25.4958 18.8143 26.0786 16.6757 26.0794 14.4974V8.59378C26.0798 8.10222 25.8977 7.62803 25.5684 7.26302C25.2392 6.89801 24.7862 6.66815 24.2972 6.61795Z" fill="#0DB561"/>
              <path d="M14.2047 19.1766C13.8706 19.177 13.5482 19.0541 13.2991 18.8315L10.061 15.9535C9.92666 15.8348 9.81704 15.6907 9.73845 15.5295C9.65987 15.3683 9.61386 15.1932 9.60306 15.0141C9.59226 14.8351 9.61689 14.6558 9.67553 14.4863C9.73417 14.3168 9.82567 14.1606 9.9448 14.0265C10.0639 13.8925 10.2083 13.7833 10.3697 13.7051C10.5311 13.627 10.7064 13.5814 10.8854 13.5711C11.0644 13.5608 11.2437 13.5859 11.413 13.645C11.5824 13.7041 11.7383 13.7961 11.8721 13.9156L14.1181 15.9113L18.608 11.1224C18.8554 10.8591 19.1972 10.7047 19.5583 10.6931C19.9195 10.6814 20.2705 10.8135 20.5344 11.0602C20.7982 11.3077 20.9531 11.6497 20.9649 12.0112C20.9768 12.3727 20.8447 12.7242 20.5977 12.9884L15.2001 18.746C15.0727 18.8825 14.9186 18.9911 14.7473 19.0652C14.576 19.1393 14.3913 19.1772 14.2047 19.1766Z" fill="white"/>
            </svg>
            <div class="text-left ml-2 ">
              <p class="text-indicator-login">100%</p>
              <p>{{ $t('login.safetyGuarantee') }}</p>
            </div>
          </div>
          <div class="flex items-center justify-center bg-white text-blue-900 p-6 rounded-lg shadow-lg">
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="31" viewBox="0 0 30 31" fill="none">
              <path d="M18.7582 19.5535L21 16.1204L18.7582 15.5367C18.4646 14.6463 17.9652 13.8052 17.2604 13.0929C16.5654 12.3904 15.7431 11.8759 14.8424 11.5791L14.8131 11.6088L12.0818 11.5L10.8679 11.5791C9.9673 11.8759 9.145 12.3904 8.44992 13.0929L4.82083 16.7607C2.39306 19.2143 2.39306 23.2021 4.82083 25.6557C7.31712 28.1786 11.2623 28.05 13.6313 25.6557L17.2604 21.9973C17.9653 21.2849 18.4646 20.444 18.7582 19.5535ZM11.184 23.1822C10.0876 24.2904 8.3353 24.2607 7.2682 23.1822C6.19137 22.0939 6.19137 20.313 7.2682 19.2247L10.8679 15.596L12.8356 17.5648L14.8131 19.5238L11.184 23.1822Z" fill="#1E93FD"/>
              <path d="M22.5 24.2425L21.2575 23C20.9142 22.6566 20.9142 22.1009 21.2575 21.7575C21.6009 21.4142 22.1566 21.4141 22.5 21.7575L23.7425 23C24.0858 23.3434 24.0858 23.8991 23.7425 24.2425C23.399 24.5858 22.8434 24.5858 22.5 24.2425Z" fill="#D2C5C2"/>
              <path d="M7.50012 9.24253L6.25754 8C5.91415 7.65661 5.91415 7.10085 6.25754 6.75752C6.60092 6.41419 7.15667 6.41413 7.5 6.75752L8.74246 8C9.08585 8.34339 9.08585 8.89914 8.74246 9.24247C8.39908 9.5858 7.84344 9.58586 7.50012 9.24253Z" fill="#DFD7D5"/>
              <path d="M24.0261 18.0888C24.1351 17.6649 24.5534 17.4138 24.9606 17.5273L26.4347 17.9385C26.8419 18.0498 27.0821 18.4874 26.9742 18.9112C26.8651 19.3351 26.4468 19.5862 26.0396 19.4727L24.5656 19.0615C24.161 18.9488 23.9169 18.5152 24.0261 18.0888Z" fill="#D2C5C2"/>
              <path d="M3.02607 12.0888C3.13511 11.6649 3.5534 11.4138 3.9606 11.5273L5.43462 11.9385C5.8403 12.0493 6.08258 12.4869 5.9741 12.9112C5.86506 13.3351 5.44677 13.5862 5.03957 13.4727L3.56555 13.0615C3.16099 12.9488 2.91688 12.5151 3.02607 12.0888Z" fill="#DFD7D5"/>
              <path d="M17.4386 26.9345L17.0273 25.4607C16.9138 25.0535 17.1649 24.6353 17.5888 24.5263C18.0127 24.4173 18.4475 24.6569 18.5615 25.0657L18.9727 26.5395C19.0862 26.9467 18.8351 27.3649 18.4112 27.474C17.9849 27.5831 17.5513 27.339 17.4386 26.9345Z" fill="#D2C5C2"/>
              <path d="M11.4385 5.9345L11.0273 4.46048C10.9138 4.05322 11.1649 3.63497 11.5888 3.52594C12.0127 3.41797 12.4485 3.65659 12.5615 4.06542L12.9727 5.53945C13.0862 5.94671 12.8351 6.36495 12.4112 6.47399C11.9849 6.58303 11.5513 6.33896 11.4385 5.9345Z" fill="#DFD7D5"/>
              <path d="M25.1883 5.35316C22.7729 2.88728 18.8476 2.87729 16.4224 5.35316L12.8117 9.02985C12.1104 9.74573 11.6136 10.5909 11.3214 11.4858C10.8928 12.7983 10.8929 14.2102 11.3215 15.5227C11.6136 16.4176 12.1104 17.2628 12.8117 17.9787C13.4935 18.6748 14.3116 19.1917 15.2175 19.5L15.2466 19.4702C16.3181 18.3764 16.318 16.5867 15.2466 15.4929C14.1753 14.3992 14.1753 12.6094 15.2466 11.5157L18.8574 7.83896C19.3834 7.30198 20.0652 7.02362 20.8055 7.02362C20.8249 7.00379 20.9904 7.01368 21.0295 7.03356C21.5554 7.03351 22.2469 7.32185 22.7534 7.83896C23.8248 8.93269 23.8248 10.7224 22.7534 11.8162L19.1719 15.463C19.6005 16.7755 19.4286 18.1875 19 19.5C20 19.5 20.8959 18.6747 21.5777 17.9787L25.1884 14.302C27.6039 11.8361 27.6038 7.81904 25.1883 5.35316Z" fill="#3153C7"/>
            </svg>
            <div class="text-left ml-2">
              <p class="text-indicator-login">500+</p>
              <p>{{ $t('login.connections') }}</p>
            </div>
            
          </div>
        </div>
        <div class="header flex justify-between items-center mb-6">
          <div 
            :class="[
              isMobile ? 'fixed bottom-5 left-5 z-10' : 'absolute bottom-[5%] left-[5%] z-10'
            ]"
          >
            <div 
              class="bg-white custom-border text-blue-700 px-4 py-1 rounded-full flex items-center shadow-md cursor-pointer"
              @click="toggleLanguageDropdown"
            >
              <img :src="currentFlag" alt="Langue" class="w-8 h-8 mr-2" />
              <i class="fas fa-chevron-down"></i>
            </div>

            <div 
              v-if="showLanguageDropdown" 
              class="absolute top-[-200%] left-0 mt-2 bg-white shadow-lg rounded-lg p-2 w-32"
            >
              <button
                @click="setLanguage('fr')"
                class="flex items-center justify-start w-full px-2 py-1 hover:bg-blue-100 rounded-md transition"
              >
                <img src="../assets/france-flag.png" alt="Français" class="w-6 h-6 mr-2" />
                Français
              </button>
              <button
                @click="setLanguage('en')"
                class="flex items-center justify-start w-full px-2 py-1 hover:bg-blue-100 rounded-md transition"
              >
                <img src="../assets/uk-flag.png" alt="English" class="w-6 h-6 mr-2" />
                English
              </button>
            </div>
          </div>
        </div>

    </div>
    <div v-if="!isMobile" class="relative w-full md:w-1/3 flex flex-col justify-center items-center bg-white">
      
      <div class="login-element w-full max-w-md px-8 relative">
        <div class="mb-6">
          <img src="../assets/main-logo.png" alt="Mirror Profiles Logo" class="w-36 h-auto" />
        </div>
        <h2 class="login-title mb-6">{{ $t('changePassword.title') }}</h2>
        <p class="text-reset mb-4">{{ $t('changePassword.subtitle') }}</p>
        <form @submit.prevent="handleChangePassword" class="space-y-6">
          <div>
            <label for="newPassword" class="login-text">{{ $t('changePassword.newPassword') }}</label>
            <input
              v-model="password"
              id="password"
              type="password"
              :placeholder="$t('changePassword.newPasswordPlaceholder')"
              required
              class="mt-1 w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-bleu-mirrorProfiles focus:ring-1 focus:ring-bleu-mirrorProfiles"
            />
          </div>

          <div>
            <label for="confirmPassword" class="login-text">{{ $t('changePassword.confirmPassword') }}</label>
            <input
              v-model="confirmPassword"
              id="confirmPassword"
              type="password"
              :placeholder="$t('changePassword.confirmPasswordPlaceholder')"
              required
              class="mt-1 w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-bleu-mirrorProfiles focus:ring-1 focus:ring-bleu-mirrorProfiles"
            />
          </div>

          <button
            type="submit"
            class="button-login w-full py-2 px-4 text-white font-semibold rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-bleu-mirrorProfiles focus:ring-offset-2"
          >
            {{ $t('changePassword.submitButton') }}
          </button>
        </form>

        <p v-if="errorMessage" class="text-red-600 text-center mt-4">{{ errorMessage }}</p>
      </div>
      <div class="header flex justify-between items-center mb-6">
        <div :class="['absolute', isMobile ? 'bottom-5 left-0 z-10' : 'bottom-[5%] left-[5%] z-10']">
          <div 
            class="bg-white custom-border text-blue-700 px-4 py-1 rounded-full flex items-center shadow-md cursor-pointer"
            @click="toggleLanguageDropdown"
          >
            <img :src="currentFlag" alt="Langue" class="w-8 h-8 mr-2" />
            <i class="fas fa-chevron-down"></i>
          </div>

          <div 
            v-if="showLanguageDropdown" 
            class="absolute top-[-200%] left-[3%] right-0 mt-2 bg-white shadow-lg rounded-lg p-2 w-32"
          >
            <button
              @click="setLanguage('fr')"
              class="flex items-center justify-start w-full px-2 py-1 hover:bg-blue-100 rounded-md transition"
            >
              <img src="../assets/france-flag.png" alt="Français" class="w-6 h-6 mr-2" />
              Français
            </button>
            <button
              @click="setLanguage('en')"
              class="flex items-center justify-start w-full px-2 py-1 hover:bg-blue-100 rounded-md transition"
            >
              <img src="../assets/uk-flag.png" alt="English" class="w-6 h-6 mr-2" />
              English
            </button>
          </div>
        </div>
      </div>
    </div>


    <div class="rounded-tl-[30px] hidden md:flex w-full text-white relative" style="background: linear-gradient(184deg, #1E93FD 0.49%, #3154C8 83.27%);">
      <div class="flex flex-col w-[80%] mx-auto my-auto">
        <h1 class="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold mb-8">
          {{ $t('login.readyToUseAccounts') }}      
        </h1>

        <div class="grid grid-cols-1 md:grid-cols-4 gap-6 text-center bg-white text-blue-900 p-6 rounded-lg shadow-lg mb-8">
          <div class="flex items-center justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="31" viewBox="0 0 30 31" fill="none">
              <g clip-path="url(#clip0_412_1873)">
                <path d="M15.3843 4.3723L16.0538 6.43286H18.2204C18.3052 6.43286 18.3878 6.45971 18.4564 6.50955C18.525 6.5594 18.5761 6.62968 18.6023 6.71033C18.6285 6.79098 18.6285 6.87786 18.6023 6.95852C18.5761 7.03917 18.5251 7.10947 18.4565 7.15932L16.7036 8.43291L17.3731 10.4935C17.3994 10.5741 17.3994 10.661 17.3732 10.7417C17.347 10.8223 17.2959 10.8926 17.2273 10.9425C17.1586 10.9923 17.076 11.0192 16.9912 11.0192C16.9064 11.0191 16.8237 10.9923 16.7551 10.9424L15.0024 9.66881L13.2496 10.9424C13.181 10.9923 13.0984 11.0191 13.0136 11.0192C12.9288 11.0192 12.8461 10.9923 12.7775 10.9425C12.7089 10.8926 12.6578 10.8223 12.6316 10.7417C12.6054 10.661 12.6054 10.5741 12.6316 10.4935L13.3011 8.43278L11.5483 7.15919C11.4797 7.10934 11.4286 7.03904 11.4025 6.95839C11.3763 6.87773 11.3763 6.79085 11.4025 6.7102C11.4287 6.62955 11.4798 6.55927 11.5484 6.50943C11.617 6.45958 11.6996 6.43273 11.7844 6.43273H13.9509L14.6204 4.37217C14.6467 4.29151 14.6977 4.22123 14.7664 4.17139C14.835 4.12155 14.9176 4.09471 15.0024 4.09473C15.0872 4.09474 15.1699 4.1216 15.2385 4.17147C15.3071 4.22133 15.3581 4.29163 15.3843 4.3723Z" fill="#FBB540"/>
                <path d="M7.31112 8.1384L7.84124 9.77072H9.55755C9.6247 9.77076 9.69012 9.79204 9.74443 9.83152C9.79875 9.871 9.83919 9.92665 9.85994 9.99051C9.8807 10.0544 9.88072 10.1232 9.86 10.187C9.83928 10.2509 9.79887 10.3066 9.74458 10.3461L8.35604 11.3546L8.88617 12.987C8.9069 13.0508 8.90687 13.1197 8.88609 13.1835C8.86532 13.2474 8.82486 13.3031 8.77051 13.3425C8.71616 13.382 8.65071 13.4033 8.58354 13.4033C8.51637 13.4033 8.45092 13.382 8.39658 13.3425L7.00804 12.3337L5.61951 13.3425C5.56516 13.382 5.49973 13.4032 5.43257 13.4032C5.36541 13.4032 5.29998 13.3819 5.24565 13.3425C5.19132 13.303 5.15086 13.2474 5.13009 13.1835C5.10931 13.1196 5.10927 13.0508 5.12999 12.987L5.66011 11.3546L4.27157 10.3461C4.21728 10.3066 4.17688 10.2509 4.15616 10.1871C4.13543 10.1232 4.13545 10.0544 4.1562 9.99055C4.17695 9.92669 4.21737 9.87103 4.27168 9.83155C4.32599 9.79206 4.39139 9.77077 4.45854 9.77072H6.17485L6.70497 8.1384C6.72548 8.07412 6.7659 8.01804 6.82038 7.97824C6.87486 7.93844 6.94058 7.91699 7.00804 7.91699C7.07551 7.91699 7.14123 7.93844 7.19571 7.97824C7.25019 8.01804 7.29061 8.07412 7.31112 8.1384Z" fill="#FBB540"/>
                <path d="M3.12707 14.8615L3.6572 16.4939H5.37376C5.44091 16.4939 5.50631 16.5152 5.56062 16.5547C5.61493 16.5942 5.65535 16.6498 5.6761 16.7137C5.69685 16.7775 5.69687 16.8463 5.67614 16.9102C5.65542 16.9741 5.61502 17.0297 5.56073 17.0692L4.17219 18.0778L4.70231 19.7101C4.72303 19.774 4.72299 19.8428 4.70221 19.9066C4.68144 19.9705 4.64098 20.0261 4.58665 20.0656C4.53231 20.1051 4.46688 20.1263 4.39973 20.1264C4.33257 20.1264 4.26714 20.1051 4.21279 20.0657L2.82451 19.0573L1.43598 20.0662C1.38163 20.1057 1.31619 20.1269 1.24902 20.1269C1.18185 20.1269 1.1164 20.1057 1.06205 20.0662C1.0077 20.0267 0.967242 19.9711 0.946466 19.9072C0.925689 19.8433 0.925663 19.7745 0.946391 19.7106L1.47651 18.0783L0.0879796 17.0698C0.0336836 17.0303 -0.00671826 16.9746 -0.0274409 16.9107C-0.0481635 16.8468 -0.0481442 16.778 -0.0273858 16.7142C-0.00662735 16.6503 0.0338056 16.5947 0.0881237 16.5552C0.142442 16.5157 0.207859 16.4944 0.27501 16.4944H1.99157L2.5217 14.8621C2.54235 14.7981 2.58275 14.7422 2.63709 14.7026C2.69144 14.663 2.75695 14.6417 2.8242 14.6416C2.89145 14.6415 2.95699 14.6628 3.0114 14.7023C3.06582 14.7418 3.10631 14.7976 3.12707 14.8615Z" fill="#FBB540"/>
                <path d="M27.4347 14.8619L27.9648 16.4943H29.6814C29.7485 16.4943 29.8139 16.5156 29.8682 16.5551C29.9225 16.5946 29.963 16.6502 29.9837 16.7141C30.0045 16.7779 30.0045 16.8467 29.9838 16.9106C29.963 16.9745 29.9226 17.0301 29.8683 17.0696L28.4798 18.0782L29.0099 19.7105C29.0306 19.7744 29.0306 19.8432 29.0098 19.907C28.9891 19.9709 28.9486 20.0266 28.8943 20.066C28.8399 20.1055 28.7745 20.1267 28.7073 20.1268C28.6402 20.1268 28.5748 20.1055 28.5204 20.0661L27.1319 19.0572L25.7436 20.0666C25.6893 20.1061 25.6238 20.1273 25.5566 20.1273C25.4895 20.1273 25.424 20.1061 25.3697 20.0666C25.3153 20.0271 25.2749 19.9715 25.2541 19.9076C25.2333 19.8437 25.2333 19.7749 25.254 19.711L25.7841 18.0787L24.3956 17.0702C24.3413 17.0307 24.3009 16.975 24.2802 16.9111C24.2595 16.8472 24.2595 16.7784 24.2802 16.7146C24.301 16.6507 24.3414 16.5951 24.3957 16.5556C24.4501 16.5161 24.5155 16.4948 24.5826 16.4948H26.2989L26.8291 14.8625C26.8496 14.7984 26.89 14.7424 26.9444 14.7028C26.9988 14.6631 27.0644 14.6417 27.1317 14.6416C27.199 14.6415 27.2646 14.6628 27.3191 14.7024C27.3735 14.742 27.414 14.7979 27.4347 14.8619Z" fill="#FBB540"/>
                <path d="M23.298 8.13831L23.8281 9.77064H25.5447C25.6118 9.77068 25.6772 9.79197 25.7315 9.83146C25.7858 9.87094 25.8263 9.9266 25.847 9.99046C25.8678 10.0543 25.8678 10.1231 25.847 10.187C25.8263 10.2508 25.7859 10.3065 25.7316 10.346L24.3431 11.3545L24.8732 12.9869C24.8939 13.0507 24.8939 13.1195 24.8731 13.1834C24.8523 13.2473 24.8119 13.3029 24.7575 13.3424C24.7032 13.3819 24.6378 13.4031 24.5706 13.4031C24.5035 13.4031 24.438 13.3819 24.3837 13.3424L22.9952 12.3336L21.6069 13.343C21.5525 13.3824 21.4871 13.4037 21.4199 13.4037C21.3527 13.4037 21.2873 13.3824 21.233 13.343C21.1786 13.3035 21.1381 13.2478 21.1174 13.184C21.0966 13.1201 21.0966 13.0513 21.1173 12.9874L21.6474 11.3551L20.2589 10.3465C20.2046 10.307 20.1642 10.2513 20.1435 10.1875C20.1227 10.1236 20.1228 10.0548 20.1435 9.99094C20.1643 9.92708 20.2047 9.87143 20.259 9.83195C20.3133 9.79247 20.3788 9.77119 20.4459 9.77115H22.1622L22.6923 8.13883C22.7129 8.07473 22.7533 8.01881 22.8077 7.97913C22.8621 7.93944 22.9276 7.91803 22.995 7.91797C23.0623 7.91791 23.1279 7.93921 23.1823 7.97881C23.2368 8.0184 23.2773 8.07425 23.298 8.13831Z" fill="#FBB540"/>
                <path d="M7.76074 19.1387H11.8983V26.3794H7.76074V19.1387Z" fill="#0296E5"/>
                <path d="M21.2079 19.1391H17.0704V16.036C17.0704 15.6245 16.9069 15.2298 16.6159 14.9388C16.3249 14.6478 15.9303 14.4844 15.5188 14.4844H14.4844L14.2943 15.815C14.1934 16.5215 13.9227 17.193 13.5054 17.7719C13.0882 18.3508 12.5367 18.82 11.8984 19.1391V26.3798L12.3673 26.6924C13.0733 27.1631 13.9028 27.4142 14.7513 27.4142H20.6907C20.9651 27.4142 21.2282 27.3052 21.4221 27.1112C21.6161 26.9173 21.7251 26.6542 21.7251 26.3798C21.7251 26.1055 21.6161 25.8424 21.4221 25.6484C21.2282 25.4544 20.9651 25.3454 20.6907 25.3454C20.9651 25.3454 21.2282 25.2365 21.4221 25.0425C21.6161 24.8485 21.7251 24.5854 21.7251 24.3111C21.7251 24.0367 21.6161 23.7736 21.4221 23.5796C21.2282 23.3856 20.9651 23.2767 20.6907 23.2767C20.9651 23.2767 21.2282 23.1677 21.4221 22.9737C21.6161 22.7797 21.7251 22.5166 21.7251 22.2423C21.7251 21.9679 21.6161 21.7048 21.4221 21.5109C21.2282 21.3169 20.9651 21.2079 20.6907 21.2079H21.2079C21.4823 21.2079 21.7454 21.0989 21.9393 20.9049C22.1333 20.7109 22.2423 20.4478 22.2423 20.1735C22.2423 19.8992 22.1333 19.6361 21.9393 19.4421C21.7454 19.2481 21.4823 19.1391 21.2079 19.1391Z" fill="#FDC9A6"/>
              </g>
              <defs>
                <clipPath id="clip0_412_1873">
                  <rect width="30" height="30" fill="white" transform="translate(0 0.5)"/>
                </clipPath>
              </defs>
            </svg>
            <div class="text-left ml-2">
              <p class="text-indicator-login">300+</p>
              <p>{{ $t('login.satisfiedUsers') }}</p>
            </div>
          </div>
          <div class="flex items-center justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="31" viewBox="0 0 30 31" fill="none">
              <path d="M9.3452 9.86118C9.3452 6.90492 11.7654 4.5 14.74 4.5C17.7252 4.5 20.1539 6.9051 20.1539 9.86118C20.1539 12.8173 17.7252 15.2222 14.74 15.2222C11.7654 15.2222 9.3452 12.8173 9.3452 9.86118ZM18.4811 25.6426C18.2866 25.3635 18.1356 25.1978 17.928 25.0591C17.7389 24.933 17.5042 24.8303 17.1458 24.6862C16.6039 24.4701 16.1762 24.0504 15.9434 23.5047C15.7087 22.9589 15.6978 22.3537 15.9106 21.8008C16.2417 20.9416 17.0385 20.3868 17.9426 20.3868C18.2173 20.3868 18.4865 20.4391 18.7414 20.5399C19.0741 20.6732 19.4143 20.8209 19.7563 21.001C19.7819 20.9686 19.809 20.938 19.8347 20.9074C20.1567 20.5219 20.4985 20.1743 20.8623 19.8501L16.4691 17.0151C15.9707 16.6872 15.3758 16.5125 14.7482 16.5125C14.1224 16.5125 13.5239 16.6854 13.0182 17.0114L7.34434 20.6714L5.14865 22.0817C4.12266 22.741 3.74264 23.8397 4.17723 24.8753C4.58108 25.8624 5.63799 26.5 6.86773 26.5H19.6964C19.2124 26.3685 18.7794 26.0677 18.4811 25.6426ZM24.8948 19.6738C24.6867 19.3335 24.2393 19.2244 23.8955 19.4306C22.1981 20.4481 21.067 21.3288 20.1159 22.8969C19.5773 22.4007 18.9919 22.1077 18.2089 21.8038C17.8349 21.6581 17.4127 21.8409 17.2659 22.2114C17.1191 22.5819 17.3035 23 17.6777 23.1451C18.5791 23.4953 19.0692 23.7507 19.666 24.5778C19.8034 24.768 20.0244 24.8793 20.258 24.8793C20.2789 24.8793 20.2998 24.8784 20.3211 24.8766C20.5774 24.8546 20.8028 24.7003 20.9143 24.4706C21.7455 22.7559 22.5834 21.902 24.6494 20.6633C24.993 20.4573 25.1027 20.0142 24.8948 19.6738Z" fill="#1E93FD"/>
            </svg>
            <div class="text-left ml-2">
              <p class="text-indicator-login">2000+</p>
              <p>{{ $t('login.activeAccounts') }}</p>
            </div>
          </div>
          <div class="flex items-center justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="31" viewBox="0 0 30 31" fill="none">
              <path d="M24.2972 6.61795C22.7738 6.45656 21.2744 6.11766 19.8296 5.60816C18.5041 5.13976 17.2326 4.53095 16.0366 3.79213C15.7247 3.6011 15.366 3.5 15.0002 3.5C14.6344 3.5 14.2757 3.6011 13.9637 3.79213C12.7677 4.53097 11.4962 5.13977 10.1707 5.60816C8.72595 6.11767 7.22656 6.45658 5.70308 6.61799C5.2141 6.66818 4.76114 6.89804 4.4319 7.26304C4.10267 7.62804 3.92058 8.10223 3.9209 8.59378V14.4974C3.92168 16.6757 4.50457 18.8143 5.60924 20.6918C6.71391 22.5692 8.30023 24.1174 10.204 25.1761L14.0336 27.3033C14.3287 27.4688 14.6613 27.5557 14.9995 27.5558C15.3378 27.5559 15.6704 27.4692 15.9656 27.3039L19.7963 25.1761C21.7001 24.1174 23.2864 22.5692 24.3911 20.6918C25.4958 18.8143 26.0786 16.6757 26.0794 14.4974V8.59378C26.0798 8.10222 25.8977 7.62803 25.5684 7.26302C25.2392 6.89801 24.7862 6.66815 24.2972 6.61795Z" fill="#0DB561"/>
              <path d="M14.2047 19.1766C13.8706 19.177 13.5482 19.0541 13.2991 18.8315L10.061 15.9535C9.92666 15.8348 9.81704 15.6907 9.73845 15.5295C9.65987 15.3683 9.61386 15.1932 9.60306 15.0141C9.59226 14.8351 9.61689 14.6558 9.67553 14.4863C9.73417 14.3168 9.82567 14.1606 9.9448 14.0265C10.0639 13.8925 10.2083 13.7833 10.3697 13.7051C10.5311 13.627 10.7064 13.5814 10.8854 13.5711C11.0644 13.5608 11.2437 13.5859 11.413 13.645C11.5824 13.7041 11.7383 13.7961 11.8721 13.9156L14.1181 15.9113L18.608 11.1224C18.8554 10.8591 19.1972 10.7047 19.5583 10.6931C19.9195 10.6814 20.2705 10.8135 20.5344 11.0602C20.7982 11.3077 20.9531 11.6497 20.9649 12.0112C20.9768 12.3727 20.8447 12.7242 20.5977 12.9884L15.2001 18.746C15.0727 18.8825 14.9186 18.9911 14.7473 19.0652C14.576 19.1393 14.3913 19.1772 14.2047 19.1766Z" fill="white"/>
            </svg>
            <div class="text-left ml-2 ">
              <p class="text-indicator-login">100%</p>
              <p>{{ $t('login.safetyGuarantee') }}</p>
            </div>
          </div>
          <div class="flex items-center justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="31" viewBox="0 0 30 31" fill="none">
              <path d="M18.7582 19.5535L21 16.1204L18.7582 15.5367C18.4646 14.6463 17.9652 13.8052 17.2604 13.0929C16.5654 12.3904 15.7431 11.8759 14.8424 11.5791L14.8131 11.6088L12.0818 11.5L10.8679 11.5791C9.9673 11.8759 9.145 12.3904 8.44992 13.0929L4.82083 16.7607C2.39306 19.2143 2.39306 23.2021 4.82083 25.6557C7.31712 28.1786 11.2623 28.05 13.6313 25.6557L17.2604 21.9973C17.9653 21.2849 18.4646 20.444 18.7582 19.5535ZM11.184 23.1822C10.0876 24.2904 8.3353 24.2607 7.2682 23.1822C6.19137 22.0939 6.19137 20.313 7.2682 19.2247L10.8679 15.596L12.8356 17.5648L14.8131 19.5238L11.184 23.1822Z" fill="#1E93FD"/>
              <path d="M22.5 24.2425L21.2575 23C20.9142 22.6566 20.9142 22.1009 21.2575 21.7575C21.6009 21.4142 22.1566 21.4141 22.5 21.7575L23.7425 23C24.0858 23.3434 24.0858 23.8991 23.7425 24.2425C23.399 24.5858 22.8434 24.5858 22.5 24.2425Z" fill="#D2C5C2"/>
              <path d="M7.50012 9.24253L6.25754 8C5.91415 7.65661 5.91415 7.10085 6.25754 6.75752C6.60092 6.41419 7.15667 6.41413 7.5 6.75752L8.74246 8C9.08585 8.34339 9.08585 8.89914 8.74246 9.24247C8.39908 9.5858 7.84344 9.58586 7.50012 9.24253Z" fill="#DFD7D5"/>
              <path d="M24.0261 18.0888C24.1351 17.6649 24.5534 17.4138 24.9606 17.5273L26.4347 17.9385C26.8419 18.0498 27.0821 18.4874 26.9742 18.9112C26.8651 19.3351 26.4468 19.5862 26.0396 19.4727L24.5656 19.0615C24.161 18.9488 23.9169 18.5152 24.0261 18.0888Z" fill="#D2C5C2"/>
              <path d="M3.02607 12.0888C3.13511 11.6649 3.5534 11.4138 3.9606 11.5273L5.43462 11.9385C5.8403 12.0493 6.08258 12.4869 5.9741 12.9112C5.86506 13.3351 5.44677 13.5862 5.03957 13.4727L3.56555 13.0615C3.16099 12.9488 2.91688 12.5151 3.02607 12.0888Z" fill="#DFD7D5"/>
              <path d="M17.4386 26.9345L17.0273 25.4607C16.9138 25.0535 17.1649 24.6353 17.5888 24.5263C18.0127 24.4173 18.4475 24.6569 18.5615 25.0657L18.9727 26.5395C19.0862 26.9467 18.8351 27.3649 18.4112 27.474C17.9849 27.5831 17.5513 27.339 17.4386 26.9345Z" fill="#D2C5C2"/>
              <path d="M11.4385 5.9345L11.0273 4.46048C10.9138 4.05322 11.1649 3.63497 11.5888 3.52594C12.0127 3.41797 12.4485 3.65659 12.5615 4.06542L12.9727 5.53945C13.0862 5.94671 12.8351 6.36495 12.4112 6.47399C11.9849 6.58303 11.5513 6.33896 11.4385 5.9345Z" fill="#DFD7D5"/>
              <path d="M25.1883 5.35316C22.7729 2.88728 18.8476 2.87729 16.4224 5.35316L12.8117 9.02985C12.1104 9.74573 11.6136 10.5909 11.3214 11.4858C10.8928 12.7983 10.8929 14.2102 11.3215 15.5227C11.6136 16.4176 12.1104 17.2628 12.8117 17.9787C13.4935 18.6748 14.3116 19.1917 15.2175 19.5L15.2466 19.4702C16.3181 18.3764 16.318 16.5867 15.2466 15.4929C14.1753 14.3992 14.1753 12.6094 15.2466 11.5157L18.8574 7.83896C19.3834 7.30198 20.0652 7.02362 20.8055 7.02362C20.8249 7.00379 20.9904 7.01368 21.0295 7.03356C21.5554 7.03351 22.2469 7.32185 22.7534 7.83896C23.8248 8.93269 23.8248 10.7224 22.7534 11.8162L19.1719 15.463C19.6005 16.7755 19.4286 18.1875 19 19.5C20 19.5 20.8959 18.6747 21.5777 17.9787L25.1884 14.302C27.6039 11.8361 27.6038 7.81904 25.1883 5.35316Z" fill="#3153C7"/>
            </svg>
            <div class="text-left ml-2">
              <p class="text-indicator-login">500+</p>
              <p>{{ $t('login.connections') }}</p>
            </div>
            
          </div>
        </div>

        <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div class="p-6 border rounded-lg flex flex-col justify-between h-full">
            <div class="flex justify-between items-center mb-1">
              <img src="../assets/note.png" alt="Stepward Logo" class="h-[5rem] w-auto" />
            </div>
            <p class="mb-4 text-details-login">
              {{ $t('login.review1') }}
            </p>
            <div class="flex flex-col gap-2 max-w-[300px]">
              <div class="flex text-white rounded-lg px-4 py-2" style="background: rgba(47, 44, 193, 0.54);">
                <div class="flex items-center gap-2">
                  <i class="fas fa-user"></i>
                  <p class="text-indicator-customer-login" v-html="$t('login.accountsNumber1')"></p>
                </div>
              </div>
              <div class="flex  bg-blue-500 text-white rounded-lg px-4 py-2" style="background: rgba(47, 44, 193, 0.54);">
                <div class="flex items-center gap-2">
                  <i class="fas fa-hourglass-half"></i>
                  <p class="text-indicator-customer-login">{{ $t('login.verticalizeAccounts') }}</p>
                </div>
              </div>
              <div class="flex bg-blue-500 text-white rounded-lg px-4 py-2" style="background: rgba(47, 44, 193, 0.54);">
                <div class="flex items-center gap-2">
                  <i class="fas fa-user-shield"></i>
                  <p class="text-indicator-customer-login">{{ $t('login.dedicatedAccount') }}</p>
                </div>
              </div>
            </div>


            <div class="flex items-center mt-8">
              <img
                src="../assets/recom.png"
                alt="Fred Duhral"
                class="h-5 rounded-full mr-4"
              />
   
            </div>
          </div>

          <div class="p-6 border rounded-lg flex flex-col justify-between h-full">
            <div class="flex justify-between items-center mb-1">
              <img src="../assets/note.png" alt="Stepward Logo" class="h-[5rem] w-auto" />
            </div>
            <p class="mb-4 text-details-login">
              {{ $t('login.review2') }}
            </p>
            <div class="flex flex-col gap-2 max-w-[300px]">
              <div class="flex text-white rounded-lg px-4 py-2" style="background: rgba(47, 44, 193, 0.54);">
                <div class="flex items-center gap-2">
                  <i class="fas fa-user"></i>
                  <p class="text-indicator-customer-login" v-html="$t('login.accountsNumber2')"></p>
                </div>
              </div>
              <div class="flex  bg-blue-500 text-white rounded-lg px-4 py-2" style="background: rgba(47, 44, 193, 0.54);">
                <div class="flex items-center gap-2">
                  <i class="fas fa-hourglass-half"></i>
                  <p class="text-indicator-customer-login">{{ $t('login.scaleVolume') }}</p>
                </div>
              </div>
              <div class="flex bg-blue-500 text-white rounded-lg px-4 py-2" style="background: rgba(47, 44, 193, 0.54);">
                <div class="flex items-center gap-2">
                  <i class="fas fa-user-shield"></i>
                  <p class="text-indicator-customer-login">{{ $t('login.secureOutbound') }}</p>
                </div>
              </div>
            </div>
            <div class="flex items-center mt-8">
              <img
                src="../assets/scalezia.png"
                alt="Fred Duhral"
                class="h-5 rounded-full mr-4"
              />

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

  <script>
  import franceFlag from '../assets/france-flag.png';
  import ukFlag from '../assets/uk-flag.png';
  import axios from '../plugins/axios';
  
  export default {
    name: 'LoginView',
    data() {
      return {
        username: '',
        password: '',
        confirmPassword: "",
        errorMessage: '',
        isMobile: false,
        currentLanguage: localStorage.getItem('langue') ||  navigator.language,
        showLanguageDropdown: false,
        flags: {
          fr: franceFlag,
          en: ukFlag,
        },
      };
    },
    computed: {
      fullName() {
        console.log(`${this.$route.params.Pr_nom} ${this.$route.params.Nom}`);
        return `${this.$route.params.Pr_nom} ${this.$route.params.Nom}`;
      },
    currentFlag() {
      const languageKey = this.currentLanguage.split('-')[0];
      return this.flags[languageKey] || this.flags.en;
    },
  },
    mounted() {
      this.updateIsMobile();
      window.addEventListener("resize", this.updateIsMobile);
    },
    methods: {
      toggleLanguageDropdown() {
      this.showLanguageDropdown = !this.showLanguageDropdown;
    },
    setLanguage(lang) {
      this.currentLanguage = lang;
      this.$i18n.locale = lang;
      this.showLanguageDropdown = false;
    },
      updateIsMobile() {
        this.isMobile = window.innerWidth <= 768; 
      },
      async handleChangePassword() {
        if (this.password !== this.confirmPassword) {
          alert("Passwords do not match.");
          return;
        }

        try {
          const response = await axios.post("/api/update-password", {
            newPassword: this.password,
          });
          
          console.log(response.data.message);
          this.$router.push({ name: "Login" });
        } catch (error) {
          console.error("Erreur lors de la réinitialisation :", error.response?.data || error.message);
          alert("An error occurred. Please try again.");
        }
      },

    },
    
  };
  </script>

  <style>
.login-element{
  margin-left: 50%;
  padding:2rem;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 10px 40px 0px rgba(30, 147, 253, 0.20);
  z-index: 89999;
}

.login-title{
  color: #000;
  font-family: Montserrat;
  font-size: clamp(16px, 2vw, 20px);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.login-text{
  color: #000;
  font-family: Montserrat;
  font-size: clamp(10px, 1.5vw, 12px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.href-login{
  text-align: center;
  font-family: Montserrat;
  font-size: clamp(10px, 1.5vw, 12px);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background: var(--gradient, linear-gradient(90deg, #1E93FD 0%, #3154C8 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.button-login{
  border-radius: 50px;
background: var(--gradient, linear-gradient(90deg, #1E93FD 0%, #3154C8 100%));
}

.custom-border{
  border-radius: 62px;
  border: 1px solid var(--gradient, #1E93FD);
}

.custom-checkbox {
    background-color: white; 
    transition: background-color 0.3s, border-color 0.3s; 
  }

  .custom-checkbox:checked {
    background: linear-gradient(90deg, #1E93FD 0%, #3154C8 100%); 
    border: transparent; 
  }

  .text-indicator-login{
    font-family: Montserrat;
    font-size: clamp(12px, 1.8vw, 16px);
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    background: var(--gradient, linear-gradient(90deg, #1E93FD 0%, #3154C8 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .text-details-login{
    color: #FFF;
    font-family: Montserrat;
    font-size: clamp(12px, 1.6vw, 14px);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .text-indicator-customer-login{
    color: #FFF;
    font-family: Montserrat;
    font-size: clamp(10px, 1.5vw, 12px);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }


  @media (max-width: 767px) {
    .login-element {
      padding: 1.5rem;
      margin-left: 0;
    }
    

    .login-title {
      font-size: 16px; 
    }

    .login-text {
      font-size: 12px;
    }

    .button-login {
      font-size: 13px;
      padding: 0.5rem 0.75rem;
    }

    .text-details-login {
      font-size: 12px;
      line-height: 1.4;
    }

    .text-indicator-login {
      font-size: 12px;
    }

    .custom-checkbox {
      width: 18px;
      height: 18px;
    }
  }

  .text-reset{
      color: #000;
      font-family: Montserrat;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
  }
</style>
 
  