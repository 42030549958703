<template>
  <div v-if="isLoading" class="overlay-loader fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
    <div class="loader loader--style8" title="Loading">
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="40px"
        height="50px"
        viewBox="0 0 24 30"
        style="enable-background:new 0 0 50 50;"
        xml:space="preserve"
      >
        <rect x="0" y="10" width="4" height="10" class="gradient-bar">
          <animate
            attributeName="opacity"
            attributeType="XML"
            values="0.2; 1; .2"
            begin="0s"
            dur="0.6s"
            repeatCount="indefinite"
          />
          <animate
            attributeName="height"
            attributeType="XML"
            values="10; 20; 10"
            begin="0s"
            dur="0.6s"
            repeatCount="indefinite"
          />
          <animate
            attributeName="y"
            attributeType="XML"
            values="10; 5; 10"
            begin="0s"
            dur="0.6s"
            repeatCount="indefinite"
          />
        </rect>
        <rect x="8" y="10" width="4" height="10" class="gradient-bar">
          <animate
            attributeName="opacity"
            attributeType="XML"
            values="0.2; 1; .2"
            begin="0.15s"
            dur="0.6s"
            repeatCount="indefinite"
          />
          <animate
            attributeName="height"
            attributeType="XML"
            values="10; 20; 10"
            begin="0.15s"
            dur="0.6s"
            repeatCount="indefinite"
          />
          <animate
            attributeName="y"
            attributeType="XML"
            values="10; 5; 10"
            begin="0.15s"
            dur="0.6s"
            repeatCount="indefinite"
          />
        </rect>
        <rect x="16" y="10" width="4" height="10" class="gradient-bar">
          <animate
            attributeName="opacity"
            attributeType="XML"
            values="0.2; 1; .2"
            begin="0.3s"
            dur="0.6s"
            repeatCount="indefinite"
          />
          <animate
            attributeName="height"
            attributeType="XML"
            values="10; 20; 10"
            begin="0.3s"
            dur="0.6s"
            repeatCount="indefinite"
          />
          <animate
            attributeName="y"
            attributeType="XML"
            values="10; 5; 10"
            begin="0.3s"
            dur="0.6s"
            repeatCount="indefinite"
          />
        </rect>
      </svg>
    </div>
    <p class="text-white text-lg text-center">
      Envoi des informations d'abonnement à Stripe...
    </p>
  </div>
   <div  :class="['relative', isMobile ? 'p-[1rem] pt-[3.5rem]' : 'p-[4rem]']"  >
    <div class="header flex justify-between items-center mb-6" >
        <button class="flex items-center text-blue-500 font-semibold">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
          </svg>
          <span class="ml-2" @click="goToPreviousStep">{{ $t('MainLayout.retour') }}</span>
        </button>
        <div :class="['absolute ', isMobile ? 'top-10 right-0 z-10 ' : 'top-8 right-8 z-10 ']" >
          <div 
                class="bg-white custom-border text-blue-700 px-4 py-1 rounded-full flex items-center shadow-md cursor-pointer"
                @click="toggleLanguageDropdown"
            >
                <img :src="currentFlag" alt="Langue" class="w-8 h-8 mr-2" />
                <i class="fas fa-chevron-down"></i>
            </div>

            <div 
                v-if="showLanguageDropdown" 
                class="absolute right-0 mt-2 bg-white shadow-lg rounded-lg p-2 w-32"
            >
                <button
                @click="setLanguage('fr')"
                class="flex items-center justify-start w-full px-2 py-1 hover:bg-blue-100 rounded-md transition"
                >
                <img src="../../assets/france-flag.png" alt="Français" class="w-6 h-6 mr-2" />
                Français
                </button>
                <button
                @click="setLanguage('en')"
                class="flex items-center justify-start w-full px-2 py-1 hover:bg-blue-100 rounded-md transition"
                >
                <img src="../../assets/uk-flag.png" alt="English" class="w-6 h-6 mr-2" />
                English
                </button>
            </div>
          </div>
      </div>

      <h1 class="text-summery mb-2">{{ $t('checkout.recapTitle') }}</h1>

      <div
        :class="[
          'grid grid-cols-1 gap-4 mt-6 space-y-5',
          isMobile ? '' : 'p-10'
        ]"
      >
      <div class="immediate-section mb-6">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-6 rounded-md">
          <div class="pt-4 rounded-md">
            <template v-if="Array.isArray(paymentData.paymentData)">
              <div 
                v-for="(item, index) in paymentData.paymentData" 
                :key="index" 
                class="mb-6 border-b border-gray-200 pb-3 last:border-0"
              >
                <div class="flex items-center justify-between mb-4">
                  <div class="flex items-center space-x-4">
                    <span class="quantity-display text-white px-4 py-1 rounded-full font-bold">
                      {{ item.quantity }}
                    </span>
                    <h2 class="text-profil">
                      {{ item.type === 'european' 
                          ? $t('checkout.europeanProfile') 
                          : $t('checkout.americanProfile')
                      }}
                    </h2>
                </div>
              </div>

        <ul class="space-y-2" v-if="item.type === 'european'">
          <li class="flex items-center">
              <svg class="responsive-svg flex-shrink-0" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 13 13" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.79232 1.08331C3.07402 1.08331 2.38515 1.36865 1.87724 1.87657C1.36933 2.38448 1.08398 3.07335 1.08398 3.79165V9.20831C1.08398 9.92661 1.36933 10.6155 1.87724 11.1234C2.38515 11.6313 3.07402 11.9166 3.79232 11.9166H9.20898C9.92728 11.9166 10.6162 11.6313 11.1241 11.1234C11.632 10.6155 11.9173 9.92661 11.9173 9.20831V3.79165C11.9173 3.07335 11.632 2.38448 11.1241 1.87657C10.6162 1.36865 9.92728 1.08331 9.20898 1.08331H3.79232ZM8.52107 5.78715C8.56972 5.73522 8.60767 5.67422 8.63275 5.60762C8.65783 5.54103 8.66955 5.47015 8.66724 5.39903C8.66492 5.32791 8.64862 5.25794 8.61927 5.19311C8.58991 5.12829 8.54808 5.06988 8.49615 5.02123C8.44422 4.97257 8.38322 4.93462 8.31663 4.90954C8.25003 4.88446 8.17915 4.87275 8.10803 4.87506C8.03691 4.87737 7.96694 4.89367 7.90212 4.92303C7.83729 4.95238 7.77889 4.99422 7.73023 5.04615L6.06028 6.82823L5.23532 6.09535C5.12723 6.0055 4.98845 5.96118 4.8483 5.97176C4.70814 5.98233 4.57758 6.04697 4.4842 6.15202C4.39082 6.25708 4.34193 6.39431 4.34786 6.53474C4.35379 6.67517 4.41408 6.8078 4.51598 6.9046L5.73473 7.98794C5.84019 8.08161 5.97803 8.13046 6.11893 8.12408C6.25984 8.11771 6.39271 8.05662 6.48928 7.95381L8.52107 5.78715Z" fill="url(#paint0_linear_385_3282)"/>
                <defs>
                  <linearGradient id="paint0_linear_385_3282" x1="1.08398" y1="6.49998" x2="11.9173" y2="6.49998" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#1E93FD"/>
                    <stop offset="1" stop-color="#3154C8"/>
                  </linearGradient>
                </defs>
              </svg>
              <span class="text-sm-payment ml-4">
                {{ $t('checkout.europeProfile') }} <span class="font-bold">{{ $t('checkout.europeProfile2') }}</span>
              </span>
            </li>
            <li class="flex items-center">
              <svg class="responsive-svg flex-shrink-0" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 13 13" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.79232 1.08331C3.07402 1.08331 2.38515 1.36865 1.87724 1.87657C1.36933 2.38448 1.08398 3.07335 1.08398 3.79165V9.20831C1.08398 9.92661 1.36933 10.6155 1.87724 11.1234C2.38515 11.6313 3.07402 11.9166 3.79232 11.9166H9.20898C9.92728 11.9166 10.6162 11.6313 11.1241 11.1234C11.632 10.6155 11.9173 9.92661 11.9173 9.20831V3.79165C11.9173 3.07335 11.632 2.38448 11.1241 1.87657C10.6162 1.36865 9.92728 1.08331 9.20898 1.08331H3.79232ZM8.52107 5.78715C8.56972 5.73522 8.60767 5.67422 8.63275 5.60762C8.65783 5.54103 8.66955 5.47015 8.66724 5.39903C8.66492 5.32791 8.64862 5.25794 8.61927 5.19311C8.58991 5.12829 8.54808 5.06988 8.49615 5.02123C8.44422 4.97257 8.38322 4.93462 8.31663 4.90954C8.25003 4.88446 8.17915 4.87275 8.10803 4.87506C8.03691 4.87737 7.96694 4.89367 7.90212 4.92303C7.83729 4.95238 7.77889 4.99422 7.73023 5.04615L6.06028 6.82823L5.23532 6.09535C5.12723 6.0055 4.98845 5.96118 4.8483 5.97176C4.70814 5.98233 4.57758 6.04697 4.4842 6.15202C4.39082 6.25708 4.34193 6.39431 4.34786 6.53474C4.35379 6.67517 4.41408 6.8078 4.51598 6.9046L5.73473 7.98794C5.84019 8.08161 5.97803 8.13046 6.11893 8.12408C6.25984 8.11771 6.39271 8.05662 6.48928 7.95381L8.52107 5.78715Z" fill="url(#paint0_linear_385_3282)"/>
                <defs>
                  <linearGradient id="paint0_linear_385_3282" x1="1.08398" y1="6.49998" x2="11.9173" y2="6.49998" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#1E93FD"/>
                    <stop offset="1" stop-color="#3154C8"/>
                  </linearGradient>
                </defs>
              </svg>
              <span class="text-sm-payment ml-4">
                {{ $t('checkout.europeIp') }} <span class="font-bold">{{ $t('checkout.europeIp2') }}</span>
              </span>
            </li>
            <li class="flex items-center">
              <svg class="responsive-svg flex-shrink-0" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 13 13" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.79232 1.08331C3.07402 1.08331 2.38515 1.36865 1.87724 1.87657C1.36933 2.38448 1.08398 3.07335 1.08398 3.79165V9.20831C1.08398 9.92661 1.36933 10.6155 1.87724 11.1234C2.38515 11.6313 3.07402 11.9166 3.79232 11.9166H9.20898C9.92728 11.9166 10.6162 11.6313 11.1241 11.1234C11.632 10.6155 11.9173 9.92661 11.9173 9.20831V3.79165C11.9173 3.07335 11.632 2.38448 11.1241 1.87657C10.6162 1.36865 9.92728 1.08331 9.20898 1.08331H3.79232ZM8.52107 5.78715C8.56972 5.73522 8.60767 5.67422 8.63275 5.60762C8.65783 5.54103 8.66955 5.47015 8.66724 5.39903C8.66492 5.32791 8.64862 5.25794 8.61927 5.19311C8.58991 5.12829 8.54808 5.06988 8.49615 5.02123C8.44422 4.97257 8.38322 4.93462 8.31663 4.90954C8.25003 4.88446 8.17915 4.87275 8.10803 4.87506C8.03691 4.87737 7.96694 4.89367 7.90212 4.92303C7.83729 4.95238 7.77889 4.99422 7.73023 5.04615L6.06028 6.82823L5.23532 6.09535C5.12723 6.0055 4.98845 5.96118 4.8483 5.97176C4.70814 5.98233 4.57758 6.04697 4.4842 6.15202C4.39082 6.25708 4.34193 6.39431 4.34786 6.53474C4.35379 6.67517 4.41408 6.8078 4.51598 6.9046L5.73473 7.98794C5.84019 8.08161 5.97803 8.13046 6.11893 8.12408C6.25984 8.11771 6.39271 8.05662 6.48928 7.95381L8.52107 5.78715Z" fill="url(#paint0_linear_385_3282)"/>
                <defs>
                  <linearGradient id="paint0_linear_385_3282" x1="1.08398" y1="6.49998" x2="11.9173" y2="6.49998" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#1E93FD"/>
                    <stop offset="1" stop-color="#3154C8"/>
                  </linearGradient>
                </defs>
              </svg>
              <span class="text-sm-payment ml-4">
                {{ $t('checkout.warmup') }}
              </span>
            </li>
            <li class="flex items-center">
              <svg class="responsive-svg flex-shrink-0" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 13 13" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M3.79232 1.08331C3.07402 1.08331 2.38515 1.36865 1.87724 1.87657C1.36933 2.38448 1.08398 3.07335 1.08398 3.79165V9.20831C1.08398 9.92661 1.36933 10.6155 1.87724 11.1234C2.38515 11.6313 3.07402 11.9166 3.79232 11.9166H9.20898C9.92728 11.9166 10.6162 11.6313 11.1241 11.1234C11.632 10.6155 11.9173 9.92661 11.9173 9.20831V3.79165C11.9173 3.07335 11.632 2.38448 11.1241 1.87657C10.6162 1.36865 9.92728 1.08331 9.20898 1.08331H3.79232ZM8.52107 5.78715C8.56972 5.73522 8.60767 5.67422 8.63275 5.60762C8.65783 5.54103 8.66955 5.47015 8.66724 5.39903C8.66492 5.32791 8.64862 5.25794 8.61927 5.19311C8.58991 5.12829 8.54808 5.06988 8.49615 5.02123C8.44422 4.97257 8.38322 4.93462 8.31663 4.90954C8.25003 4.88446 8.17915 4.87275 8.10803 4.87506C8.03691 4.87737 7.96694 4.89367 7.90212 4.92303C7.83729 4.95238 7.77889 4.99422 7.73023 5.04615L6.06028 6.82823L5.23532 6.09535C5.12723 6.0055 4.98845 5.96118 4.8483 5.97176C4.70814 5.98233 4.57758 6.04697 4.4842 6.15202C4.39082 6.25708 4.34193 6.39431 4.34786 6.53474C4.35379 6.67517 4.41408 6.8078 4.51598 6.9046L5.73473 7.98794C5.84019 8.08161 5.97803 8.13046 6.11893 8.12408C6.25984 8.11771 6.39271 8.05662 6.48928 7.95381L8.52107 5.78715Z" fill="url(#paint0_linear_385_3282)"/>
                  <defs>
                    <linearGradient id="paint0_linear_385_3282" x1="1.08398" y1="6.49998" x2="11.9173" y2="6.49998" gradientUnits="userSpaceOnUse">
                      <stop stop-color="#1E93FD"/>
                      <stop offset="1" stop-color="#3154C8"/>
                    </linearGradient>
                  </defs>
                </svg>
              <span class="text-sm-payment ml-4">
                {{ $t('checkout.replacement') }}
              </span>
            </li>
          </ul>

          <ul class="space-y-2" v-if="item.type === 'american'">
            <li class="flex items-center">

              <svg class="responsive-svg flex-shrink-0 " xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 13 13" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.79232 1.08331C3.07402 1.08331 2.38515 1.36865 1.87724 1.87657C1.36933 2.38448 1.08398 3.07335 1.08398 3.79165V9.20831C1.08398 9.92661 1.36933 10.6155 1.87724 11.1234C2.38515 11.6313 3.07402 11.9166 3.79232 11.9166H9.20898C9.92728 11.9166 10.6162 11.6313 11.1241 11.1234C11.632 10.6155 11.9173 9.92661 11.9173 9.20831V3.79165C11.9173 3.07335 11.632 2.38448 11.1241 1.87657C10.6162 1.36865 9.92728 1.08331 9.20898 1.08331H3.79232ZM8.52107 5.78715C8.56972 5.73522 8.60767 5.67422 8.63275 5.60762C8.65783 5.54103 8.66955 5.47015 8.66724 5.39903C8.66492 5.32791 8.64862 5.25794 8.61927 5.19311C8.58991 5.12829 8.54808 5.06988 8.49615 5.02123C8.44422 4.97257 8.38322 4.93462 8.31663 4.90954C8.25003 4.88446 8.17915 4.87275 8.10803 4.87506C8.03691 4.87737 7.96694 4.89367 7.90212 4.92303C7.83729 4.95238 7.77889 4.99422 7.73023 5.04615L6.06028 6.82823L5.23532 6.09535C5.12723 6.0055 4.98845 5.96118 4.8483 5.97176C4.70814 5.98233 4.57758 6.04697 4.4842 6.15202C4.39082 6.25708 4.34193 6.39431 4.34786 6.53474C4.35379 6.67517 4.41408 6.8078 4.51598 6.9046L5.73473 7.98794C5.84019 8.08161 5.97803 8.13046 6.11893 8.12408C6.25984 8.11771 6.39271 8.05662 6.48928 7.95381L8.52107 5.78715Z" fill="url(#paint0_linear_385_3282)"/>
                <defs>
                  <linearGradient id="paint0_linear_385_3282" x1="1.08398" y1="6.49998" x2="11.9173" y2="6.49998" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#1E93FD"/>
                    <stop offset="1" stop-color="#3154C8"/>
                  </linearGradient>
                </defs>
              </svg>
              <span class="text-sm-payment ml-4">
                {{ $t('checkout.usProfile') }} <span class="font-bold">{{ $t('checkout.usProfile2') }}</span> {{ $t('checkout.usProfile3') }}
              </span>
            </li>
            <li class="flex items-center">
              <svg class="responsive-svg flex-shrink-0" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 13 13" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.79232 1.08331C3.07402 1.08331 2.38515 1.36865 1.87724 1.87657C1.36933 2.38448 1.08398 3.07335 1.08398 3.79165V9.20831C1.08398 9.92661 1.36933 10.6155 1.87724 11.1234C2.38515 11.6313 3.07402 11.9166 3.79232 11.9166H9.20898C9.92728 11.9166 10.6162 11.6313 11.1241 11.1234C11.632 10.6155 11.9173 9.92661 11.9173 9.20831V3.79165C11.9173 3.07335 11.632 2.38448 11.1241 1.87657C10.6162 1.36865 9.92728 1.08331 9.20898 1.08331H3.79232ZM8.52107 5.78715C8.56972 5.73522 8.60767 5.67422 8.63275 5.60762C8.65783 5.54103 8.66955 5.47015 8.66724 5.39903C8.66492 5.32791 8.64862 5.25794 8.61927 5.19311C8.58991 5.12829 8.54808 5.06988 8.49615 5.02123C8.44422 4.97257 8.38322 4.93462 8.31663 4.90954C8.25003 4.88446 8.17915 4.87275 8.10803 4.87506C8.03691 4.87737 7.96694 4.89367 7.90212 4.92303C7.83729 4.95238 7.77889 4.99422 7.73023 5.04615L6.06028 6.82823L5.23532 6.09535C5.12723 6.0055 4.98845 5.96118 4.8483 5.97176C4.70814 5.98233 4.57758 6.04697 4.4842 6.15202C4.39082 6.25708 4.34193 6.39431 4.34786 6.53474C4.35379 6.67517 4.41408 6.8078 4.51598 6.9046L5.73473 7.98794C5.84019 8.08161 5.97803 8.13046 6.11893 8.12408C6.25984 8.11771 6.39271 8.05662 6.48928 7.95381L8.52107 5.78715Z" fill="url(#paint0_linear_385_3282)"/>
                <defs>
                  <linearGradient id="paint0_linear_385_3282" x1="1.08398" y1="6.49998" x2="11.9173" y2="6.49998" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#1E93FD"/>
                    <stop offset="1" stop-color="#3154C8"/>
                  </linearGradient>
                </defs>
              </svg>
              <span class="text-sm-payment ml-4">
                {{ $t('checkout.usIp') }} <span class="font-bold">{{ $t('checkout.usIp2') }}</span> {{ $t('checkout.usIp3') }}
              </span>
            </li>
            <li class="flex items-center">
              <svg class="responsive-svg flex-shrink-0" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 13 13" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.79232 1.08331C3.07402 1.08331 2.38515 1.36865 1.87724 1.87657C1.36933 2.38448 1.08398 3.07335 1.08398 3.79165V9.20831C1.08398 9.92661 1.36933 10.6155 1.87724 11.1234C2.38515 11.6313 3.07402 11.9166 3.79232 11.9166H9.20898C9.92728 11.9166 10.6162 11.6313 11.1241 11.1234C11.632 10.6155 11.9173 9.92661 11.9173 9.20831V3.79165C11.9173 3.07335 11.632 2.38448 11.1241 1.87657C10.6162 1.36865 9.92728 1.08331 9.20898 1.08331H3.79232ZM8.52107 5.78715C8.56972 5.73522 8.60767 5.67422 8.63275 5.60762C8.65783 5.54103 8.66955 5.47015 8.66724 5.39903C8.66492 5.32791 8.64862 5.25794 8.61927 5.19311C8.58991 5.12829 8.54808 5.06988 8.49615 5.02123C8.44422 4.97257 8.38322 4.93462 8.31663 4.90954C8.25003 4.88446 8.17915 4.87275 8.10803 4.87506C8.03691 4.87737 7.96694 4.89367 7.90212 4.92303C7.83729 4.95238 7.77889 4.99422 7.73023 5.04615L6.06028 6.82823L5.23532 6.09535C5.12723 6.0055 4.98845 5.96118 4.8483 5.97176C4.70814 5.98233 4.57758 6.04697 4.4842 6.15202C4.39082 6.25708 4.34193 6.39431 4.34786 6.53474C4.35379 6.67517 4.41408 6.8078 4.51598 6.9046L5.73473 7.98794C5.84019 8.08161 5.97803 8.13046 6.11893 8.12408C6.25984 8.11771 6.39271 8.05662 6.48928 7.95381L8.52107 5.78715Z" fill="url(#paint0_linear_385_3282)"/>
                <defs>
                  <linearGradient id="paint0_linear_385_3282" x1="1.08398" y1="6.49998" x2="11.9173" y2="6.49998" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#1E93FD"/>
                    <stop offset="1" stop-color="#3154C8"/>
                  </linearGradient>
                </defs>
              </svg>
              <span class="text-sm-payment ml-4">
                {{ $t('checkout.warmup') }}
              </span>
            </li>
            <li class="flex items-center">
              <svg class="responsive-svg flex-shrink-0" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 13 13" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M3.79232 1.08331C3.07402 1.08331 2.38515 1.36865 1.87724 1.87657C1.36933 2.38448 1.08398 3.07335 1.08398 3.79165V9.20831C1.08398 9.92661 1.36933 10.6155 1.87724 11.1234C2.38515 11.6313 3.07402 11.9166 3.79232 11.9166H9.20898C9.92728 11.9166 10.6162 11.6313 11.1241 11.1234C11.632 10.6155 11.9173 9.92661 11.9173 9.20831V3.79165C11.9173 3.07335 11.632 2.38448 11.1241 1.87657C10.6162 1.36865 9.92728 1.08331 9.20898 1.08331H3.79232ZM8.52107 5.78715C8.56972 5.73522 8.60767 5.67422 8.63275 5.60762C8.65783 5.54103 8.66955 5.47015 8.66724 5.39903C8.66492 5.32791 8.64862 5.25794 8.61927 5.19311C8.58991 5.12829 8.54808 5.06988 8.49615 5.02123C8.44422 4.97257 8.38322 4.93462 8.31663 4.90954C8.25003 4.88446 8.17915 4.87275 8.10803 4.87506C8.03691 4.87737 7.96694 4.89367 7.90212 4.92303C7.83729 4.95238 7.77889 4.99422 7.73023 5.04615L6.06028 6.82823L5.23532 6.09535C5.12723 6.0055 4.98845 5.96118 4.8483 5.97176C4.70814 5.98233 4.57758 6.04697 4.4842 6.15202C4.39082 6.25708 4.34193 6.39431 4.34786 6.53474C4.35379 6.67517 4.41408 6.8078 4.51598 6.9046L5.73473 7.98794C5.84019 8.08161 5.97803 8.13046 6.11893 8.12408C6.25984 8.11771 6.39271 8.05662 6.48928 7.95381L8.52107 5.78715Z" fill="url(#paint0_linear_385_3282)"/>
                  <defs>
                    <linearGradient id="paint0_linear_385_3282" x1="1.08398" y1="6.49998" x2="11.9173" y2="6.49998" gradientUnits="userSpaceOnUse">
                      <stop stop-color="#1E93FD"/>
                      <stop offset="1" stop-color="#3154C8"/>
                    </linearGradient>
                  </defs>
                </svg>
              <span class="text-sm-payment ml-4">
                {{ $t('checkout.replacement') }}
              </span>
            </li>
          </ul>
          </div>
          </template>

      </div>
  

        <div v-if="paymentData.paymentDataWithAddons.addons && paymentData.paymentDataWithAddons.addons.length > 0">
          <h3 class="text-profil mt-5">Add-ons</h3>
          <div class="bg-white p-4 pt-0 rounded-lg">
            <div
              v-for="addon in paymentData.paymentDataWithAddons.addons"
              :key="addon.id"
              class="flex items-center justify-between border-b border-gray-200 py-3 last:border-0"
            >
              <div class="flex items-center gap-4">
                <img :src="addontable[addon.name]" alt="Logo" class="h-7" />
              </div>

            </div>
          </div>
        </div>
      </div>
      <div>
          <div class="my-4 h-[2px] bg-gray-400 "></div>
          <div class="flex justify-between mt-4">
            <span class="title-recap">{{ $t('checkout.immediatePayment') }}</span>
            <span v-if="isSummaryLoading" class="loading-animation"></span>
            <span v-else class="total-sum">{{ summaryData.immediate.total }} {{ summaryData.immediate.currency }}</span>
          </div>
        </div>
        <div class="flex items-center justify-center mt-5" v-if="!showPaymentDetails">
          <button
            class="button-details-payment button-details-payment-arrow"
            @click="togglePaymentDetails"
          >
            {{ $t('checkout.seeDetailsButton') }}
            <span class="arrow-icon ml-2"><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 20 20" fill="none">
              <path d="M17.9563 6.61558C17.7219 6.38124 17.404 6.2496 17.0725 6.2496C16.7411 6.2496 16.4232 6.38124 16.1888 6.61558L10.0013 12.8031L3.81378 6.61558C3.57803 6.38788 3.26228 6.26189 2.93453 6.26474C2.60679 6.26758 2.29327 6.39904 2.06151 6.6308C1.82975 6.86256 1.69829 7.17608 1.69544 7.50383C1.6926 7.83157 1.81859 8.14733 2.04628 8.38308L9.11753 15.4543C9.35194 15.6887 9.66983 15.8203 10.0013 15.8203C10.3327 15.8203 10.6506 15.6887 10.885 15.4543L17.9563 8.38308C18.1906 8.14867 18.3223 7.83078 18.3223 7.49933C18.3223 7.16787 18.1906 6.84999 17.9563 6.61558Z" fill="url(#paint0_linear_776_2644)"/>
              <defs>
                <linearGradient id="paint0_linear_776_2644" x1="18.3223" y1="11.035" x2="1.69539" y2="11.035" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#1E93FD"/>
                  <stop offset="1" stop-color="#3153C7"/>
                </linearGradient>
              </defs>
          </svg></span>
          </button>
        </div>

        <div v-if="showPaymentDetails">
          <ul>
            <li
              v-for="item in summaryData.immediate.items"
              :key="item.description"
              class="flex items-center border-b border-gray-200 py-3 last:border-0"
            >
              <span class="flex-grow text-sm text-gray-600 basis-[70%]">
                {{ item.description }}
              </span>
              <div class="basis-[10%]"></div>
              <span class="text-sm font-medium basis-[20%] text-right">
                {{ item.amount }} {{ item.currency }}
              </span>
            </li>
          </ul>
        </div>
    </div>

 

      <div class="recurring-section ">
       
        <div class="flex justify-between mt-4">
          <span class="title-recap text-gray-700">{{ $t('checkout.nextBilling') }}</span>
          <span v-if="isSummaryLoading" class="loading-animation"></span>
          <span v-else class="total-sum font-bold">{{ summaryData.recurring.total }} {{ summaryData.recurring.currency }}</span>
        </div>
        <div class="flex items-center justify-center mt-5" v-if="!showRecurringDetails">
          <button
            class="button-details-payment-arrow"
            @click="toggleRecurringDetails"
          >
          {{ $t('checkout.seeDetailsButton') }}
          <span class="arrow-icon ml-2"><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 20 20" fill="none">
            <path d="M17.9563 6.61558C17.7219 6.38124 17.404 6.2496 17.0725 6.2496C16.7411 6.2496 16.4232 6.38124 16.1888 6.61558L10.0013 12.8031L3.81378 6.61558C3.57803 6.38788 3.26228 6.26189 2.93453 6.26474C2.60679 6.26758 2.29327 6.39904 2.06151 6.6308C1.82975 6.86256 1.69829 7.17608 1.69544 7.50383C1.6926 7.83157 1.81859 8.14733 2.04628 8.38308L9.11753 15.4543C9.35194 15.6887 9.66983 15.8203 10.0013 15.8203C10.3327 15.8203 10.6506 15.6887 10.885 15.4543L17.9563 8.38308C18.1906 8.14867 18.3223 7.83078 18.3223 7.49933C18.3223 7.16787 18.1906 6.84999 17.9563 6.61558Z" fill="url(#paint0_linear_776_2644)"/>
            <defs>
              <linearGradient id="paint0_linear_776_2644" x1="18.3223" y1="11.035" x2="1.69539" y2="11.035" gradientUnits="userSpaceOnUse">
                <stop stop-color="#1E93FD"/>
                <stop offset="1" stop-color="#3153C7"/>
              </linearGradient>
            </defs>
          </svg></span>
        </button>
        </div>

        <div v-if="showRecurringDetails">
          <ul class="recurring-items bg-white p-4 rounded-lg">
            <li
              v-for="item in summaryData.recurring.items"
              :key="item.description"
              class="flex items-center border-b border-gray-200 py-3 last:border-0"
            >
              <span 
                v-if="!isSummaryLoading" 
                class="flex-grow text-sm text-gray-600 basis-[70%]"
              >
                {{ item.description }}
              </span>
              <div class="basis-[10%]"></div>
              <span 
                v-if="!isSummaryLoading" 
                class="text-sm font-medium basis-[20%] text-right"
              >
                {{ item.amount }} {{ item.currency }}
              </span>
              <span v-if="isSummaryLoading" class="loading-animation flex-grow"></span>
            </li>
          </ul>
        </div>
      </div>
      
        <div class="payment-footer">
          <span class="payment-text">Payment managed by <span class="stripe-logo">stripe</span></span>
        </div>
  </div>

    

      <div class="footer mt-8 text-center mt-5">
        <button
        class="button-continu text-[20px] text-white px-8 py-4 rounded-full font-semibold inline-flex items-center justify-center"
      @click="proceedToPayment"
      >
      {{ $t('checkout.confirmPaymentButton') }}
    </button>
    </div>
  </div>
</template>

 <script>
 import axios from '../../plugins/axios';
import franceFlag from '../../assets/france-flag.png';
import ukFlag from '../../assets/uk-flag.png';
import heyreach from '../../assets/HeyReach-Logo.png'
import mirrorchat from '../../assets/mirrorchat-Logo.png'
import waalaxy from '../../assets/waalaxy-Logo.png'
import custom from '../../assets/custom-Logo.png'
import salesNav from '../../assets/SalesNav-logo.png'


export default {
  name: 'CheckoutViewV3',
  data() {
    return {
      addons: [],
      selectedAddons: [],
      addontable: {
        "HeyReach Business": heyreach,
        "MirrorChat": mirrorchat,
        "Custom Branding": custom,
        "Waalaxy Business": waalaxy,
        "Sales Navigator": salesNav,
      },
      paymentData: null,
      isLoading: false,
      isSummaryLoading: true, 
      showPaymentDetails: false,
      flags: {
        fr: franceFlag,
        en: ukFlag,
      },
      currentLanguage: localStorage.getItem('langue'),
      showLanguageDropdown: false,
      isMobile: false,
      showRecurringDetails: false,
      summaryData: {
        immediate: {
          subtotal: '0.00',
          vat: '0.00',
          total: '0.00',
          currency: 'eur',
          items: [],
        },
        recurring: {
          subtotal: '0.00',
          vat: '0.00',
          total: '0.00',
          currency: 'eur',
          items: [],
        },
      },
    };
  },
  computed: {
    currentFlag() {
      const languageKey = this.currentLanguage.split('-')[0];
      return this.flags[languageKey] || this.flags.en;
    },
  },
   methods: {
    toggleRecurringDetails() {
      this.showRecurringDetails = !this.showRecurringDetails;
    },
    getAddonLogo(key) {
      return this.addontable[key] || null; 
    },
    updateIsMobile() {
        this.isMobile = window.innerWidth <= 768; 
      },
    goToPreviousStep() {
        const currentStep = 2;

        localStorage.setItem('currentStep', currentStep);

        const stepEvent = new StorageEvent('storage', {
          key: 'currentStep',
          newValue: currentStep.toString(),
        });
        window.dispatchEvent(stepEvent);
        localStorage.removeItem('paymentDataFull');
        const storageEvent = new StorageEvent('storage', {
          key: "paymentDataFull",       
          oldValue: localStorage.getItem("paymentDataFull"), 
          newValue: null,         
          storageArea: localStorage,
        });

        window.dispatchEvent(storageEvent);


       this.$router.push('/checkout/v2');
     },
     proceedToPayment() {
      if (!this.paymentData || Object.keys(this.paymentData).length === 0) {
        console.error('Aucune donnée de paiement disponible.');
        return;
      }

      this.isLoading = true;

      let totalQuantity = 0;
      if (Array.isArray(this.paymentData.paymentData)) {
        totalQuantity = this.paymentData.paymentData.reduce(
          (acc, item) => acc + item.quantity, 
          0
        );
      } else {
        totalQuantity = this.paymentData.paymentData.quantity;
      }

      this.paymentData.totalQuantity = totalQuantity;

      axios
        .post('/api/summary/valided', this.paymentData.apiData)
        .then(response => {
          console.log(response.data);
          const { subscriptions } = response.data;

          localStorage.setItem('paymentDataFulltempo', null);
          localStorage.setItem('subscriptions', JSON.stringify(subscriptions));
          localStorage.setItem('numberAccount', totalQuantity);

          this.$router.push({ name: 'SuccessView' });
        })
        .catch(error => {
          console.error(
            'Erreur lors de la mise à jour de l\'abonnement :',
            error.response?.data || error.message
          );
          alert('Une erreur est survenue lors de la mise à jour de l\'abonnement.');

          this.$router.push({ name: 'CancelView' });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

      togglePaymentDetails() {
      this.showPaymentDetails = !this.showPaymentDetails;
    },
    toggleLanguageDropdown() {
      this.showLanguageDropdown = !this.showLanguageDropdown;
    },
    setLanguage(lang) {
      this.currentLanguage = lang;
      this.$i18n.locale = lang;
      this.showLanguageDropdown = false;
      localStorage.setItem('langue', lang)
      localStorage.setItem('lang', lang);
    },
  },
  created() {
    const storedPaymentData = localStorage.getItem('paymentDataFull');
    if (storedPaymentData) {
      const data = JSON.parse(storedPaymentData);
      console.log('Données initiales de paiement :', data);
      this.paymentData = data;
    } else {
      console.error('Aucune donnée de paiement trouvée.');
      const currentStep = 1;

      localStorage.setItem('currentStep', currentStep);

      const stepEvent = new StorageEvent('storage', {
        key: 'currentStep',
        newValue: currentStep.toString(),
      });

      window.dispatchEvent(stepEvent);
      this.$router.push('/checkout/v1');
      return;
    }
    console.log('Données récupérées pour le paiement :', this.paymentData);
  },
  mounted() {
    this.updateIsMobile();
    window.addEventListener("resize", this.updateIsMobile);
    const storedPaymentData = localStorage.getItem('paymentDataFull');
    if (storedPaymentData) {
      const data = JSON.parse(storedPaymentData);
      console.log('Données initiales de paiement :', data);
      this.paymentData = data;
    }
    console.log('Données récupérées pour le paiement :', this.paymentData);

    if (!this.paymentData) {
      console.error('Aucune donnée de paiement trouvée.');
      const currentStep = 1;

      localStorage.setItem('currentStep', currentStep);

      const stepEvent = new StorageEvent('storage', {
        key: 'currentStep',
        newValue: currentStep.toString(),
      });

      window.dispatchEvent(stepEvent);
      this.$router.push('/checkout/v1');
      return;
    }

    axios
      .post('/api/summary', {
        apiData: this.paymentData.apiData,
      })
      .then((response) => {
        console.log('Résumé Stripe reçu :', response.data);

        const translateCurrency = (currency) => {
          if (currency === 'eur') return '€';
          if (currency === 'usd') return '$';
          return currency; 
        };

        const updateItemCurrencies = (items) => {
          return items.map((item) => ({
            ...item,
            currency: translateCurrency(item.currency),
          }));
        };

        const updatedImmediate = {
          ...response.data.data.immediate,
          currency: translateCurrency(response.data.data.immediate.currency),
          items: updateItemCurrencies(response.data.data.immediate.items),
        };

        const updatedRecurring = {
          ...response.data.data.recurring,
          currency: translateCurrency(response.data.data.recurring.currency),
          items: updateItemCurrencies(response.data.data.recurring.items),
        };

        this.summaryData = {
          immediate: updatedImmediate,
          recurring: updatedRecurring,
          heyReach: response.data.heyReach, 
        };

        this.isSummaryLoading = false;
      })
      .catch((error) => {
        console.error('Erreur lors de la récupération du résumé Stripe :', error.message);
        this.isSummaryLoading = false;
      });


  },
  
};

 </script>
 

  <style scoped>

  .button-continu {
      width: 50%;
      background: linear-gradient(90deg, #1E93FD 0%, #3154C8 100%);
      box-shadow: 0 4px 10px rgba(30, 147, 253, 0.3);
      transition: background 1s ease, box-shadow 1s ease; 
    }

    .button-continu:hover {
      background: linear-gradient(90deg, #3154C8 0%, #3154C8 100%);
      box-shadow: 0 4px 10px rgba(30, 147, 253, 0.3);
    }
    .text-sm-payment{
      color: #000;
      font-family: Montserrat;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 200%; 
    }
    .fade-enter-active,
      .fade-leave-active {
        transition: opacity 0.5s;
      }
      .fade-enter,
      .fade-leave-to {
        opacity: 0;
      }
      .step-circle {
        transition: background-color 0.3s, border-color 0.3s;
      }
      .text-lg {
        transition: color 0.3s;
      }
      .payment-summary{
        background: #0E51AC;
      }

      .monthy-price{
        border-radius: 4px;
        background: #083879;
      }

      .quantity-display{
        border-radius: 5px;
        background: #1E93FD;
      }


      .payment-footer {
        background: #EFEFEF;
        padding: 12px 24px; 
        border-radius: 8px; 
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); 
      }

      .payment-text {
        color: #707070;
        font-family: Montserrat;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .stripe-logo {
        font-weight: bold; 
        font-size: 17px;
        font-weight: 700;
      }


        .immediate-section,
        .recurring-section {
        border-radius: 10px;
        padding: 2rem;
        background: #fff;
        box-shadow: 0px 20px 40px rgba(30, 147, 253, 0.2);
        }

    

        .immediate-items li,
        .recurring-items li {
        padding: 12px 0;
        font-family: Montserrat, sans-serif;
        }

        .immediate-items li:last-child,
        .recurring-items li:last-child {
        border-bottom: none;
        }

        .custom-border{
          border-radius: 62px;
          border: 1px solid var(--gradient, #1E93FD);
        }

        .title-payment{
          color: #000;
          font-family: Montserrat;
          font-size: 25px;
          font-style: normal;
          font-weight: 700;
          line-height: 140%; 
          align-self: stretch;
        }

        .title-recap {
          color: #000;
          font-family: Montserrat;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: 140%; 
        }


        .total-sum{
          text-align: right;
          font-family: Montserrat;
          font-size: 2rem;
          font-style: normal;
          width: 50%;
          font-weight: 700;
          line-height: 140%; 
          background: var(--gradient, linear-gradient(90deg, #1E93FD 0%, #3154C8 100%));
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        .fade-enter-active,
        .fade-leave-active {
          transition: opacity 0.7s ease-out;
        }

        .fade-enter-from,
        .fade-leave-to {
          opacity: 0;
        }

        .text-summery{
          color: #000;
          font-family: Montserrat;
          font-size: 32px;
          font-style: normal;
          font-weight: 700;
          line-height: 140%; 
        }

        .payment-month{
          padding: 4px 10px;
          border-radius: 4px;
          background: #083879;
          color: #FFF;
          font-family: Montserrat;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%;
        }

        .payment-month span{
          font-weight: 700;
        }

        .text-profil{
          color: #000;
          font-family: Montserrat;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 140%; 
        }

        .button-details-payment{
          display: flex;
          padding: 14px 22px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 50px;
          background: linear-gradient(90deg, #1E93FD 0%, #3153C7 100%);
          color: #FFF;
          font-family: Montserrat;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          text-transform: uppercase;
          transition: all 0.3s ease-in-out;
        }

        .button-details-payment:hover {
          opacity: 0.9;
          transform: scale(1.05); 
        }

        @media (max-width: 768px) {
          .immediate-section,
          .recurring-section {
            padding: 1rem;
          }

          .title-payment,
          .title-recap {
            font-size: 1rem;
          }

          .total-sum {
            font-size: 1rem;
          }

          .text-summery {
            font-size: 1.5rem;
          }

          .payment-month {
            font-size: 0.75rem;
          }

          .button-details-payment {
            font-size: 0.75rem;
          }

          .button-continu{
            font-size: 0.7rem;
            width: 90%;
          }
        }


        .loading-animation {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  height: 20px;
}

.responsive-svg{
  width: 2vw;
}

.loading-animation {
  width: 20px;
  height: 20px;
  background-color: #1E93FD;
  border-radius: 2px;
  animation: loading-spin 1.2s infinite ease-in-out;
}

.loading-animation:nth-child(1) {
  animation-delay: 0s;
}
.loading-animation:nth-child(2) {
  animation-delay: 0.2s;
}
.loading-animation:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes loading-spin {
  0% {
    transform: scale(1) rotate(0deg);
    opacity: 1;
  }
  50% {
    transform: scale(1.5) rotate(180deg);
    opacity: 0.5;
  }
  100% {
    transform: scale(1) rotate(360deg);
    opacity: 1;
  }
}

.overlay-loader {
  position: fixed; 
  top: 0;
  left: 0;
  width: 120%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; 
}

.loader {
  color: #1E93FD;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gradient-bar {
  fill: var(--gradient, #3154C8);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.button-details-payment-arrow {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  background: var(--gradient, linear-gradient(90deg, #1E93FD 0%, #3153C7 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  border: none;
  padding: 0;
  display: inline-flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.arrow-icon {
  font-size: 12px;
  font-weight: bold;
  color: transparent;
  background: var(--gradient, linear-gradient(90deg, #1E93FD 0%, #3153C7 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

        
  </style>
  