<template>
  <div class="checkout-container bg-white min-h-screen flex flex-col md:flex-row">
    <div
    :class="[
        'flex flex-col text-white sticky top-0 z-20 transition-all duration-300',
        (isMenuOpen || windowWidth < 1024) ? 'h-screen' : 'h-auto',
        'sm:flex md:hidden'
      ]"      
      style="background: linear-gradient(183deg, #1E93FD -15.85%, #3153C7 95.22%);"
    >
      <div class="flex items-center justify-between p-4">
        <img
          src="../assets/main-logo-white.png"
          alt="Mirror Profiles Logo"
          class="w-24 cursor-pointer"
          @click="redirectToAccounts"
        />
        <div class="relative">
          <button @click="isMenuOpen = !isMenuOpen" class="text-white">
            <i class="fas fa-bars fa-2x"></i>
          </button>
          <div 
            v-if="paymentData && !paymentDataFull" 
            class="absolute top-0 right-0 bg-red-500 rounded-full h-3 w-3 transform translate-x-[50%] -translate-y-[50%]"
          ></div>
        </div>
      </div>

      <div class="steps flex items-stretch justify-around mt-4 sticky top-0 z-10 p-4"
      >
        <div class="step flex flex-col items-center flex-1 text-center">
          <div
            class="step-circle w-8 h-8 border-2 border-white text-white flex items-center justify-center rounded-full font-semibold"
            :class="currentStep >= 1 ? (currentStep > 1 ? 'bg-white border-transparent' : 'bg-blue-500 border-white') : 'next-step'"
            >
          <template v-if="currentStep > 1">
            <i class="fas fa-check text-blue-500"></i>
          </template>
          <template v-else>
            1 
          </template>
          </div>
          <p class="text-xs font-medium mt-2 text-center">
            {{ $t('checkout.step1') }}
          </p>
        </div>
        <div
        class="absolute top-[32%] left-[31%] right-[0] transform -translate-x-1/3 bg-white h-[2px] w-[20%]"
        :class="currentStep >= 2 ? 'bg-blue-500' : 'next-step'"
        ></div>
        <div class="step flex flex-col items-center flex-1 text-center">
          <div
            class="step-circle w-8 h-8 border-2 border-white text-white flex items-center justify-center rounded-full font-semibold"
            :class="currentStep >= 2 ? (currentStep > 2 ? 'bg-white border-transparent' : 'bg-blue-500 border-white') : 'next-step'"
            >
          <template v-if="currentStep > 2">
            <i class="fas fa-check text-blue-500"></i>
          </template>
          <template v-else>
            2
          </template>
          </div>
          <p class="text-xs font-medium mt-2 text-center">
            {{ $t('checkout.step2') }}
          </p>
        </div>
        <div
        class="absolute top-[32%] left-[62%] right-[0] transform -translate-x-1/3 bg-white h-[2px] w-[20vw]"
        :class="currentStep >= 3 ? 'bg-blue-500' : 'next-step'"
        ></div>
        <div class="step flex flex-col items-center flex-1 text-center">
          <div
            class="step-circle w-8 h-8 border-2 border-white text-white flex items-center justify-center rounded-full"
            :class="currentStep >= 3 ? (currentStep > 3 ? 'bg-white border-transparent' : 'bg-blue-500 border-white') : 'next-step'"
            >
          <template v-if="currentStep > 3">
            <i class="fas fa-check text-blue-500"></i>
          </template>
          <template v-else>
            3
          </template>
          </div>
          <p class="text-xs font-medium mt-2 text-center">
            {{ $t('checkout.step3Out') }}
          </p>
        </div>
      </div>

      <transition name="slide-down">
        <div
          v-if="isMenuOpen"
          class="absolute top-0 left-0 w-full h-full bg-blue-700 text-white flex flex-col p-6 z-20 overflow-auto"
        >
          <button @click="isMenuOpen = false" class="self-end mb-4">
            <i class="fas fa-times fa-2x"></i>
          </button>
          <h1 class="title-checkout mt-10 max-w-lg">
            {{ $t('checkout.title') }}
          </h1>
          <transition name="fade" mode="out-in">
            <div v-if="paymentData && !paymentDataFull" class="payment-summary p-6 rounded-lg text-white mt-10 bg-blue-600">
              <div class="flex items-center justify-between mb-4">
                <div class="flex items-center space-x-4">
                  <span class="quantity-display text-white px-4 py-1 rounded-full font-bold">
                    {{ paymentData.quantity }}
                  </span>
                  <h2 class="text-m font-bold">
                    {{ paymentData.type === 'european' ? $t('checkout.europeanProfile') : $t('checkout.americanProfile')}}
                  </h2>
                </div>
                <div class="text-right flex flex-col items-end">
                  <!-- <p class="text-sm">
                    {{ paymentData.unitPrice.toFixed(2) }}€/mois/compte
                  </p> -->
                  <p class="monthy-price text-sm font-semibold bg-gray-900 text-white px-3 py-1 rounded-lg mt-1">
                    {{ $t('checkout.pricePerMonth1') }} {{ paymentData.totalPrice.toFixed(2) }}{{ getCurrencySymbol(paymentData.currency) }}{{ $t('checkout.pricePerMonth2') }} 
                  </p>
                </div>
              </div>
              <div class="p-4 rounded-md">
                <ul class="space-y-2" v-if="paymentData.type === 'european'">
                  <li class="flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" class="text-white mr-2">
                      <rect width="16" height="16" rx="2" fill="white" />
                      <path d="M6 10.5L3.5 8L2.5 9L6 12.5L13.5 5L12.5 4L6 10.5Z" fill="#1E93FD" />
                    </svg>
                    <span class="text-sm text-white">
                      {{ $t('checkout.europeProfile') }} <span class="font-bold">{{ $t('checkout.europeProfile2') }}</span>
                    </span>
                  </li>
                  <li class="flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" class="text-white mr-2">
                      <rect width="16" height="16" rx="2" fill="white" />
                      <path d="M6 10.5L3.5 8L2.5 9L6 12.5L13.5 5L12.5 4L6 10.5Z" fill="#1E93FD" />
                    </svg>
                    <span class="text-sm text-white">
                      {{ $t('checkout.europeIp') }} <span class="font-bold">{{ $t('checkout.europeIp2') }}</span>
                    </span>
                  </li>
                  <li class="flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" class="text-white mr-2">
                      <rect width="16" height="16" rx="2" fill="white" />
                      <path d="M6 10.5L3.5 8L2.5 9L6 12.5L13.5 5L12.5 4L6 10.5Z" fill="#1E93FD" />
                    </svg>
                    <span class="text-sm text-white">{{ $t('checkout.warmup') }}</span>
                  </li>
                  <li class="flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" class="text-white mr-2">
                      <rect width="16" height="16" rx="2" fill="white" />
                      <path d="M6 10.5L3.5 8L2.5 9L6 12.5L13.5 5L12.5 4L6 10.5Z" fill="#1E93FD" />
                    </svg>
                    <span class="text-sm text-white">{{ $t('checkout.replacement') }}</span>
                  </li>
                </ul>

                <ul class="space-y-2" v-else>
                  <li class="flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" class="text-white mr-2">
                      <rect width="16" height="16" rx="2" fill="white" />
                      <path d="M6 10.5L3.5 8L2.5 9L6 12.5L13.5 5L12.5 4L6 10.5Z" fill="#1E93FD" />
                    </svg>
                    <span class="text-sm text-white">
                      {{ $t('checkout.usProfile') }} <span class="font-bold">{{ $t('checkout.usProfile2') }}</span> <span class="font-bold">{{ $t('checkout.usProfile3') }}</span>
                    </span>
                  </li>
                  <li class="flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" class="text-white mr-2">
                      <rect width="16" height="16" rx="2" fill="white" />
                      <path d="M6 10.5L3.5 8L2.5 9L6 12.5L13.5 5L12.5 4L6 10.5Z" fill="#1E93FD" />
                    </svg>
                    <span class="text-sm text-white">
                      {{ $t('checkout.usIp') }} <span class="font-bold">{{ $t('checkout.usIp2') }}</span> <span class="font-bold">{{ $t('checkout.usIp3') }}</span>
                    </span>
                  </li>
                  <li class="flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" class="text-white mr-2">
                      <rect width="16" height="16" rx="2" fill="white" />
                      <path d="M6 10.5L3.5 8L2.5 9L6 12.5L13.5 5L12.5 4L6 10.5Z" fill="#1E93FD" />
                    </svg>
                    <span class="text-sm text-white">{{ $t('checkout.warmup') }}</span>
                  </li>
                  <li class="flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" class="text-white mr-2">
                      <rect width="16" height="16" rx="2" fill="white" />
                      <path d="M6 10.5L3.5 8L2.5 9L6 12.5L13.5 5L12.5 4L6 10.5Z" fill="#1E93FD" />
                    </svg>
                    <span class="text-sm text-white">{{ $t('checkout.replacement') }}</span>
                  </li>
                </ul>
              </div>

            </div>

            <div v-else class="text-center mx-auto px-4 max-w-lg mt-12">
              <!-- <p class="font-bold text-lg mb-4" style="color: #FFF;">
                {{ $t('checkout.demoQuestion') }}
              </p>
              <button
                class="bg-white text-blue-500 px-6 py-3 rounded-full font-semibold hover:bg-gray-200 transition"
              >
                {{ $t('checkout.scheduleDemo') }}
              </button> -->
            </div>
          </transition>
        </div>
      </transition>
    </div>

    <!-- Desktop Version -->
    <div
      class="hidden md:flex w-1/3 flex-col p-responsive text-white sticky top-0"
      style="background: linear-gradient(183deg, #1E93FD -15.85%, #3153C7 95.22%);  height: 100vh;"
    >
      <div class="flex flex-col items-start mb-10 px-6">
        <img
          src="../assets/main-logo-white.png"
          alt="Mirror Profiles Logo"
          class="w-32 mb-4 cursor-pointer"
          @click="redirectToAccounts"
        />
        <h1 class="title-checkout mt-10 max-w-lg">
          {{ $t('checkout.title') }}
        </h1>
      </div>

      <div class="steps relative flex flex-col space-y-11 mt-2 mb-10 max-w-lg px-6">
        <div class="step flex items-center space-x-4 relative">
          <div
            class="step-circle bg-blue-500 w-8 h-8 border-2 border-white text-white flex items-center justify-center rounded-full font-semibold"
            :class="currentStep >= 1 ? (currentStep > 1 ? 'bg-white border-transparent' : 'bg-blue-500 border-white') : 'next-step'"
            >
          <template v-if="currentStep > 1">
            <i class="fas fa-check text-blue-500"></i>
          </template>
          <template v-else>
            1 
          </template>
          </div>
          <p class="text-lg-desktop font-medium text-white" style="line-height: 140%;">
            {{ $t('checkout.step1') }}
          </p>
        </div>
        <div
          class="absolute top-1 bottom-[8.7rem] left-[3rem] transform -translate-x-1/3 bg-white w-[2px]"
          :class="currentStep >= 2 ? 'bg-blue-500' : 'next-step'"
        ></div>
        <div class="step flex items-center space-x-4 relative">
          <div
            class="step-circle w-8 h-8 border-2 border-white text-white flex items-center justify-center rounded-full font-semibold"
            :class="currentStep >= 2 ? (currentStep > 2 ? 'bg-white border-transparent' : 'bg-blue-500 border-white') : 'next-step'"
            >
          <template v-if="currentStep > 2">
            <i class="fas fa-check text-blue-500"></i>
          </template>
          <template v-else>
            2
          </template>
          </div>
          <p class="text-lg-desktop font-medium" :class="currentStep >= 2 ? 'text-white' : 'next-step'" style="line-height: 140%;">
            {{ $t('checkout.step2') }}
          </p>
        </div>
        <div
          class="absolute top-[6rem] bottom-[3rem] left-[3rem] transform -translate-x-1/3 bg-white w-[2px]"
          :class="currentStep === 3 ? 'bg-blue-500' : 'next-step'"
        ></div>
        <div class="step flex items-center space-x-4 relative">
          <div
            class="step-circle w-8 h-8 border-2 border-white text-white flex items-center justify-center rounded-full"
            :class="currentStep === 3 ? (currentStep > 3 ? 'bg-white border-transparent' : 'bg-blue-500 border-white') : 'next-step'"
            >
          <template v-if="currentStep > 3">
            <i class="fas fa-check text-blue-500"></i>
          </template>
          <template v-else>
            3
          </template>
          </div>
          <p class="text-lg-desktop font-medium" :class="currentStep === 3 ? 'text-white' : 'next-step'" style="line-height: 140%;">
            {{ $t('checkout.step3Out') }}
          </p>
        </div>
      </div>

      <transition name="fade" mode="out-in">
        <div 
          v-if="paymentData && Array.isArray(paymentData) && paymentData.length" 
          class="payment-summary p-6 rounded-lg text-white bg-blue-600"
        >
          <div 
            v-for="(item, index) in paymentData" 
            :key="index" 
            class="mb-8 border-b border-blue-400 last:border-b-0 pb-4"
          >
            <div class="flex items-center justify-between mb-4">
              <div class="flex items-center space-x-4">
                <span class="quantity-display text-white px-4 py-1 rounded-full font-bold">
                  {{ item.quantity }}
                </span>
                <h2 class="text-m font-bold">
                  {{ item.type === 'european' 
                      ? $t('checkout.europeanProfile') 
                      : $t('checkout.americanProfile') 
                  }}
                </h2>
              </div>
              <div class="text-right flex items-center justify-between">
                <p class="monthy-price text-sm font-semibold bg-gray-900 text-white px-3 py-1 rounded-lg">
                  {{ $t('checkout.pricePerMonth1') }}
                  {{ item.totalPrice.toFixed(2) }}{{ getCurrencySymbol(item.currency) }}
                  {{ $t('checkout.pricePerMonth2') }}
                </p>
              </div>
            </div>
          </div>
          <div 
              v-if="paymentDataFull && paymentDataFull.paymentDataWithAddons.addons 
                  && paymentDataFull.paymentDataWithAddons.addons.length > 0"
              class="mt-4"
            >
              <h3 class="text-profil mt-2">Add-ons</h3>
              <div class="p-4 pt-0 rounded-lg">
                <div
                  v-for="addon in paymentDataFull.paymentDataWithAddons.addons"
                  :key="addon.id"
                  class="flex items-center justify-between border-b border-gray-200 py-3 last:border-0"
                >
                  <div class="flex items-center gap-4">
                    <img 
                      :src="addon.image" 
                      alt="Logo" 
                      class="h-7"
                    />
                  </div>
                  <div class="text-right flex items-center justify-between">
                    <p class="monthy-price text-sm font-semibold bg-gray-900 text-white px-3 py-1 rounded-lg">
                      {{ $t('checkout.pricePerMonth1') }}
                      {{ addon.totalPrice.toFixed(2) }}{{ getCurrencySymbol(addon.currency) }}
                      {{ $t('checkout.pricePerMonth2') }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </transition>


    </div>

    <div class="flex-1">
      <router-view />
    </div>
  </div>
</template>

<script>

export default {
  name: 'SideBarLayout',
  data() {
    return {
      currentStep: parseInt(localStorage.getItem('currentStep')) || 1,
      paymentData: JSON.parse(localStorage.getItem('paymentData')) || null,
      paymentDataFull: JSON.parse(localStorage.getItem('paymentDataFull')) || null,
      isMobile: false,
      isMenuOpen: false,
    };
  },
  watch: {
    currentStep(newStep) {
      localStorage.setItem('currentStep', newStep);
    },
    paymentData(newData) {
      console.log("newData");
      console.log(newData);
      if (newData) {
        localStorage.setItem('paymentData', JSON.stringify(newData));
      } else {
        localStorage.removeItem('paymentData');
      }
    },
    paymentDataFull(newData) {
      console.log("newDatapaymentDataFull");
      console.log(newData);
      if (newData) {
        localStorage.setItem('paymentDataFull', JSON.stringify(newData));
      } else {
        localStorage.removeItem('paymentDataFull');
      }
    },
  },
  mounted() {
    this.updateIsMobile();
    window.addEventListener("resize", this.updateIsMobile);
    const storedPaymentData = localStorage.getItem('paymentData');
    if (storedPaymentData) {
      this.paymentData = JSON.parse(storedPaymentData);
    }

    const storedPaymentDataFull = localStorage.getItem('paymentDataFull');
    if (storedPaymentDataFull) {
      this.paymentDataFull = JSON.parse(storedPaymentDataFull);
    }

    const storedCurrentStep = localStorage.getItem('currentStep');
    if (storedCurrentStep) {
      this.currentStep = parseInt(storedCurrentStep);
    }

    window.addEventListener('storage', this.handleStorageChange);
  },
  beforeUnmount() {
    window.removeEventListener('storage', this.handleStorageChange);
  },
  methods: {
    getCurrencySymbol(currency) {
      if (!currency) return '';
      const symbols = {
        eur: '€',
        usd: '$',
      };
      return symbols[currency.toLowerCase()] || currency.toUpperCase();
    },
    updateIsMobile() {
        this.isMobile = window.innerWidth <= 900; 
      },
    handleStorageChange(event) {
      if (event.key === 'paymentData') {
        this.paymentData = event.newValue ? JSON.parse(event.newValue) : null;
      }
      if (event.key === 'paymentDataFull') {
        this.paymentDataFull = event.newValue ? JSON.parse(event.newValue) : null;
      }
      if (event.key === 'currentStep') {
        this.currentStep = event.newValue ? parseInt(event.newValue) : 1;
      }
    },
    redirectToAccounts() {
      this.$router.push('/accounts'); 
    },
  },
};
</script>



<style scoped>
    .text-profil{
      color: #FFF;
      font-family: Montserrat;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%; 
    }
        
    .title-checkout{
      color: #FFF;
      font-family: Montserrat, sans-serif;
      font-size: clamp(20px, 1.5vw, 23px);
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
    }

    .step-circle {
        width: 3rem;
        height: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px solid white;
        border-radius: 50%;
        color: white;
        font-weight: bold;
      }
      .next-step {
        opacity: 0.5;
      }

      .fade-enter-active,
      .fade-leave-active {
        transition: opacity 0.5s ease;
      }

      .fade-enter {
        opacity: 0;
      }

      .fade-leave-to {
        opacity: 0;
      }
      .step-circle {
        transition: background-color 0.3s, border-color 0.3s;
      }
      .text-lg {
        transition: color 0.3s;
      }
      .payment-summary{
        background: #0E51AC;
      }

      .monthy-price{
        border-radius: 4px;
        background: #083879;
      }

      .quantity-display{
        border-radius: 5px;
        background: #1E93FD;
      }


      .text-lg-desktop{
        color: #FFF;
        font-family: Montserrat;
        font-size: clamp(12px, 1.5vw, 20px);
        font-style: normal;
        font-weight: 600;
        line-height: 140%; 
      }

      .p-responsive {
        padding: 1rem; 
      }

      @media (min-width: 1500px) {
        .p-responsive {
          padding: 4rem; 
        }
      }

    
</style>


