import { createRouter, createWebHistory } from 'vue-router';
import LoginView from '../views/Login.vue';
import SetPassword from '../views/SetPassword.vue';
import TestView from '../views/test.vue';
import MainLayout from '../layouts/MainLayout.vue';
import AccountsView from '../views/Accounts.vue';
import BillingView from '../views/Billing.vue';
import DeveloperView from '../views/Developer.vue';
import HelpView from '../views/Help.vue';
import AcademyView from '../views/Academy.vue';
import SettingsView from '../views/Settings.vue';
import CheckoutView from '../views/checkout/CheckoutStep1.vue';
import CheckoutViewV2 from '../views/checkout/CheckoutStep2.vue';
import CheckoutViewV3 from '../views/checkout/CheckoutStep3.vue';

import CheckoutViewOut from '../views/checkoutlogout/CheckoutStep1.vue';
import CheckoutViewV2Out from '../views/checkoutlogout/CheckoutStep2.vue';
import CheckoutViewV3Out from '../views/checkoutlogout/CheckoutStep3.vue';
import SideBarLayouOut from '../layouts/SideBarLayouOut.vue';

import SuccessView from '../views/checkout/SuccessView.vue';
import CancelView from '../views/checkout/CancelView.vue';
import SideBarLayout from '../layouts/SideBarLayout.vue';
import ForgotPassword from '../views/ForgotPassword'
import ResetPassword from '../views/ResetPassword'
import i18n from '../i18n';

const routes = [
  {
    path: '/',
    redirect: '/accounts',
  },
  {
    path: '/ForgotPassword',
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta: {
      title: i18n.global.t('routes.forgotPassword.title'),
      description: i18n.global.t('routes.forgotPassword.title'),
    },
  },
  {
    path: '/ResetPassword',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: {
      title: i18n.global.t('routes.createPassword.title'),
      description: i18n.global.t('routes.createPassword.title'),
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView,
    meta: {
      title: i18n.global.t('routes.login.title'),
      description: i18n.global.t('routes.login.title'),
    },
  },
  {
    path: '/SetPassword',
    name: 'SetPassword',
    component: SetPassword,
    props: (route) => ({
      email: route.params.email,
      Nom: decodeURIComponent(route.params.Nom || ''),
      Pr_nom: decodeURIComponent(route.params.Pr_nom || ''),
      Langue: route.params.Langue || 'fr-FR',
    }),
    meta: {
      title: i18n.global.t('routes.changePassword.title'),
      description: i18n.global.t('routes.changePassword.title'),
    },
  },
  {
    path: '/test',
    name: 'Test',
    component: TestView,
    meta: {
      title: 'Test Page - My App',
      description: 'This is a test page for development purposes.',
    },
  },
  {
    path: '/checkout',
    component: SideBarLayout,
    meta: {
      title: i18n.global.t('routes.subscription.title'),
      description: i18n.global.t('routes.subscription.description'),
    },
    children: [
      { path: 'v1', name: 'v1', component: CheckoutView, meta: { title: i18n.global.t('routes.subscription.title'), description: i18n.global.t('routes.subscription.description') } },
      { path: 'v2', name: 'v2', component: CheckoutViewV2, meta: { title: i18n.global.t('routes.addons.title'), description: i18n.global.t('routes.addons.description') } },
      { path: 'v3', name: 'v3', component: CheckoutViewV3, meta: { title: i18n.global.t('routes.summary.title'), description: i18n.global.t('routes.summary.description') } },
      { path: 'v3/success', name: 'SuccessView', component: SuccessView, meta: { title: i18n.global.t('routes.success.title'), description: i18n.global.t('routes.success.description') } },
      { path: 'v3/cancel', name: 'CancelView', component: CancelView, meta: { title: i18n.global.t('routes.paymentCancelled.title'), description: i18n.global.t('routes.paymentCancelled.description') } },
    ],
  },
  {
    path: '/checkoutlogout',
    component: SideBarLayouOut,
    meta: {
      title: i18n.global.t('routes.subscription.title'),
      description: i18n.global.t('routes.subscription.description'),
    },
    children: [
      { path: 'v1', name: 'v1Out', component: CheckoutViewOut, meta: { title: i18n.global.t('routes.subscription.title'), description: i18n.global.t('routes.subscription.description') } },
      { path: 'v2', name: 'v2Out', component: CheckoutViewV2Out, meta: { title: i18n.global.t('routes.addons.title'), description: i18n.global.t('routes.addons.description') } },
      { path: 'v3', name: 'v3Out', component: CheckoutViewV3Out, meta: { title: i18n.global.t('routes.summary.title'), description: i18n.global.t('routes.summary.description') } },
      { path: 'v3/success', name: 'SuccessViewOut', component: SuccessView, meta: { title: i18n.global.t('routes.success.title'), description: i18n.global.t('routes.success.description') } },
      { path: 'v3/cancel', name: 'CancelViewOut', component: CancelView, meta: { title: i18n.global.t('routes.paymentCancelled.title'), description: i18n.global.t('routes.paymentCancelled.description') } },
    ],
  },
  {
    path: '/',
    component: MainLayout,
    children: [
      { path: 'accounts', name: 'Accounts', component: AccountsView, meta: { title: i18n.global.t('routes.accounts.title'), description: i18n.global.t('routes.accounts.description') } },
      { path: 'billing', name: 'Billing', component: BillingView, meta: { title: i18n.global.t('routes.accounts.title'), description: i18n.global.t('routes.accounts.description') } },
      { path: 'developer', name: 'Developer', component: DeveloperView, meta: { title: i18n.global.t('routes.accounts.title'), description: i18n.global.t('routes.accounts.description') } },
      { path: 'help', name: 'Help', component: HelpView, meta: { title: i18n.global.t('routes.accounts.title'), description: i18n.global.t('routes.accounts.description') } },
      { path: 'academy', name: 'Academy', component: AcademyView, meta: { title: i18n.global.t('routes.academy.title'), description: i18n.global.t('routes.academy.description') } },
      { path: 'settings', name: 'Settings', component: SettingsView, meta: { title: i18n.global.t('routes.settings.title'), description: i18n.global.t('routes.settings.description') } },
    ],
  },
];


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token');

  if (to.matched.some(record => record.meta.requiresAuth) && !token) {
    next({ name: 'Login' });
  } else {
    if (to.meta.title) {
      document.title = to.meta.title;
    }

    const description = document.querySelector('meta[name="description"]');
    if (description) {
      description.setAttribute('content', to.meta.description || '');
    }

    next();
  }
});

export default router;
