<template>
  <div v-if="isLoading" class="overlay-loader fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[999999999999999]">
    <div class="loader loader--style8" title="Loading">
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="40px"
        height="50px"
        viewBox="0 0 24 30"
        style="enable-background:new 0 0 50 50;"
        xml:space="preserve"
      >
        <rect x="0" y="10" width="4" height="10" class="gradient-bar">
          <animate
            attributeName="opacity"
            attributeType="XML"
            values="0.2; 1; .2"
            begin="0s"
            dur="0.6s"
            repeatCount="indefinite"
          />
          <animate
            attributeName="height"
            attributeType="XML"
            values="10; 20; 10"
            begin="0s"
            dur="0.6s"
            repeatCount="indefinite"
          />
          <animate
            attributeName="y"
            attributeType="XML"
            values="10; 5; 10"
            begin="0s"
            dur="0.6s"
            repeatCount="indefinite"
          />
        </rect>
        <rect x="8" y="10" width="4" height="10" class="gradient-bar">
          <animate
            attributeName="opacity"
            attributeType="XML"
            values="0.2; 1; .2"
            begin="0.15s"
            dur="0.6s"
            repeatCount="indefinite"
          />
          <animate
            attributeName="height"
            attributeType="XML"
            values="10; 20; 10"
            begin="0.15s"
            dur="0.6s"
            repeatCount="indefinite"
          />
          <animate
            attributeName="y"
            attributeType="XML"
            values="10; 5; 10"
            begin="0.15s"
            dur="0.6s"
            repeatCount="indefinite"
          />
        </rect>
        <rect x="16" y="10" width="4" height="10" class="gradient-bar">
          <animate
            attributeName="opacity"
            attributeType="XML"
            values="0.2; 1; .2"
            begin="0.3s"
            dur="0.6s"
            repeatCount="indefinite"
          />
          <animate
            attributeName="height"
            attributeType="XML"
            values="10; 20; 10"
            begin="0.3s"
            dur="0.6s"
            repeatCount="indefinite"
          />
          <animate
            attributeName="y"
            attributeType="XML"
            values="10; 5; 10"
            begin="0.3s"
            dur="0.6s"
            repeatCount="indefinite"
          />
        </rect>
      </svg>
    </div>
  </div>


  <div class="grid-container account-page">
    <div class="header">
        <div class="flex flex-col md:flex-row justify-between items-center mb-4">
          <h1 class="title-main-account">{{ $t('accounts.title') }}</h1>
        </div>
        <div class="mt-4 mb-6 border-t border-blue-200 espace"></div>
        <div class="flex flex-col md:flex-row items-center justify-between space-y-2 md:space-y-0 md:space-x-4 mb-4 p-4  rounded-lg" style="box-shadow: 0px 10px 40px rgba(30, 147, 253, 0.2);">
          <div class="text-counter-account bg-blue-100  px-4 py-2 rounded-lg flex items-center space-x-6 md:w-auto">
            <div class="flex items-center">
              <i class="fas fa-user mr-2"></i>
              <span>{{ totalAccounts }}</span>
            </div>
            <div class="flex flex-wrap items-center gap-3">
              <span class="flex items-center gap-2 whitespace-nowrap">
                <img :src="flags.eu" class="w-5 h-auto" alt="European Flag" />
                {{ europeanAccounts }}
              </span>
              <span class="flex items-center gap-2 whitespace-nowrap">
                <img :src="flags.us" class="w-5 h-auto" alt="US Flag" />
                {{ usAccounts }}
              </span>
            </div>
          </div>

          <button class="bg-gradient-to-r from-blue-500 to-blue-700 text-getAccounts px-6 py-2 rounded-full flex items-center hover:from-blue-600 hover:to-blue-800"
          @click="$router.push('/checkout/v1')">
            <i class="fas fa-plus mr-2"></i> {{ $t('accounts.getNewAccounts') }}
          </button>
        </div>
      </div>

      <div
      :class="{ 'reduced-size': (selectedAccount && problemType !== 'Compte strike') && !isMobile, 'full-size': !selectedAccount}" 
      class="test-anime bg-white p-6" style="box-shadow: 0px 10px 40px rgba(30, 147, 253, 0.2);">
      <div class="flex flex-col md:flex-row items-center space-y-2 md:space-y-0 md:space-x-4 mb-4 sticky-header">
        <div class="relative flex-grow">
          <span class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none text-gray-400">
            <i class="fas fa-search"></i>
          </span>
          <input
            type="text"
            v-model="searchQuery"
            :placeholder="$t('accounts.Search')"
            class="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        <div class="flex flex-row w-full md:w-auto space-x-2">
          <div class="relative flex-1">
            <button
              @click="toggleLocationFilterDropdown"
              class="border border-blue-500 text-blue-500 px-4 py-2 rounded-lg flex justify-center items-center hover:bg-blue-100 transition-colors w-full md:w-auto"
            >
              <i class="fas fa-map-marker-alt mr-2"></i> <span class="text-center hide-on-medium-screen" v-if="!isMobile && !selectedAccount">{{ selectedLocationFilter || $t('accounts.filterLocation') }}</span>
              <i class="fas fa-caret-down ml-1"></i>
            </button>
            <div v-if="showLocationFilterDropdown" class="absolute z-10 bg-white shadow-lg rounded-lg mt-2 p-2 w-40">
              <button
                v-for="option in locationFilterOptions"
                :key="option.value"
                @click="applyLocationFilter(option.value)"
                class="w-full text-left px-2 py-1 hover:bg-gray-100"
              >
                {{ option.label }}
              </button>
            </div>
          </div>

          <div class="relative flex-1">
            <button
              @click="toggleGenderFilterDropdown"
              class="border border-blue-500 text-blue-500 px-4 py-2 rounded-lg flex justify-center items-center hover:bg-blue-100 transition-colors w-full md:w-auto"
            >
              <i class="fas fa-user mr-2"></i> 
              <span class="text-center hide-on-medium-screen" v-if="!isMobile && !selectedAccount">{{ selectedGenderFilter || $t('accounts.filterGender') }}</span>
              <i class="fas fa-caret-down ml-1"></i>
            </button>
            <div 
              v-if="showGenderFilterDropdown" 
              class="absolute z-10 bg-white shadow-lg rounded-lg mt-2 p-2 w-40"
            >
              <button
                v-for="option in genderFilterOptions"
                :key="option.value"
                @click="applyGenderFilter(option.value)"
                class="w-full text-left px-2 py-1 hover:bg-gray-100"
              >
                {{ option.label }}
              </button>
            </div>
          </div>

          <div class="flex flex-row w-full md:w-auto space-x-2">
            <div class="relative flex-1">
              <button
                @click="toggleStatusFilterDropdown"
                class="border border-blue-500 text-blue-500 px-4 py-2 rounded-lg flex justify-center items-center hover:bg-blue-100 transition-colors w-full md:w-auto"
              >
                <i class="fas fa-tags mr-2"></i> 
                <span class="text-center hide-on-medium-screen" v-if="!isMobile && !selectedAccount">{{ selectedStatusFilter || $t('accounts.filterStatus') }}</span>
                <i class="fas fa-caret-down ml-1"></i>
              </button>
              <div v-if="showStatusFilterDropdown" class="absolute z-10 bg-white shadow-lg rounded-lg mt-2 p-2 w-40">
                <button
                  v-for="option in statusFilterOptions"
                  :key="option.value"
                  @click="applyStatusFilter(option.value)"
                  class="w-full text-left px-2 py-1 hover:bg-gray-100"
                >
                  {{ option.label }}
                </button>
              </div>
            </div>
          </div>

          <button 
            @click="toggleSortOrder" 
            class="border border-blue-500 text-blue-500 px-4 py-2 rounded-lg flex items-center hover:bg-blue-100 transition-colors w-full md:w-auto flex-1"
          >
            <i class="fas fa-sort mr-2"></i><span class="hide-on-medium-screen" v-if="!isMobile && !selectedAccount">{{ sortOrder === 'asc' ? $t('accounts.sortAsc') : $t('accounts.sortDesc') }}</span> 
            <i class="fas fa-caret-down ml-1"></i>
          </button>
        </div>

      </div>

        

        <div  >
          <div 
            v-for="(account, index) in (filterActivate ? filteredAccounts : displayedAccounts)" 
            :key="index" 
            class="flex items-center justify-between p-4 transition-colors -mx-6 w-[calc(100%+3rem)] cursor-pointer"
            :class="{
              'bg-blue-100': activeAccount === account.compte,
              'bg-white': index % 2 === 0 && activeAccount !== account.compte,
              'bg-gray-100': index % 2 !== 0 && activeAccount !== account.compte,
              'hover:bg-blue-100': true,
              'opacity-50': account.isPreparing,
            }"
              @click="account.etats !== 'strike' && showAccountDetails(account.email)" 
          >
            <div class="flex items-center space-x-4 flex-grow">
              <template v-if="account.isPending">
                <div class="flex items-center space-x-2">
                  <img 
                      src="../assets/profil.png" 
                      alt="Image de bonhomme" 
                      class="w-12 h-12 rounded-full object-cover"
                  />                    
                  <div>
                    <p class="text-compte-title pending">{{ $t('accounts.pendingAccount') }}</p>
                    <p class="text-compte-poste flex items-center gap-2 whitespace-nowrap">
                      {{ $t('accounts.preparingAccount') }} 
                      <span class="ml-2 text-lg">
                        <img :src="getPendingFlag(account.pays)" class="w-5 h-auto" />
                        <span v-if="account.SalesNav" class="ml-2">
                          <img src="../assets/salesnav.png" alt="SalesNav" class="w-auto h-6 inline" />
                        </span>
                      </span>
                    </p>
                  </div>
                </div>
              </template>

              <template v-else-if="account.isPreparing">
                <img :src="account.photo && JSON.parse(account.photo).length ? JSON.parse(account.photo)[0].url : require('../assets/profil.png')" :alt="account.compte" class="w-12 h-12 rounded-full">
                <div>
                  <p class="text-compte-title">{{ account.compte }} 
                  </p>
                  <span v-if="account.SalesNav" class="ml-3">
                        <img src="../assets/salesnav.png" alt="SalesNav" class="w-auto h-6 inline" />
                      </span>
                  <p class="text-compte-poste flex items-center gap-2 whitespace-nowrap">
                    {{ $t('accounts.preparingAccount') }}
                    <span class="ml-2 text-lg">
                      <img :src="getNationality(account.pays)" class="w-5 h-auto"  />
                    </span>
                  </p>
                </div>
              </template>

              <template v-else>
                <div class="flex items-center space-x-4">
                  <img 
                    :src="account.photo && JSON.parse(account.photo).length ? JSON.parse(account.photo)[0].url : require('../assets/profil.png')" 
                    :alt="account.compte" 
                    class="w-12 h-12 rounded-full"
                  />
                  
                  <div>
                    <p
                      class="text-compte-title flex flex-col sm:flex-row items-start sm:items-center sm:space-x-2 space-y-2 sm:space-y-0"
                    >
                      <span class="flex-shrink-0">{{ account.compte }}</span>
                      <span v-if="account.SalesNav" class="ml-3">
                        <img src="../assets/salesnav.png" alt="SalesNav" class="w-auto h-6 inline" />
                      </span>

                      <span
                        v-if="account.resiliationDate"
                        class="bg-yellow-100 text-yellow-700 px-3 py-1 rounded-full text-sm font-semibold border flex items-center"
                      >
                        <i class="fas fa-hourglass-half mr-2"></i>
                        <span>{{ formatDate(account.resiliationDate) }}</span>
                      </span>

                      <span
                        v-if="account.etats === 'strike'"
                        class="bg-red-100 text-red-600 px-3 py-1 rounded-full text-sm font-semibold flex items-center"
                      >
                        {{ $t('accounts.strike') }}
                      </span>
                    </p>


                    
                    <p class="text-compte-poste flex items-center gap-2 whitespace-nowrap">
                      {{ (selectedAccount || isMobile) ? truncateText(account.poste, 15) : account.poste }}
                      <span class="ml-2 text-lg">
                        <img :src="getNationality(account.pays)" class="w-5 h-auto"  />
                        
                      </span>
                    </p>
                  </div>
                </div>
            </template>
            </div>

            <div class="flex items-center space-x-2">
              <template v-if="account.isPreparing">
                {{ $t('accounts.addedSoon') }}
              </template>

              <template v-if="!account.isPending && !account.isPreparing">
               
                <a
                  v-if="account.etats !== 'strike' && !isMobile"
                  :href="account.lien_gologin"
                  target="_blank"
                  @click.stop="selectedAccount = ''; activeAccount = '';"
                  :class="[
                    'launch-button border px-4 py-2 rounded-full text-sm font-semibold flex items-center justify-center transition-colors',
                    selectedAccount ? 'bg-transparent text-transparent' : 'bg-gradient-text'
                  ]"
                >
                  <i :class="{ 'mr-2': (!selectedAccount && !isMobile) || problemType === 'Compte strike' }" class="fas fa-rocket"></i>
   
                    <span v-if="(!selectedAccount || problemType === 'Compte strike') && !isMobile" class="inline-block overflow-hidden transition-all">
                      {{ $t('accounts.launchSession') }}
                    </span>
                </a>
                <button
                v-if="account.etats !== 'strike'"
                  class="text-white w-8 h-8 rounded-full flex items-center justify-center transition-transform hover:scale-105"
                  style="background: linear-gradient(90deg, #1E93FD 0%, #3154C8 100%); box-shadow: 0 4px 10px rgba(30, 147, 253, 0.3);"
                >
                  <i class="fas fa-plus"></i>
                </button>
                <template v-if="account.etats === 'strike'">
                  <button
                    @click="account.sendStrike ? null : replaceAccount(account)" 
                    :disabled="account.sendStrike" 
                    :class="[
                      'replace-button border px-4 py-2 rounded-full text-sm font-semibold flex items-center justify-center transition-colors',
                      selectedAccount ? 'bg-transparent text-transparent' : 'bg-gradient-text',
                      account.sendStrike ? 'opacity-50 cursor-not-allowed' : ''
                    ]"
                  >
                    <i 
                      :class="{ 'mr-2': (!selectedAccount && !isMobile) || problemType === 'Compte strike' }" 
                      class="fas fa-random"
                    ></i>

                    <span 
                      v-if="(!selectedAccount || problemType === 'Compte strike') && !isMobile" 
                      class="inline-block overflow-hidden transition-all"
                    >
                      {{ account.sendStrike ? $t('accounts.CannotReplace') : $t('accounts.Replace') }}
                    </span>
                  </button>

                <button
                  v-if="account.etats === 'strike'"
                  class="text-white w-8 h-8 rounded-full flex items-center justify-center transition-transform hover:scale-105"
                  style="background: linear-gradient(90deg, #9E9E9E 0%, #757575 100%); box-shadow: 0 4px 10px rgba(158, 158, 158, 0.3);"
                  >
                  <i class="fas fa-plus"></i>
                </button>

              </template>
              </template>
              <template v-if="account.isPending">
                <img 
                  src="../assets/profil_loader.png" 
                  alt="Chargement du profil" 
                  class="w-8 h-8 rounded-full animate-spin-custom"
                />
              </template>
            </div>
          </div>
        </div>

      </div>
      <transition name="slide-in">
        <div
          v-if="selectedAccount && problemType !== 'Compte strike'"
          class="details-panel bg-white"
          :class="{
            'mobile-fullscreen': isMobile,
            'activated': selectedAccount && !isMobile
          }"
        >
          <button
                @click="closeDetails"
                class="flex items-center gradient-text hover:underline"
                >
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.6021 4.68989C10.8657 4.95349 10.8657 5.38088 10.6021 5.64448L7.2544 8.99219L10.6021 12.3399C10.8657 12.6035 10.8657 13.0309 10.6021 13.2945C10.3385 13.5581 9.91111 13.5581 9.64751 13.2945L5.82251 9.46948C5.69592 9.3429 5.6248 9.17121 5.6248 8.99219C5.6248 8.81317 5.69592 8.64148 5.82251 8.51489L9.64751 4.68989C9.91111 4.42629 10.3385 4.42629 10.6021 4.68989Z" fill="url(#paint0_linear_535_4125)"/>
                    <defs>
                        <linearGradient id="paint0_linear_535_4125" x1="8.2123" y1="4.49219" x2="8.2123" y2="13.4922" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#1E93FD"/>
                        <stop offset="1" stop-color="#3154C8"/>
                        </linearGradient>
                    </defs>
                </svg>
                {{ $t('accounts.back') }}
            </button>

          <h2 class="mt-5">{{ $t('accounts.detailsTitle') }}</h2>
          <div class="text-info">
            <p class="border-b border-gray-200 py-3 last:border-0">
              <strong>{{ $t('accounts.fullName') }}</strong>
              <span>{{ selectedAccount.compte }}</span>
            </p>
            <p class="border-b border-gray-200 py-3 last:border-0">
              <strong>{{ $t('accounts.company') }}</strong>
              <span>{{ selectedAccount.entreprise }}</span>
            </p>
            <p class="border-b border-gray-200 py-3 last:border-0">
              <strong>{{ $t('accounts.jobTitle') }}</strong>
              <span>{{ selectedAccount.poste }}</span>
            </p>
            <p class="border-b border-gray-200 py-3 last:border-0">
              <strong>{{ $t('accounts.numberOfConnections') }}</strong>
              <span>{{ selectedAccount.nombre_relations }}</span>
            </p>
            <p class="border-b border-gray-200 py-3 last:border-0">
              <strong>{{ $t('accounts.automationTool') }}</strong>
              <span v-if="selectedAccount.Automation_tool_setup">
                {{ selectedAccount.Automation_tool_setup }}
              </span>
              <span v-else>
                {{ $t('accounts.noAutomationTool') }}
              </span>            
            </p>
            <p class="border-b border-gray-200 py-3 last:border-0">
              <strong>{{ $t('accounts.email') }}</strong>
              <span>{{ selectedAccount.email }}</span>
            </p>
            <p class="border-b border-gray-200 py-3 last:border-0" style="text-align: center;">
              <strong>{{ $t('accounts.password') }}</strong>
              <span v-if="passwordVisible">
                {{ password }}
                <i class="fa-solid fa-eye-slash" @click="togglePasswordVisibility" style="
                  margin-left: 8px; 
                  background: var(--gradient, linear-gradient(90deg, #1E93FD 0%, #3154C8 100%)); 
                  -webkit-background-clip: text; 
                  -webkit-text-fill-color: transparent; 
                  cursor: pointer;
                "></i>
              </span>
              <span v-else>
                ******** 
                <i class="fa-solid fa-eye" @click="fetchPassword" style="
                  margin-left: 8px; 
                  background: var(--gradient, linear-gradient(90deg, #1E93FD 0%, #3154C8 100%)); 
                  -webkit-background-clip: text; 
                  -webkit-text-fill-color: transparent; 
                  cursor: pointer;
                "></i>
              </span>
            </p>
            <p class="border-b border-gray-200 py-3 last:border-1">
              <strong>{{ $t('accounts.proxyDetails') }}</strong>
              <a
                @click="() => { showProxyModal = true ;}"
                target="_blank"
                class="btn-linkedin "
                style="color: white;"
              >
                {{ $t('accounts.viewproxyDetails') }}
              </a>
            </p>
            <p class="border-b border-gray-200 py-3 last:border-1">
              <strong>{{ $t('accounts.urlLinkedIn') }}</strong>
              <a
                :href="selectedAccount.linkedin_profile_url"
                target="_blank"
                class="btn-linkedin "
                style="color: white;"
              >
                {{ $t('accounts.viewLinkedInProfile') }}
              </a>
            </p>
          </div>
          <div class="mt-6 space-y-4">
            <h3>{{ $t('accounts.actionsTitle') }}</h3>
            <div class="flex items-center justify-between">
              <button @click="fetchOTP" class="action-button" :disabled="!isMailValid">
                {{ $t('accounts.get2FA') }} <i class="fas fa-key"></i>
              </button>
              <div class="ml-4 flex items-center space-x-2">

                <span
                    v-if="otp !== null"
                    :class="{
                      'text-sm text-red-500 line-through': period === 0, 
                      'text-sm text-blue-500 font-semibold': period > 0 
                    }"
                  >
                  {{ otp }}
                  <button
                    v-if="otp !== null"
                    @click="copyToClipboard(otp)"
                    class="ml-2 text-blue-500 hover:text-blue-800 mr-2"
                    title="Copier le code OTP"
                  >
                    <i class="fas fa-clipboard"></i>
                  </button>

                  <p v-if="period > 0" class="text-gray-500 text-sm inline">
                    {{ period }}s 
                  </p>
                </span>   
              </div>
            </div>
            <div class="flex items-center justify-between">
              <button @click="testProxy" class="action-button">
                {{ $t('accounts.testProxy') }} <i class="fas fa-satellite-dish"></i>
              </button>
              <div class="ml-4">
                <span v-if="proxyTestResult"   :class="proxydata > 300 ? 'proxy-slow' : 'proxy'">
                  {{ proxyTestResult }} 
                </span>
              </div>
            </div>
            <button @click="showProblemModal = true" class="action-button">
              {{ $t('accounts.reportProblem') }} <i class="fas fa-exclamation-circle"></i>
            </button>
            <button @click="showAutomationModal = true" class="action-button">
              {{ $t('accounts.addAutomationTool') }} <i class="fas fa-plug"></i>
            </button>
          </div>
          <div class="remove-button-container">
            <button 
              class="remove-button w-full max-w-md" 
              @click="handleRemoveButtonClick">
              <i class="fas fa-trash-alt mr-2"></i>
              {{ 
                selectedAccount.resiliationDate 
                  ? $t('accounts.cancelResiliation', { resiliationDate: formatDate(selectedAccount.resiliationDate) }) 
                  : $t('accounts.removeFromSubscription') 
              }}
            </button>
          </div>
        </div>
      </transition>

    </div>
    <div v-if="showProblemModal" class="modal-overlay flex items-center justify-center min-h-screen bg-gray-900 bg-opacity-50">
  <div class="modal bg-white rounded-lg p-6 shadow-lg max-w-lg relative"
  :class="{ 'w-full': !isMobile }">
    <button 
    @click="() => { showProblemModal = false; problemType = ''; selectedAccount = ''; activeAccount = ''; }" 
      class="absolute top-4 right-4 text-gray-400 hover:text-gray-600 focus:outline-none"
    >
      <i class="fas fa-times text-xl"></i>
    </button>

    <h2 class="text-center text-xl-comp modal-title mb-4">
      {{ problemType === 'Compte strike' ? "" : $t('accounts.reportProblemTitle') }}
    </h2>
    <p v-if="problemType === 'Compte strike'" class="text-center text-xl modal-title mb-4">
      {{ $t('accounts.replaceAccountInstruction') }}
    </p>

    <div class="flex flex-col items-start mb-6 mt-6">
      <div class="text-left mb-5">
        <h2 class="title-compte">{{ $t('accounts.compte') }}</h2>
      </div>
      
      <div class="flex items-center justify-between w-full">
        <div class="flex items-center">
          <img 
            :src="selectedAccount.photo && JSON.parse(selectedAccount.photo).length ? JSON.parse(selectedAccount.photo)[0].url : require('../assets/profil.png')" 
            :alt="selectedAccount.compte" 
            class="w-12 h-12 rounded-full"
          />
          <p class="text-compte ml-5">{{ selectedAccount.compte }}</p>
        </div>
        
      </div>
    </div>


    <form @submit.prevent="submitProblemForm" class="space-y-6">
      <template v-if="problemType === 'Compte strike'">
        <div>
          <label class="block text-sm modal-label mb-2">
            {{ $t('accounts.linksSentQuestion') }}
          </label>
          <div class="flex items-center space-x-6 ">
            <label class="radio-label flex items-center cursor-pointer">
              <input
                type="radio"
                v-model="linksSent"
                :value="true"
                class="hidden"
              />
              <span class="custom-radio"></span>
              <span class="radio-text ml-2" :class="{ active: linksSent === true }">{{ $t('common.yes') }}</span>
            </label>
            <label class="radio-label flex items-center cursor-pointer">
              <input
                type="radio"
                v-model="linksSent"
                :value="false"
                class="hidden"
              />
              <span class="custom-radio"></span>
              <span class="radio-text ml-2" :class="{ active: linksSent === false }">{{ $t('common.no') }}</span>
            </label>
          </div>
        </div>

        <div>
          <label class="block text-sm modal-label mb-2">
            {{ $t('accounts.connectionRequests') }}
          </label>
          <input
            type="number"
            v-model="connectionRequests"
            class="w-full px-4 py-2 border rounded-lg "
            :placeholder="$t('accounts.enterNumber')"
          />
        </div>
        <div>
          <label class="block text-sm modal-label mb-2">
            {{ $t('accounts.profileVisits') }}
          </label>
          <input
            type="number"
            v-model="profileVisits"
            class="w-full px-4 py-2 border rounded-lg "
            :placeholder="$t('accounts.enterNumber')"
          />
        </div>

        <div>
          <label class="block text-sm modal-label mb-2">
            {{ $t('accounts.automationToolUsed') }}
          </label>
          <select v-model="automationTool" class="select-problem w-full px-4 py-2 border rounded-lg">
            <option v-for="tool in automationTools" :key="tool" :value="tool">{{ tool }}</option>
          </select>
        </div>
      </template>

      <template v-else>
        <div>
          <label class="block text-sm modal-label mb-2">
            {{ $t('accounts.problemType') }}
          </label>
          <select
            v-model="problemType"
            class="select-problem w-full px-4 py-2 border rounded-lg mb-4"
          >
            <option
              v-for="(type, index) in problemTypes"
              :key="index"
              :value="type.id"
                          >
              {{ type.label }}
            </option>
          </select>

          <label class="block text-sm modal-label mb-2">
            {{ $t('accounts.problemDescription') }}
              <span
              v-if="!isDescriptionRequired"
              class="text-sm text-gray-500 mr-1"
            >
              ({{ $t('accounts.descriptionOptional') }})
            </span>
          </label>
          <textarea
            v-model="problemDescription"
            class="w-full px-4 py-2 border rounded-lg"
            rows="4"
            :placeholder="$t('accounts.problemDescriptionPlaceholder')"
          ></textarea>
          
        </div>


      </template>

      <div class="flex justify-center w-full">
        <button
          type="submit"
          class="submit-button text-white font-bold py-2 px-8 rounded-full w-[60%] max-w-md hover:from-blue-600 hover:to-blue-800"
          :disabled="problemType === '' || (problemType === 'Compte strike' && automationTool === '')"
        >
          {{ $t('accounts.submit') }}
        </button>
      </div>
    </form>
  </div>
</div>

<div v-if="showRemoveSubModal" class="modal-overlay flex items-center justify-center min-h-screen bg-gray-900 bg-opacity-50 fixed inset-0">
  <div class="modal-content bg-white rounded-lg p-6 w-[90%] max-w-md relative">
        <button @click="() => { showRemoveSubModal = false; problemType = ''; console.log(problemType) }" class="close-button absolute top-4 right-4 text-gray-600 hover:text-gray-900">
          <i class="fas fa-times"></i>
        </button>
        
        <div class="flex flex-col items-center space-y-4">
          <img src="../assets/Remove-logo.png" alt="Remove Icon" class="w-28 mt-8 mb-2" />
          <h2 class="text-lg modal-title text-center">{{ $t('accounts.stopSubscriptionTitle') }}</h2>
          <p class="modal-desc text-center">{{ $t('accounts.renewalDate') }}: <span class="font-bold">{{ renewalDate }}</span></p>

          <div class="w-full">
            <label for="removeOption" class="block text-sm modal-label">{{ $t('accounts.removeOption') }}</label>
            <select v-model="removeOption" id="removeOption" class="select-problem w-full mt-2 p-2 border border-gray-300 rounded-lg">
              <option value="now">{{ $t('accounts.removeNow') }}</option>
              <option value="end">{{ $t('accounts.removeEndOfMonth') }}</option>
            </select>
          </div>

          <div class="bg-blue-100 info-modal px-4 py-2 rounded-md text-sm grid grid-cols-[auto,1fr] items-center gap-2">
            <i class="fas fa-info-circle text-lg"></i>
            <span>{{ $t('accounts.removeWarning') }}</span>
          </div>

          <div class="flex justify-center w-full">
            <button
              @click="removeSubscriptionBeSure"
              class="submit-button text-white font-bold py-2 px-8 rounded-full w-[60%] max-w-md hover:from-blue-600 hover:to-blue-800"
            >
            {{ $t('accounts.remove') }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div v-if="removeSubSure" class="modal-overlay flex items-center justify-center min-h-screen bg-gray-900 bg-opacity-50 fixed inset-0">
      <div class="modal-content bg-white rounded-lg p-6 w-[90%] max-w-md relative">
        <button @click="() => { removeSubSure = false; problemType = '';  }" class="close-button absolute top-4 right-4 text-gray-600 hover:text-gray-900">
          <i class="fas fa-times"></i>
        </button>
        
        <div class="flex flex-col items-center space-y-4">
          <img src="../assets/Remove-logo.png" alt="Remove Icon" class="w-28 mt-8 mb-2" />
          <h2 class="text-lg modal-title text-center">{{ $t('accounts.stopSubscriptionSure') }}</h2>
          <p class="modal-desc text-center">{{ $t('accounts.stopSubscriptionSureDesc') }}</p>
          <div class="flex justify-center w-full">
            <button
              @click="removeSubscription"
              class="submit-button text-white font-bold py-2 px-8 rounded-full w-[60%] max-w-md hover:from-blue-600 hover:to-blue-800"
            >
            {{ $t('accounts.remove') }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div v-if="showCancelRemoveSubModal" class="modal-overlay flex items-center justify-center min-h-screen bg-gray-900 bg-opacity-50 fixed inset-0">
      <div class="modal-content bg-white rounded-lg p-6 w-[90%] max-w-md relative">
        <button @click="() => { showCancelRemoveSubModal = false; problemType = '';  }" class="close-button absolute top-4 right-4 text-gray-600 hover:text-gray-900">
          <i class="fas fa-times"></i>
        </button>
        
        <div class="flex flex-col items-center space-y-4">
          <img src="../assets/Remove-logo.png" alt="Remove Icon" class="w-28 mt-8 mb-2" />
          <h2 class="text-lg modal-title text-center">{{ $t('accounts.stopCancelSubscriptionSure') }}</h2>
          <p class="modal-desc text-center">{{ $t('accounts.stopCancelSubscriptionSureDesc') }}</p>
          <div class="flex justify-center w-full">
            <button
              @click="cancelRemoveSubscription"
              class="submit-button text-white font-bold py-2 px-8 rounded-full w-[60%] max-w-md hover:from-blue-600 hover:to-blue-800"
            >
            {{ $t('accounts.cancelremove') }}
            </button>
          </div>
        </div>
      </div>
    </div>


    <div v-if="showAutomationModalSuccess" class="modal-overlay flex items-center justify-center min-h-screen bg-gray-900 bg-opacity-50 fixed inset-0">
      <div class="modal-content bg-white rounded-lg p-6 w-[90%] max-w-md relative">
        <button @click="() => { showAutomationModalSuccess = false; problemType = '';  }" class="close-button absolute top-4 right-4 text-gray-600 hover:text-gray-900">
          <i class="fas fa-times"></i>
        </button>
        
        <div class="flex flex-col items-center space-y-4">
          <img src="../assets/automationSucess.png" alt="Remove Icon" class="w-28 mt-8 mb-2" />
          <h2 class="text-lg modal-title text-center">{{ $t('accounts.automationSuccessTitle') }}</h2>
          <p class="modal-desc text-center">{{ $t('accounts.automationSuccessDesc') }}</p>
          <div class="flex justify-center w-full">
            <button
              @click="() => { showAutomationModalSuccess = false; problemType = '';  }"
              class="submit-button text-white font-bold py-2 px-8 rounded-full w-[60%] max-w-md hover:from-blue-600 hover:to-blue-800"
            >
            OK
            </button>
          </div>
        </div>
      </div>
    </div>

    <div v-if="showModalSuccess" class="modal-overlay flex items-center justify-center min-h-screen bg-gray-900 bg-opacity-50 fixed inset-0">
      <div class="modal-content bg-white rounded-lg p-6 w-[90%] max-w-md relative">
        <button @click="() => { showModalSuccess = false; problemType = '';  }" class="close-button absolute top-4 right-4 text-gray-600 hover:text-gray-900">
          <i class="fas fa-times"></i>
        </button>
        
        <div class="flex flex-col items-center space-y-4">
          <img src="../assets/automationSucess.png" alt="Remove Icon" class="w-28 mt-8 mb-2" />
          <h2 class="text-lg modal-title text-center">{{ $t('accounts.automationSuccessTitle') }}</h2>
          <p class="modal-desc text-center">{{ $t('accounts.SuccessDesc') }}</p>
          <div class="flex justify-center w-full">
            <button
              @click="() => { showModalSuccess = false; problemType = '';  }"
              class="submit-button text-white font-bold py-2 px-8 rounded-full w-[60%] max-w-md hover:from-blue-600 hover:to-blue-800"
            >
            OK
            </button>
          </div>
        </div>
      </div>
    </div>
    
    
    <div v-if="showAutomationModal" class="modal-overlay flex items-center justify-center min-h-screen bg-gray-900 bg-opacity-50">     
  <div class="modal bg-white rounded-lg p-6 shadow-lg max-w-lg relative"
  :class="{ 'w-full': !isMobile }">
    <button 
    @click="() => { showAutomationModal = false; problemType = '';}" 
      class="absolute top-4 right-4 text-gray-400 hover:text-gray-600 focus:outline-none"
    >
      <i class="fas fa-times text-xl"></i>
    </button>

    <h2 class="text-xl modal-title text-center mb-5 ">
      {{ $t('accounts.addAutomationTitle') }}
    </h2>
    <div class="flex flex-col items-start mb-6 mt-6">
      <div class="text-left mb-3">
        <h2 class="title-compte">{{ $t('accounts.compte') }}</h2>
      </div>
      
      <div class="flex items-center justify-between w-full">
        <div class="flex items-center">
          <img 
            :src="selectedAccount.photo && JSON.parse(selectedAccount.photo).length ? JSON.parse(selectedAccount.photo)[0].url : require('../assets/profil.png')" 
            :alt="selectedAccount.compte" 
            class="w-12 h-12 rounded-full"
          />
          <p class="text-compte ml-5">{{ selectedAccount.compte }}</p>
        </div>
        
      </div>
    </div>
    <form @submit.prevent="submitAutomationForm">
      <div class="mb-6">
        <label class="block text-sm modal-label mb-2">
          {{ $t('accounts.selectTool') }}
        </label>
        <select
          v-model="automationTool"
          class="select-problem w-full px-4 py-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
        >
          <option v-for="tool in automationTools" :key="tool" :value="tool">
            {{ tool }}
          </option>
        </select>
      </div>
      <div class="mb-6">
        <label class="block text-sm modal-label mb-2">
          {{ $t('accounts.email') }}
        </label>
        <input
          v-model="automationEmail"
          type="email"
          class="w-full px-4 py-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          placeholder="example@email.com"
          required
        />
      </div>
      <div class="mb-6">
        <label class="block text-sm modal-label mb-2">
          {{ $t('accounts.password') }}
        </label>
        <input
          v-model="automationPassword"
          type="password"
          class="w-full px-4 py-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          placeholder="••••••••"
          required
        />
      </div>
      <div class="flex justify-center w-full">
        <button
          type="submit"
          class="submit-button text-white font-bold py-2 px-8 rounded-full w-[60%] max-w-md hover:from-blue-600 hover:to-blue-800"
        >
          {{ $t('accounts.submit') }}
        </button>
      </div>
    </form>
  </div>
</div>

<div v-if="showPasswordModal" class="modal-overlay flex items-center justify-center min-h-screen bg-gray-900 bg-opacity-50">
      <div class="modal bg-white rounded-lg p-6 shadow-lg max-w-lg relative" :class="{ 'w-full': !isMobile }">
        <button 
          @click="closeModal"
          class="absolute top-4 right-4 text-gray-400 hover:text-gray-600 focus:outline-none"
        >
          <i class="fas fa-times text-xl"></i>
        </button>
        <div class="flex items-center justify-center mb-6 mt-10">
          <img src="../assets/password.png" alt="Icon" class=" h-24" />
        </div>
        <h2 class="text-center text-xl modal-title font-semibold mb-4">
          {{ $t('accounts.modaltitle') }}
        </h2>
        <form @submit.prevent="submitRequest">
          <label for="reason" class="block text-sm modal-label mb-2">{{ $t('accounts.modalreasonLabel') }}</label>
          <textarea 
            id="reason"
            v-model="requestReason"
            rows="4"
            class="w-full border border-gray-300 rounded-lg p-2"
            placeholder="Enter your reason here..."
          ></textarea>
          <div class="flex justify-center mt-6">
            <button
              type="submit"
              class="submit-button text-white font-bold py-2 px-8 rounded-full w-[60%] max-w-md hover:from-blue-600 hover:to-blue-800"
            >
            {{ $t('accounts.submit') }}
            </button>
          </div>
        </form>
      </div>
    </div>
    <div v-if="showProxyModal" class="modal-overlay flex items-center justify-center min-h-screen bg-gray-900 bg-opacity-50">
      <div class="modal bg-white rounded-lg p-6 shadow-lg max-w-lg relative" :class="{ 'w-full': !isMobile }">
        <button 
        @click="() => { showProxyModal = false ;}"
          class="absolute top-4 right-4 text-gray-400 hover:text-gray-600 focus:outline-none"
        >
          <i class="fas fa-times text-xl"></i>
        </button>
        <div class="flex items-center justify-center mb-6 mt-10">
          <img src="../assets/proxy.png" alt="Icon" class=" h-24" />
        </div>
        <h2 class="text-center text-xl modal-title mb-4">
          {{ $t('accounts.proxyDetails') }}
        </h2>
        <p class="modal-desc text-center mb-4"> {{ $t('accounts.proxyTextInfo') }}</p>
        <div class="text-info-modal">
          <p class="border-b border-gray-200 py-3 last:border-0 modal-list">
            Protocol
            <span>{{ JSON.parse(selectedAccount.Type_proxy__mode_)[0] }}</span>
          </p>
          <p class="border-b border-gray-200 py-3 last:border-0 modal-list">
            Host
            <span style="color: #3153C7;">{{ JSON.parse(selectedAccount.Adresse_IP)[0]}}<button
              class="copy-btn"
              @click="copyToClipboard(JSON.parse(selectedAccount.Adresse_IP)[0])"
            >
            <svg class="icon-copy" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                <defs>
                  <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                    <stop offset="0%" stop-color="#3154C8" />
                    <stop offset="100%" stop-color="#1E93FD" />
                  </linearGradient>
                </defs>
                <rect x="9" y="9" width="13" height="13" rx="2" ry="2" stroke="url(#gradient)"></rect>
                <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1" stroke="url(#gradient)"></path>
              </svg>
            </button></span>
          </p>
          <p class="border-b border-gray-200 py-3 last:border-0 modal-list">
            Port
            <span>{{ JSON.parse(selectedAccount.Port)[0]}}<button
              class="copy-btn"
              @click="copyToClipboard(JSON.parse(selectedAccount.Port)[0])"
            >
              <svg class="icon-copy" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                <defs>
                  <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                    <stop offset="0%" stop-color="#3154C8" />
                    <stop offset="100%" stop-color="#1E93FD" />
                  </linearGradient>
                </defs>
                <rect x="9" y="9" width="13" height="13" rx="2" ry="2" stroke="url(#gradient)"></rect>
                <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1" stroke="url(#gradient)"></path>
              </svg>
            </button></span>
          </p>
          <p class="border-b border-gray-200 py-3 last:border-0 modal-list">
            Login
            <span>{{ JSON.parse(selectedAccount.Login)[0]}}<button
              class="copy-btn"
              @click="copyToClipboard(JSON.parse(selectedAccount.Login)[0])"
            >
            <svg class="icon-copy" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                <defs>
                  <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                    <stop offset="0%" stop-color="#3154C8" />
                    <stop offset="100%" stop-color="#1E93FD" />
                  </linearGradient>
                </defs>
                <rect x="9" y="9" width="13" height="13" rx="2" ry="2" stroke="url(#gradient)"></rect>
                <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1" stroke="url(#gradient)"></path>
              </svg>
            </button></span>
          </p>
          <p class="border-b border-gray-200 py-3 last:border-0 modal-list">
            Pass
            <span>{{JSON.parse(selectedAccount.Pass)[0]}}<button
              class="copy-btn"
              @click="copyToClipboard(JSON.parse(selectedAccount.Pass)[0])"
            >
              <svg class="icon-copy" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                <defs>
                  <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                    <stop offset="0%" stop-color="#3154C8" />
                    <stop offset="100%" stop-color="#1E93FD" />
                  </linearGradient>
                </defs>
                <rect x="9" y="9" width="13" height="13" rx="2" ry="2" stroke="url(#gradient)"></rect>
                <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1" stroke="url(#gradient)"></path>
              </svg>
            </button></span>
          </p>
          <p class="border-b border-gray-200 py-3 last:border-0 modal-list">
            Login:Pass@Host:port
            <span class="text-ellipsis">{{JSON.parse(selectedAccount.Type_proxy__mode_)[0]+ "://"+ JSON.parse(selectedAccount.Login)[0]+":"+JSON.parse(selectedAccount.Pass)[0]+"@"+JSON.parse(selectedAccount.Adresse_IP)[0]+":"+JSON.parse(selectedAccount.Port)[0]}}</span>

            <button
              class="copy-btn"
              @click="copyToClipboard(JSON.parse(selectedAccount.Type_proxy__mode_)[0]+ '://'+ JSON.parse(selectedAccount.Login)[0]+':'+JSON.parse(selectedAccount.Pass)[0]+'@'+JSON.parse(selectedAccount.Adresse_IP)[0]+':'+JSON.parse(selectedAccount.Port)[0])"
            >
              <svg class="icon-copy" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                <defs>
                  <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                    <stop offset="0%" stop-color="#3154C8" />
                    <stop offset="100%" stop-color="#1E93FD" />
                  </linearGradient>
                </defs>
                <rect x="9" y="9" width="13" height="13" rx="2" ry="2" stroke="url(#gradient)"></rect>
                <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1" stroke="url(#gradient)"></path>
              </svg>
            </button>
          </p>
        </div>
      </div>
    </div>
    <div 
      v-if="showToast" 
      :class="[
        'fixed top-5 left-1/2 transform -translate-x-1/2 px-6 py-3 text-white font-semibold rounded-lg shadow-lg transition-opacity duration-300',
        toastType === 'success' ? 'bg-green-500' : 'bg-red-500'
      ]"
      style="z-index: 9999;"
    >
      <i 
        :class="toastType === 'success' ? 'fas fa-check-circle mr-2' : 'fas fa-exclamation-circle mr-2'"
      ></i>
      {{ toastMessage }}
    </div>



</template>
  
  <script>
  import api from '../plugins/axios';
  import euFlag from '../assets/EU_logo.png';
  import usFlag from '../assets/US_logo.png';
 
  
  export default {
    name: 'AccountsView',
    data() {
      return {
        activeAccount: null,
        accounts: [],
        isMobile: false,
        selectedAccount: null,
        email: localStorage.getItem('email') || 'Unknown',
        nom: localStorage.getItem('nom') || 'Unknown',
        prenom: localStorage.getItem('prenom') || 'Unknown',
        langue: localStorage.getItem('langue') || 'Unknown',
        subscriptions: JSON.parse(localStorage.getItem('subscriptions')) || [],
        isLoading: true, 
        otp: null,
        proxyTestResult: null,
        proxydata: 0,
        europeCountries: [
          'FR', 'DE', 'IT', 'ES', 'NL', 'BE', 'LU', 'PT', 'AT', 'DK', 'FI', 'SE',
          'NO', 'PL', 'CZ', 'SK', 'HU', 'RO', 'BG', 'GR', 'IE', 'HR', 'SI', 'EE',
          'LV', 'LT', 'MT', 'CY', 'GB', 'France'
        ],
        automationTools: [
          'Aucun', 'Waalaxy', 'Dripify', 'HeyReach', 'Ulinc', 'Valley',
          'SalesFlow', 'MeetAlfred', 'Sales Mind AI', 'Lemlist', 'LGM', 'Anybiz',
          'Kanbox', 'Buzz', 'Expandi'
        ],
        showAutomationModal: false,
        automationTool: '',
        automationEmail: '',
        automationPassword: '',
        showProblemModal: false,
        problemTypes: [
          { id: "Compte plus accessible", label: this.$t("problemTypes.accountInaccessible") },
          { id: "Problème GoLogin", label: this.$t("problemTypes.gologinIssue") },
          { id: "Déconnexion du compte", label: this.$t("problemTypes.accountDisconnection") },
          { id: "Email non accessible sur Linkedin", label: this.$t("problemTypes.linkedinEmailIssue") },
          { id: "Autres", label: this.$t("problemTypes.other") },
        ],
        problemType: "",
        problemDescription: "",
        linksSent: null, 
        connectionRequests: 0,
        profileVisits: 0,
        autoProfileVisits: null, 
        searchQuery: '',
        selectedFilter: '',
        sortOrder: 'asc',
        selectedLocationFilter: '',
        selectedLocationFilterVal: '',
        selectedGenderFilter: '',
        selectedGenderFilterVal: '',
        showLocationFilterDropdown: false,
        showGenderFilterDropdown: false,
        locationFilterOptions: [
          { label: this.$t('accounts.allLocations'), value: '', values: ['FR', 'DE', 'IT', 'ES', 'NL', 'BE', 'LU', 'PT', 'AT', 'DK', 'FI', 'SE',
              'NO', 'PL', 'CZ', 'SK', 'HU', 'RO', 'BG', 'GR', 'IE', 'HR', 'SI', 'EE',
              'LV', 'LT', 'MT', 'CY', 'GB', 'France','US', 'USA', 'Singapore'] }, 
          { 
            label: this.$t('accounts.europe'), 
            value: 'europe', 
            values: [
              'FR', 'DE', 'IT', 'ES', 'NL', 'BE', 'LU', 'PT', 'AT', 'DK', 'FI', 'SE',
              'NO', 'PL', 'CZ', 'SK', 'HU', 'RO', 'BG', 'GR', 'IE', 'HR', 'SI', 'EE',
              'LV', 'LT', 'MT', 'CY', 'GB', 'France'
            ],
          },
          { 
            label: this.$t('accounts.usa'), 
            value: 'usa', 
            values: ['US', 'USA', 'Singapore'] 
          },
        ],
        genderFilterOptions: [
          { label: this.$t('accounts.allGenders'), value: '', values: ['Homme', 'Male', 'Femme', 'Female', null] },
          { label: this.$t('accounts.male'), value: 'male', values: ['Homme', 'Male'] },
          { label: this.$t('accounts.female'), value: 'female', values: ['Femme', 'Female'] },
        ],
        statusFilterOptions: [
          { label: this.$t('accounts.allGenders'), value: '' },
          { label: this.$t('accounts.strike'), value: 'Strike' },
          { label: this.$t('accounts.salesNav'), value: 'SalesNav' },
          { label: this.$t('accounts.pending'), value: 'En attente' },
        ],
        selectedStatusFilter: '',
        showStatusFilterDropdown: false,

        filterActivate: false,
        showRemoveSubModal: false,
        showCancelRemoveSubModal: false,
        renewalDate: localStorage.getItem('date_renew'|| 'Unknown'),
        removeOption: 'now',
        password: '', 
        passwordVisible: false, 
        showPasswordModal: false, 
        requestReason: '',
        period: 0,
        intervalId: null,
        toastMessage: '',
        showToast: false,
        maxRetries: 4,
        retries: 0,
        showProxyModal: false,
        flags: {
          eu: euFlag,
          us: usFlag,
        },
        removeSubSure: false,
        showAutomationModalSuccess: false,
        toastType: 'success',
        showModalSuccess: false,
      };
    },
    computed: {
      isMailValid() {
        return (
          this.selectedAccount &&
          this.selectedAccount.email &&
          (this.selectedAccount.email.endsWith('@gmail.com') ||
            this.selectedAccount.email.endsWith('@laposte.net'))
        );
      },
      totalAccounts() {
      return this.subscriptions.reduce((sum, sub) => sum + sub.quantity, 0);
      },
      europeanAccounts() {
        return this.subscriptions
          .filter((sub) => sub.currency === 'eur')
          .reduce((sum, sub) => sum + sub.quantity, 0);
      },
      usAccounts() {
        return this.subscriptions
          .filter((sub) => sub.currency === 'usd')
          .reduce((sum, sub) => sum + sub.quantity, 0);
      },
      totalRequiredAccounts() {
        return this.subscriptions.reduce((sum, sub) => sum + sub.quantity, 0);
      },
      pendingAccountsCount() {
        return Math.max(this.totalRequiredAccounts - this.accounts.length, 0);
      },
      filteredAccounts() {
        let accounts = this.displayedAccounts;

        if (this.searchQuery) {
          accounts = accounts.filter((account) =>
            account.compte?.toLowerCase().includes(this.searchQuery.toLowerCase())
          );
        }

        if (this.selectedGenderFilterVal) {
          accounts = accounts.filter((account) =>
            this.selectedGenderFilterVal.includes(account.sexe)
          );
        }
        if (this.selectedGenderFilterVal === "" || this.selectedGenderFilterVal.includes(null)) {
          accounts = this.displayedAccounts;
        }        
        if (this.selectedLocationFilterVal) {
          accounts = accounts.filter((account) =>
          this.selectedLocationFilterVal.includes(account.pays)
          );
        }
        

        if (this.selectedStatusFilter) {
          accounts = accounts.filter((account) => {
            if (this.selectedStatusFilter === 'Strike') {
              return account.etats === 'strike';
            } else if (this.selectedStatusFilter === 'SalesNav') {
              return account.SalesNav !== null && account.SalesNav !== undefined;
            } else if (this.selectedStatusFilter === 'En attente') {
              return account.isPending === true;
            }
            return true; 
          });
        }

        accounts = accounts.sort((a, b) => {
          if (this.sortOrder === 'asc') {
            return a.compte?.localeCompare(b.compte || '') || 0;
          } else {
            return b.compte?.localeCompare(a.compte || '') || 0;
          }
        });

        return accounts;
      },
      isDescriptionRequired() {
        return (
          this.problemType === "Problème GoLogin" ||
          this.problemType === "Autres"
        );
      },
    },
    methods: {

      handleRemoveButtonClick() {
        if (this.selectedAccount && this.selectedAccount.resiliationDate) {
          this.showCancelRemoveSubModal = true;
        } else {
          this.showRemoveSubModal = true;
        }
      },
      truncateText(text, maxLength) {
        if (!text) return '';
        return text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
      },
      formatDateConditionally(dateString) {
        if (!dateString) return '';
        const langue = localStorage.getItem('langue');
        
        if (langue && langue.startsWith('fr')) {
          const [year, month, day] = dateString.split('-');
          return `${day}/${month}/${year}`;
        }

        return dateString;
      },
      showToastMessage(message) {
        this.toastMessage = message;
        this.showToast = true;

        setTimeout(() => {
          this.showToast = false;
          this.toastMessage = '';
        }, 3000);
      },
      copyToClipboard(text) {
          if (navigator.clipboard && navigator.clipboard.writeText) {
            navigator.clipboard.writeText(text)
              .then(() => {
                this.toastType = "success";
                
                this.showToastMessage(this.$t('accounts.copy_success'));
              })
              .catch(() => {
                this.toastType = "error";
                this.showToastMessage(this.$t('accounts.copy_error'));
              });
          } else {
            this.toastType = "error";
            this.showToastMessage(this.$t('accounts.copy_unsupported'));
          }
        },
        startTimer() {
          if (this.intervalId === null) {
            this.intervalId = setInterval(() => {
              if (this.period > 0) {
                this.period -= 1;
              } else {
                this.stopTimer();
                this.handleTimerEnd();
              }
            }, 1000);
          }
        },
        stopTimer() {
          clearInterval(this.intervalId);
          this.intervalId = null;
        },
        async handleTimerEnd() {
          if (this.retries < this.maxRetries) {
            this.retries += 1;
            await this.fetchOTP();
          } else {
            this.retries = 0;
          }
        },
      togglePasswordVisibility() {
        this.passwordVisible = !this.passwordVisible;
      },
      async fetchPassword() {
        try {
          const response = await api.get('/api/getpassword', {
            params: { email: this.selectedAccount.email },
          });

          const { status, password } = response.data;
          console.log(password);
          if (status === true) {
            this.password = password; 
            this.passwordVisible = true; 
          } else {
            this.showPasswordModal = true; 
          }
        } catch (error) {
          console.error('Erreur lors de la récupération du mot de passe:', error);
        }
      },
      closeModal() {
        this.showPasswordModal = false;
        this.requestReason = '';
      },
      async submitRequest() {
        try {
          this.isLoading = true;
          const response = await api.post('/api/intercom/RequestPassword', {
            description: this.requestReason,
            emailClients: this.email,
          });

          if (response.status === 200) {
            this.toastType = "success";
            this.isLoading = false;
            this.showModalSuccess = true;
            this.showToastMessage('Votre demande a été envoyée avec succès.');
            this.closeModal(); 
          }
        } catch (error) {
          this.toastType = "error";
          console.error('Erreur lors de l\'envoi de la demande:', error);
          this.showToastMessage('Une erreur est survenue. Veuillez réessayer.');
        }
      },

      closeDetails() {
        this.selectedAccount = null;
        this.activeAccount = null;
        this.problemType = null;
      },
      updateIsMobile() {
        this.isMobile = window.innerWidth <= 768; 
      },
      formatDate(date) {
        if (!date) return '';
        const parsedDate = new Date(date);

        const isMediumScreen =
          window.innerWidth >= 768 && window.innerWidth <= 1279;

        if (isMediumScreen) {
          return parsedDate.toLocaleDateString('fr-FR', {
            year: '2-digit',
            month: '2-digit',
            day: '2-digit',
          });
        }

        return parsedDate.toLocaleDateString('fr-FR', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        });
      },
      removeSubscriptionBeSure(){
        this.removeSubSure = true;
      },
      removeSubscription() {
        this.isLoading = true;
        const mode = this.removeOption === 'now' ? 'now' : 'cancel-at-period-end';
        console.log("mode =====");
        console.log(mode);
        api.post('/api/subscription/decrement-or-cancel', {
          email: this.selectedAccount.email,
          mode,
        })
          .then(response => {
            console.log(`Succès lors de la suppression (${mode}) :`, response.data);

            if (mode === 'now') {
              localStorage.setItem('subscriptions', JSON.stringify(response.data.subscriptions));
              this.subscriptions = JSON.parse(response.data.subscriptions);
              this.toastType = "success";
              this.showModalSuccess = true
              this.showToastMessage(this.$t('accounts.subscriptionRemovedNow'));
            } else if (mode === 'cancel-at-period-end') {
              const { resiliationDate } = response.data;
              this.toastType = "success";
              this.showModalSuccess = true
              this.showToastMessage(`${this.$t('accounts.subscriptionRemovedEnd')} ${this.$t('accounts.resiliationDate')}: ${resiliationDate}`);
            }
          })
          .catch(error => {
            this.toastType = "error";
            console.error(`Erreur lors de la suppression (${mode}) :`, error.response?.data || error.message);
          })
          .finally(() => {
            this.isLoading = false;
            window.location.reload()
          });
      },
      cancelRemoveSubscription(){
        this.isLoading = true;
        api.post('/api/subscription/decrement-cancel', {
          email: this.selectedAccount.email,
        })
          .then(response => {
            console.log(response.data);
            this.toastType = "success";
            this.showModalSuccess = true
            this.showToastMessage(`${this.$t('accounts.subscriptionCancelRemovedEnd')}`);
          })
          .catch(error => {
            this.toastType = "error";
            console.log(error);
            this.showToastMessage(`${this.$t('accounts.subscriptionCancelRemovedEndError')} `);
          })
          .finally(() => {
            this.isLoading = false;
            window.location.reload()
          });
      },
      replaceAccount(account) {
        this.problemType = "Compte strike";
        this.selectedAccount = account; 
        console.log(this.selectedAccount);
        this.showProblemModal = true;
      },
      toggleLocationFilterDropdown() {
        this.showLocationFilterDropdown = !this.showLocationFilterDropdown;
      },
      applyLocationFilter(filter) {
        this.showLocationFilterDropdown = false;
        const selectedOption = this.locationFilterOptions.find(option => option.value === filter);
        this.selectedLocationFilter = selectedOption.label;
        this.selectedLocationFilterVal = selectedOption && selectedOption.values ? selectedOption.values : [];
        this.filterActivate = !!filter || !!this.searchQuery || !!this.selectedGenderFilter || !this.selectedStatusFilter;
        this.prepareDisplayedAccounts();
      },
      toggleGenderFilterDropdown() {
        this.showGenderFilterDropdown = !this.showGenderFilterDropdown;
      },
      applyGenderFilter(filter) {
        this.showGenderFilterDropdown = false;
        const selectedOption = this.genderFilterOptions.find(option => option.value === filter);
        this.selectedGenderFilter = selectedOption.label;
        this.selectedGenderFilterVal = selectedOption && selectedOption.values ? selectedOption.values : [];
        this.filterActivate = !!filter || !!this.searchQuery || !!this.selectedLocationFilter || !this.selectedStatusFilter;
        this.prepareDisplayedAccounts();
      },
      toggleSortOrder() {
        this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
      },
      toggleStatusFilterDropdown() {
        this.showStatusFilterDropdown = !this.showStatusFilterDropdown;
      },
      applyStatusFilter(filter) {
        this.selectedStatusFilter = filter;
        this.showStatusFilterDropdown = false;
        this.filterActivate = !!filter || !!this.searchQuery || !!this.selectedGenderFilter || !!this.selectedLocationFilter;
        this.prepareDisplayedAccounts();
      },
      prepareDisplayedAccounts() {
        const preparingAccounts = this.accounts.filter((account) => account.prepare === 1);
        let accounts = this.accounts.filter((account) => account.prepare !== 1);

        if (this.searchQuery) {
          accounts = accounts.filter((account) =>
            account.compte.toLowerCase().includes(this.searchQuery.toLowerCase())
          );
        }

        if (this.selectedFilter) {
          accounts = accounts.filter((account) => {
            if (['FR', 'US', ...this.europeCountries].includes(this.selectedFilter)) {
              return account.pays === this.selectedFilter;
            } else if (['Homme', 'Femme'].includes(this.selectedFilter)) {
              return account.sexe === this.selectedFilter;
            }
            return true;
          });
        }

        accounts = accounts.sort((a, b) => {
          if (this.sortOrder === 'asc') {
            return a.compte.localeCompare(b.compte);
          } else {
            return b.compte.localeCompare(a.compte);
          }
        });

        const totalEurSlots = this.subscriptions
          .filter((sub) => sub.currency === 'eur')
          .reduce((sum, sub) => sum + sub.quantity, 0);

        const totalUsSlots = this.subscriptions
          .filter((sub) => sub.currency === 'usd')
          .reduce((sum, sub) => sum + sub.quantity, 0);

        const totalSlots = totalEurSlots + totalUsSlots;

        const europeanAccountsCount = accounts.filter((acc) =>
          acc.pays !== 'US' && acc.pays !== 'USA'
        ).length;

        const usAccountsCount = accounts.filter((acc) =>
          acc.pays === 'US' || acc.pays === 'USA'
        ).length;

        const hasMultipleNationalities = totalEurSlots > 0 && totalUsSlots > 0;

        let pendingEuropeanAccounts = [];
        let pendingUsAccounts = [];

        if (!hasMultipleNationalities) {
          const existingAccountsCount = europeanAccountsCount + usAccountsCount;
          if (existingAccountsCount < totalSlots) {
            const missingSlots = totalSlots - existingAccountsCount;
            const defaultCountry = totalEurSlots > 0 ? 'FR' : 'US';

            pendingEuropeanAccounts =
              defaultCountry === 'FR'
                ? Array.from({ length: missingSlots }, () => ({
                    isPending: true,
                    pays: 'FR',
                  }))
                : [];

            pendingUsAccounts =
              defaultCountry === 'US'
                ? Array.from({ length: missingSlots }, () => ({
                    isPending: true,
                    pays: 'US',
                  }))
                : [];
          }
        } else {
          if (europeanAccountsCount < totalEurSlots) {
            pendingEuropeanAccounts = Array.from(
              { length: totalEurSlots - europeanAccountsCount },
              () => ({
                isPending: true,
                pays: 'FR', 
              })
            );
          }

          if (usAccountsCount < totalUsSlots) {
            pendingUsAccounts = Array.from({ length: totalUsSlots - usAccountsCount }, () => ({
              isPending: true,
              pays: 'US',
            }));
          }
        }

        preparingAccounts.forEach((preparingAccount) => {
          if (
            this.europeCountries.includes(preparingAccount.pays) &&
            pendingEuropeanAccounts.length > 0
          ) {
            pendingEuropeanAccounts.shift();
          } else if (preparingAccount.pays === 'US' && pendingUsAccounts.length > 0) {
            pendingUsAccounts.shift();
          }
        });

        this.displayedAccounts = [
          ...accounts,
          ...preparingAccounts.map((account) => ({
            ...account,
            isPreparing: true,
          })),
          ...pendingEuropeanAccounts,
          ...pendingUsAccounts,
        ];

        console.log(this.displayedAccounts);
      },



      async submitAutomationForm() {
        this.isLoading = true;
        try {
          const response = await api.post('/api/webhook/automation', {
            Automation_tool: this.automationTool,
            Email_automation_tool: this.automationEmail,
            Email_compte: this.selectedAccount.email,
            Password: this.automationPassword,
          });
          console.log('Automation tool added successfully:', response.data);
          this.toastType = "success";
          this.showToastMessage(this.$t('accounts.automationSuccess'));
          this.showAutomationModal = false;
          this.showAutomationModalSuccess = true;
          
        } catch (error) {
          console.error('Error adding automation tool:', error);
          this.toastType = "error";
          this.showToastMessage(this.$t('accounts.automationError'));
        }
        this.isLoading = false;
      },
    getNationality(countryCode) {
      return this.europeCountries.includes(countryCode) ? this.flags.eu : this.flags.us;
    },
    getPendingFlag(pays) {
      return pays === 'FR' ? this.flags.eu : this.flags.us;
    },
    async showAccountDetails(accountId) {
      try {
        if (this.selectedAccount && this.selectedAccount.email === accountId) {
          this.selectedAccount = null;
          this.activeAccount = null;
          return;
        }
        this.otp = null
        this.proxyTestResult = null
        
        const response = await api.get(`/api/comptes/${accountId}`);
        this.selectedAccount = response.data;
        this.activeAccount = response.data.compte;
        console.log(this.selectedAccount);
      } catch (error) {
        console.error('Error fetching account details:', error);
      }
    },

    async testProxy() {
      try {
        this.isLoading = true;
        if (!this.selectedAccount || !this.selectedAccount.email) {
          console.error('Aucun compte sélectionné ou ID introuvable.');
          this.proxyTestResult = 'Erreur : ID de compte introuvable';
          return;
        }

        const response = await api.get(`/api/comptes/${this.selectedAccount.email}/proxy/test`);

        if (response.data && response.data.status === 'success') {
          this.proxyTestResult = `${response.data.ms}`;
          this.proxydata = response.data.ms;

          if (this.proxydata > 300) {
            this.toastType = "success";
            this.isLoading = false;
            this.showModalSuccess = true;
            this.showToastMessage(
              this.$t('accounts.proxySlowWarning')
            );
          }
        } else {
          console.error('Erreur dans la réponse du test proxy:', response);
          this.proxyTestResult = this.$t('accounts.proxyError');
        }
        this.isLoading = false;
      } catch (error) {
        console.error('Erreur lors du test du proxy:', error);
        this.proxyTestResult = this.$t('accounts.proxyError');
      }

  },
  async submitProblemForm() {
    this.isLoading = true;
    if (!this.problemType) {
      this.toastType = "error";
      return this.showToastMessage(this.$t('accounts.selectProblemType'));
    }

    if (this.isDescriptionRequired && !this.problemDescription) {
      this.toastType = "error";
      return this.showToastMessage(this.$t('accounts.fillDescriptionField'));
    }

    const requestData = {
      problem_type: this.problemType,
      description: this.problemDescription,
      emailClients: localStorage.getItem('email'), 
      emailCompte: this.selectedAccount.email, 
    };
    console.log(requestData);
    
    if (this.problemType === 'Email non accessible sur Linkedin') {
      try {
        const requestData2 = { email: this.selectedAccount.email };
        const response = await api.post('/api/mail/problem', requestData2);     
        this.toastType = "success";     
        this.showToastMessage(this.$t('accounts.ticketSuccess'));
        console.log('Réponse du serveur:', response.data);
        this.showProblemModal = false;
        this.showModalSuccess = true;
        this.resetForm();
      } catch (error) {
        this.toastType = "error";
        console.error('Erreur lors de la soumission du formulaire:', error.response?.data || error.message);
        this.showToastMessage(this.$t('accounts.ticketError'));
      }
      this.isLoading = false;
    } else {
      try {
        if(this.problemType === 'Compte strike'){
          requestData.links_sent = this.linksSent;
          requestData.connection_requests = this.connectionRequests;
          requestData.profile_visits = this.profileVisits;
          requestData.automation_tool = this.automationTool;
          requestData.auto_profile_visits = this.automationTool && this.automationTool !== "Aucun" ? true : false;
          const response = await api.post('/api/replace/comptestrike', requestData);
          this.toastType = "success";
          this.showModalSuccess = true;
          
          this.showToastMessage(this.$t('accounts.ticketSuccess'));
          console.log('Réponse du serveur:', response.data);

          this.resetForm();
          location.reload();
        }else{
          const response = await api.post('/api/intercom/problem', requestData);
          this.toastType = "success";
          this.showModalSuccess = true;
          
          this.showToastMessage(this.$t('accounts.ticketSuccess'));
          console.log('Réponse du serveur:', response.data);

          this.resetForm();
        }
        
      } catch (error) {
        this.toastType = "error";
        console.error('Erreur lors de la soumission du formulaire:', error.response?.data || error.message);
        this.showToastMessage(this.$t('accounts.ticketError'));
      }
      this.isLoading = false;
    }
  },
    resetForm() {
      this.problemType = '';
      this.problemDescription = '';
      this.linksSent = null;
      this.connectionRequests = null;
      this.profileVisits = null;
      this.automationTool = '';
      this.showProblemModal = false;
      this.selectedAccount = null;
      this.activeAccount = null;
    },
    async fetchOTP() {
    try {
      this.isLoading = true;
      if (!this.selectedAccount || !this.selectedAccount.email) {
        console.error('Aucun compte sélectionné ou email introuvable.');
        this.otp = 'Erreur : Email introuvable';
        this.isLoading = false;
        return;
      }

      const email = this.selectedAccount.email;

      if (email.endsWith('@gmail.com')) {
        const response = await api.post('/api/twofa/register', { email });

        if (response.status === 200 && response.data.otp) {
          this.otp = response.data.otp;
          this.period = response.data.period;
          this.startTimer();
        } else {
          console.error('Erreur dans la réponse OTP pour Gmail:', response);
          this.otp = 'Erreur lors de la récupération';
        }
      } 
      else if (email.endsWith('@laposte.net')) {
        const webhookURL = `https://n8n.stepward.com/webhook/internal_2FA_stepward?email=${encodeURIComponent(email)}`;

        const response = await fetch(webhookURL);
        console.log(response);
        if (response.ok) {
          const data = await response.json();
          if (data.otp) {
            this.otp = data.otp;
            this.period = 15; 
            this.startTimer();
          } else {
            console.error('Réponse inattendue du webhook Laposte:', data);
            this.otp = 'Erreur lors de la récupération';
          }
        } else {
          console.error('Erreur lors de l\'appel au webhook Laposte:', response.statusText);
          this.otp = "Pas de code 2FA dans le dernier mail";
        }
      } 
      else {
        console.error('Email invalide. Seuls les emails @gmail.com et @laposte.net sont autorisés.');
        this.otp = 'Erreur : Email non valide';
      }
      this.isLoading = false;
    } catch (error) {
      console.error('Erreur lors de la récupération de l\'OTP:', error);
      this.otp = 'Erreur lors de la récupération';
      this.isLoading = false;
    }
  },
  },
  
    async mounted() {
      try {
        const response = await api.get('/api/comptes');
        this.accounts = response.data;
        this.isLoading = false; 
        console.log("this.accounts");
        console.log(this.accounts);
      } catch (error) {
        console.error('Error fetching accounts:', error);
      }
     
      this.updateIsMobile();
      window.addEventListener("resize", this.updateIsMobile);
      localStorage.setItem('paymentDataFulltempo', null);
      localStorage.setItem('paymentData', null);
      localStorage.setItem('numberAccount', null);
    },
    watch: {
      searchQuery(newQuery) {
        this.filterActivate = !!newQuery || !!this.selectedFilter;
        this.prepareDisplayedAccounts();
      },
      selectedFilter() {
        this.filterActivate = !!this.searchQuery || !!this.selectedFilter;
        this.prepareDisplayedAccounts();
      },
      sortOrder() {
        this.prepareDisplayedAccounts();
      },
      accounts: 'prepareDisplayedAccounts', 
      subscriptions: 'prepareDisplayedAccounts',
    },
  };
  
  </script>
  <style>

  
.margin-top-30{
  margin-top: 30px;
}

.mt-8 {
  margin-top: 2rem;
}

.grid-container {
    display: grid;
  grid-template-columns: 1fr minmax(0, 1fr); 
  grid-gap: 16px;
}

.header {
  grid-column: 1 / -1;
}

.test-anime {
  grid-column: 1 / -1;
  grid-row: 2; 
  transition: all 0.5s ease-in-out, opacity 0.5s ease-in-out;
  display: flex;
  flex-direction: column;
  height: 75vh;
  overflow-y: auto;
  opacity: 1; 
  visibility: visible; 
  border-radius:12px;
}


.test-anime.full-size {
  transform: translateX(0); 
  width: 100%; 
}

.test-anime.reduced-size {
  transform: translateX(0); 
  width: 49%;
}

.sticky-header {
  position: sticky;
  top: -1.5rem;
  left: 0;
  right: 0;
  z-index: 10;
  margin: -1.5rem -1.5rem 1.5rem -1.5rem;
  background-color: white;
  padding: 1.5rem;
}



.details-panel {
  position: fixed;
  top: 20.6%;
  right: 1.3%;
  width: 38%;
  height: 75%;
  padding: 20px;
  border-radius: 12px;
  background: #ffffff;
  color: #2c2c2c;
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 140%;
  overflow: auto;
  z-index: 50;
  box-shadow: 0px 10px 40px rgba(30, 147, 253, 0.2);
}

.details-panel.mobile-fullscreen {
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 0;
  padding: 20px;
}


.details-panel.activated {
  position: static; 
  grid-column: 2; 
  grid-row: 2; 
  width: 100%; 
  height: 75vh; 

}

.back-button {
  z-index: 100;
}

@media (max-width: 768px) {
  .details-panel {
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    border-radius: 0;
    padding: 16px;
  }
}



.slide-in-enter-active {
  transition: transform 0.5s ease-in-out;
}
.slide-in-enter-from {
  transform: translateX(100%);
}
.slide-in-enter-to {
  transform: translateX(0);
}

/* Animation à la sortie */
.slide-in-leave-active {
  transition: transform 0.5s ease-in-out;
}
.slide-in-leave-from {
  transform: translateX(0);
}
.slide-in-leave-to {
  transform: translateX(100%);
}




  .details-panel h2 {
    font-size: 18px;
    font-weight: 700;
    color: #2C2C2C;
    margin-bottom: 20px;
  }


.text-info p {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}



.text-info div {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.text-info div strong {
  font-size: 14px;
  font-weight: 400;
  color: #000;
  line-height: normal;
}

.text-info p strong {
  font-size: 14px;
  font-weight: 400;
  color: #000;
  line-height: normal;
}

.text-info p span,
.text-info p a {
  font-size: 14px;
  font-weight: 700;
  color: #000;
  text-align: right;
  line-height: normal;
}


.mt-6 h3 {
  font-size: 16px;
  font-weight: 700;
  color: #2C2C2C;
  margin-bottom: 15px;
}

.action-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 18px;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  color: #1E93FD;
  background: #FFFFFF;
  border-radius: 4px;
  border: 1px solid var(--gradient, #1E93FD);
  transition: background-color 0.2s ease-in-out;
  cursor: pointer;
}

.action-button:hover {
  background-color: #F2F9FF;
}

.remove-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #E53935;
  border: 1px solid #E53935;
  border-radius: 50px;
  margin-top: 20px;
  transition: background-color 0.2s ease-in-out;
}

.remove-button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.remove-button:hover {
  background-color: #F9C2C2;
}

.action-button i {
  margin-left: 8px; 
}


.remove-button:hover {
  background-color: #f9c2c2; 
}

.overlay-loader {
  position: absolute; 
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; 
}

.loader {
  color: #1E93FD;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gradient-bar {
  fill: var(--gradient, #3154C8);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.action-button:disabled {
  background-color: #d3d3d3; 
  color: #9e9e9e; 
  cursor: not-allowed; 
  border: 1px solid #d3d3d3;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 50;
}

.modal {
  width: 90%;
  max-width: 500px;
}

.btn-linkedin {
  color: #FFF; 
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  border-radius: 4px;
  background: linear-gradient(90deg, #1E93FD 0%, #3154C8 100%);
  padding: 4px 10px;
  justify-content: right;
  align-items: center;
  gap: 10px;

  text-decoration: none;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.btn-linkedin:hover {
  background: linear-gradient(90deg, #1C86E5 0%, #2B4AAF 100%);
  transform: scale(1.05);
  cursor: pointer;
}




.launch-button {
  margin-right: 10px;
  border: 1px solid var(--gradient, #1E93FD);
  font-family: Montserrat, sans-serif;
  font-style: normal;
  text-transform: uppercase; 
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  color: transparent;
}

.bg-gradient-text {
  background: var(--gradient, linear-gradient(90deg, #1E93FD 0%, #3154C8 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; 
}

.launch-button:hover {
  background: var(--gradient, linear-gradient(90deg, #3154C8 0%, #1E93FD 100%)); 
  color: #ffffff; 
}

.launch-button:hover span {
  color: #ffffff !important; 
  -webkit-text-fill-color: #ffffff !important;
}

.launch-button:hover i {
  color: #ffffff; 
  -webkit-text-fill-color: #ffffff;
}

.launch-button i, .launch-button span {
  background: var(--gradient, linear-gradient(90deg, #1E93FD 0%, #3154C8 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; 
  color: transparent;
}

.replace-button {
  margin-right: 10px;
  border: 1px solid #FA5D72;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  text-transform: uppercase; 
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  color: transparent;
}




.bg-gradient-text-red {
  background: var(--gradient, linear-gradient(90deg, #FA5D72 0%, #c83131 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; 
}

.replace-button:hover {
  background: var(--gradient, linear-gradient(90deg, #FA5D72 0%, #c83131 100%));
  color: #ffffff; 
}

.replace-button:hover span {
  color: #ffffff !important; 
  -webkit-text-fill-color: #ffffff !important;
}

.replace-button:hover i {
  color: #ffffff; 
  -webkit-text-fill-color: #ffffff;
}

.replace-button i, .replace-button span {
  background: var(--gradient, linear-gradient(90deg, #FA5D72 0%, #c83131 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; 
  color: transparent;
}


.text-compte-title{
  color: #2C2C2C;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; 
}

.text-compte-poste{
  color: #2C2C2C;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  opacity: 0.7;
  margin-top: 5px;
}
.pending {
  opacity: 0.5;
}

.title-main-account{
  color: #2C2C2C;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; 
}

.text-counter-account{
  color: #000;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; 
}

.text-form-problem{
  color: #000;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 5px;
  }
  
  

  .radio-label .custom-radio {
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid #ccc;
  background: transparent;
  position: relative;
  transition: border-color 0.3s ease, background-color 0.3s ease;
}

.radio-label .custom-radio::after {
  content: '';
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: linear-gradient(90deg, #1E93FD 0%, #3153C7 100%);
}

.radio-label input:checked + .custom-radio::after {
  display: block;
}

.radio-label input:checked + .custom-radio {
  border-color: #1E93FD;
}

.radio-text {
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 140%;
  color: #333; /* Default text color */
  transition: color 0.3s ease, background 0.3s ease;
}

.radio-text.active {
  background: linear-gradient(90deg, #1E93FD 0%, #3153C7 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.animate-spin-custom {
  animation: spin 4s linear infinite;
}

.submit-button {
  border-radius: 50px;
  background: var(--gradient, linear-gradient(90deg, #1E93FD 0%, #3154C8 100%));
  transition: all 0.3s ease-in-out; 
  color: #FFF;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.submit-button:hover {
  background: linear-gradient(to right, #1d4ed8, #1e3a8a); 
}

.submit-button:disabled {
  background: linear-gradient(90deg, #d1d5db 0%, #9ca3af 100%);
  color: #6b7280;
  cursor: not-allowed;
  opacity: 0.7;
  transition: all 0.3s ease-in-out;
}

.select-problem {
  padding: 5px 16px;
  border-radius: 6px;
  border: 1px solid var(--gradient, #1E93FD);
  background-color: #FFF;
  font-size: 16px;
  font-family: Montserrat, sans-serif;
  font-style: normal; 
  font-weight: 500; 
  line-height: 140%;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%231E93FD' viewBox='0 0 24 24'%3E%3Cpath d='M7 10l5 5 5-5H7z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: calc(100% - 20px) center; 
  background-size: 20px; 
  -webkit-appearance: none;
  -moz-appearance: none;
  color: #000; 
}


@media (min-width: 800px) and (max-width: 1279px) {
  .hide-on-medium-screen {
    display: none;
  }
}

@keyframes custom-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.animate-custom-spin {
  animation: custom-spin 1s linear infinite;
  animation-play-state: running, paused; 
}

.animate-custom-spin:nth-child(odd) {
  animation-delay: 1s;
}

.proxy{
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: #DAFBEB;
  color: #01BC62;
  text-align: right;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.proxy-slow{
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: #FFD4DA;
  color: #F4002F;
  text-align: right;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}


.modal-title{
  color: #000;
  text-align: center;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.modal-desc{
  color: #000;
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.modal-list{
  color: #000;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.modal-list span {
  color: #000;
  text-align: right;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: inline-flex; 
  align-items: center;  
  gap: 8px; 
}

.copy-btn svg {
  display: inline-block; 
  vertical-align: middle; 
  width: 18px; 
  height: 18px; 
}


.text-info-modal p {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.copy-btn {
  background: linear-gradient(90deg, #3154C8 0%, #1E93FD 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  border: none;
  background-clip: text;
  cursor: pointer;
  margin-left: 8px;
  display: inline-flex;
  align-items: center;
  transition: opacity 0.2s ease;
}

.copy-btn:hover {
  opacity: 0.8;
}

.info-modal span{
  color: #000;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.text-ellipsis {
  display: inline-block; 
  max-width: 100%; 
  white-space: nowrap;
  overflow: hidden; 
  text-overflow: ellipsis;
  margin-left: 20px;
}

.text-getAccounts{
  color: #FFF;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}


.modal-label{
  color: #000;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.text-compte{
  color: #2C2C2C;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
}

.title-compte{
  color: #000;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

</style>
