<template>
  <div  :class="['relative', isMobile ? 'p-[1rem] pt-[3.5rem]' : 'p-[4rem]']"  >
    <div class="header flex justify-between items-center mb-6" >
        <button class="flex items-center text-blue-500 font-semibold">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
          </svg>
          <span class="ml-2" @click="goToPreviousStep">{{ $t('MainLayout.retour') }}</span>
        </button>
        <div :class="['absolute ', isMobile ? 'top-10 right-0 z-10 ' : 'top-8 right-8 z-10 ']" >
            <div 
                class="bg-white custom-border text-blue-700 px-4 py-1 rounded-full flex items-center shadow-md cursor-pointer"
                @click="toggleLanguageDropdown"
            >
                <img :src="currentFlag" alt="Langue" class="w-8 h-8 mr-2" />
                <i class="fas fa-chevron-down"></i>
            </div>

              <div 
                v-if="showLanguageDropdown" 
                class="absolute right-0 mt-2 bg-white shadow-lg rounded-lg p-2 w-32"
              >
                <button
                @click="setLanguage('fr')"
                class="flex items-center justify-start w-full px-2 py-1 hover:bg-blue-100 rounded-md transition"
                >
                <img src="../../assets/france-flag.png" alt="Français" class="w-6 h-6 mr-2" />
                Français
                </button>
                <button
                @click="setLanguage('en')"
                class="flex items-center justify-start w-full px-2 py-1 hover:bg-blue-100 rounded-md transition"
                >
                <img src="../../assets/uk-flag.png" alt="English" class="w-6 h-6 mr-2" />
                English
                </button>
            </div>
          </div>
      </div>
      <div class="flex items-center">
        <h1 class="main-title-addon">
          {{$t('checkout.addAddons')}}
          <span class="faculatif-title-addon">{{$t('checkout.optional')}}</span>
        </h1>
        <img v-if="!isMobile" src="../../assets/video.png" alt="Camera Icon" class="w-6 h-6 ml-5 Camera_Icon" @click="openModal(0)" />
      </div>

      <div class="bg-blue-info p-6 rounded-lg mb-6 mt-12 text-center relative max-w-[90%] mx-auto">
      <p class="info-text text-lg grid grid-cols-[auto,1fr] items-center gap-2">
        <i class="fas fa-info-circle text-gray-500 mr-2"></i>
        {{ $t('checkout.compatibilityMessage') }}
      </p>
    </div>
    <div v-if="!isMobile">
    <div 
      v-for="(addon, index) in addons"
      :key="addon.id"
      class="addon-item flex border border-gray-200 bg-white shadow hover:shadow-lg transition relative hover:border-blue-500 mt-10 mb-5 w-[90%] mx-auto min-h-[200px]"
      style="transition: border-color 0.3s; font-size: 0.875rem;"
    >
      <label
        :for="'addon-' + index"
        class="relative flex items-center justify-center p-4 cursor-pointer"
        style="border-radius: 1.5rem 0 0 1.5rem; background: linear-gradient(90deg, #1E93FD 0%, #3153C7 100%); min-width: 200px;"
        @click="handleDisabledClick(index)"
      >
      <input
          type="checkbox"
          :id="'addon-' + index"
          v-model="selectedAddons"
          :value="addon"
          :disabled="isDisabled(index)"
          class="styled-checkbox absolute left-[-40px] form-checkbox w-7 h-7 bg-white shadow-md"
          @change="handleAddonSelection(index)"
        />

        <img
          :style="{ padding: index === 3 ? '3rem' : '1rem' }"
          :src="addon.image"
          alt="Addon Logo"

        />
      </label>

      <label
        :for="'addon-' + index"
        class="addon-label flex items-center px-4 py-4 cursor-pointer"
        @click="handleDisabledClick(index)"
      >
        <div class="flex-grow p-2">
          <h2 class="title-addon mb-3">{{ addon.name }}</h2>
          <p class="text-addon">{{ addon.description }}</p>
        </div>

        <div class="prices-wrapper flex items-center h-full relative">
          
          <div class="border-l border-gray-300 pl-4 flex flex-col justify-center text-right h-full w-full">
            <p class="price-addon">
              <span class="price-detail-addon">{{ addon.price }}{{ getCurrencySymbol(paymentData.currency) }}</span>
              <span class="price-month-addon"><template v-if="addon.mode !== 'one_time'">
                {{ $t('checkout.pricePerMonth2') }}
              </template>/profil</span>
            </p>
            <p class="price-monthly mt-2">
              <span>{{$t('checkout.pricePerMonth1')}} {{ addon.totalPrice }}{{ getCurrencySymbol(paymentData.currency) }}</span><template v-if="addon.mode !== 'one_time'">{{ $t('checkout.pricePerMonth2') }}</template>
            </p>
            <p v-if="index === 0 && isSelected(index)" class="text-info mt-2 flex items-center grid grid-cols-[auto,1fr] items-center gap-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none" >
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.94681 1C3.21476 1 1 3.21476 1 5.94681C1 8.67886 3.21476 10.8936 5.94681 10.8936C8.67886 10.8936 10.8936 8.67886 10.8936 5.94681C10.8936 3.21476 8.67886 1 5.94681 1ZM0 5.94681C0 2.66248 2.66248 0 5.94681 0C9.23114 0 11.8936 2.66248 11.8936 5.94681C11.8936 9.23114 9.23114 11.8936 5.94681 11.8936C2.66248 11.8936 0 9.23114 0 5.94681ZM5.44727 3.94727C5.44727 3.67112 5.67112 3.44727 5.94727 3.44727H5.95277C6.22891 3.44727 6.45277 3.67112 6.45277 3.94727V3.95277C6.45277 4.22891 6.22891 4.45277 5.95277 4.45277H5.94727C5.67112 4.45277 5.44727 4.22891 5.44727 3.95277V3.94727ZM6.44727 5.94727C6.44727 5.67112 6.22341 5.44727 5.94727 5.44727C5.67112 5.44727 5.44727 5.67112 5.44727 5.94727V8.36807C5.44727 8.64421 5.67112 8.86807 5.94727 8.86807C6.22341 8.86807 6.44727 8.64421 6.44727 8.36807V5.94727Z" fill="black"/>
              </svg>
              <span class="ml-2" v-html="$t('checkout.paymentAfterSubscription')"></span>
            </p>
          </div>
        </div>
      </label>
      <img
        v-if="index != 3"
        :style="{ padding: index === 3 ? '2rem' : '' }"
        src="../../assets/video.png" 
        alt="Camera Icon" 
        class="camera-icon absolute top-4 right-4 z-50 cursor-pointer"
        style="top:1rem;right:1.5rem;"
        @click.stop="openModal(index+1)"
      />

    </div>
  </div>
  <div v-if="isMobile">
    <div 
      v-for="(addon, index) in addons"
      :key="addon.id"
      
      :class="[
        'addon-item-mobile flex items-start bg-white rounded-lg shadow-md p-4 mb-6 mx-auto w-full border border-blue-500 relative',
        selectedAddons.includes(addon) ? 'outline outline-4 outline-blue-500' : ''
      ]"
      style="box-shadow: rgba(30, 147, 253, 0.2) 0px 10px 40px;"
    >
      <label :for="'addon-' + index" @click="handleDisabledClick(index)">
      <input
        type="checkbox"
        :id="'addon-' + index"
        v-model="selectedAddons"
        :value="addon"
        :disabled="isDisabled(index)"
        class="styled-checkbox absolute left-[-40px] top-1/2 transform -translate-y-1/2"
        style="width: 30px; height: 30px;"
        @change="handleAddonSelection(index)"
      />

      <div class="flex-1 space-y-5">
        <div class="addon-image bg-gradient-to-r from-blue-400 to-blue-600 rounded-lg mb-4 flex justify-center items-center h-[100px]">
          <img :src="addon.image" alt="Addon Icon"  />
        </div>
        <h2 class="title-addon text-center mb-2">{{ addon.name }}</h2>
        <p class="text-addon text-center mb-4">{{ addon.description }}</p>
        <div class="price-info text-center">
          <p class="price-addon text-lg font-bold mb-1">
            <span class="price-detail-addon">{{ addon.price }}{{ getCurrencySymbol(paymentData.currency) }}</span>
            <span class="price-month-addon text-sm font-normal"><template v-if="addon.mode !== 'one_time'">
                {{ $t('checkout.pricePerMonth2') }}
              </template>/profil</span>
          </p>
          <p class="price-monthly bg-gray-100 rounded-md px-2 py-1 inline-block">
            <span>{{$t('checkout.pricePerMonth1')}} {{ addon.totalPrice }}{{ getCurrencySymbol(paymentData.currency) }}</span><template v-if="addon.mode !== 'one_time'">{{ $t('checkout.pricePerMonth2') }}</template>
          </p>
          <p v-if="index === 0 && isSelected(index)" class="text-info mt-2 flex justify-center items-center gap-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none" >
              <path fill-rule="evenodd" clip-rule="evenodd" d="M5.94681 1C3.21476 1 1 3.21476 1 5.94681C1 8.67886 3.21476 10.8936 5.94681 10.8936C8.67886 10.8936 10.8936 8.67886 10.8936 5.94681C10.8936 3.21476 8.67886 1 5.94681 1ZM0 5.94681C0 2.66248 2.66248 0 5.94681 0C9.23114 0 11.8936 2.66248 11.8936 5.94681C11.8936 9.23114 9.23114 11.8936 5.94681 11.8936C2.66248 11.8936 0 9.23114 0 5.94681ZM5.44727 3.94727C5.44727 3.67112 5.67112 3.44727 5.94727 3.44727H5.95277C6.22891 3.44727 6.45277 3.67112 6.45277 3.94727V3.95277C6.45277 4.22891 6.22891 4.45277 5.95277 4.45277H5.94727C5.67112 4.45277 5.44727 4.22891 5.44727 3.95277V3.94727ZM6.44727 5.94727C6.44727 5.67112 6.22341 5.44727 5.94727 5.44727C5.67112 5.44727 5.44727 5.67112 5.44727 5.94727V8.36807C5.44727 8.64421 5.67112 8.86807 5.94727 8.86807C6.22341 8.86807 6.44727 8.64421 6.44727 8.36807V5.94727Z" fill="black"/>
            </svg>
            <span class="ml-2" v-html="$t('checkout.paymentAfterSubscription')"></span>
          </p>
        </div>
      </div>
    </label>
  </div>
</div>


      <div class="footer mt-8 text-center mt-5">
        <button
        class="button-continu text-[20px] text-white px-8 py-4 rounded-full font-semibold w-4/5 mx-auto"
        @click="proceedToPayment"

        >
          {{ $t('checkout.continueButton') }}
        </button>
      </div>
    </div>
    <div 
      v-if="showToast" 
      class="fixed bottom-5 left-1/2 transform -translate-x-1/2 px-4 py-2 bg-gray-800 text-white rounded shadow-lg transition-opacity"
      style="z-index: 9999;"
    >
      {{ toastMessage }}
    </div>



    <div class="w-full flex flex-col items-center">
      <div
        v-if="showModal"
        @click.self="closeModal"
        class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
      >
        <div class="bg-white rounded-lg overflow-hidden relative w-full max-w-3xl mx-4 sm:mx-6 lg:mx-8">
          <button
            @click="closeModal"
            class="absolute top-4 right-4 z-50 text-white hover:text-gray-300"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-8 w-8"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
          <div class="relative" style="padding-bottom: 56.25%; height: 0;">
            <iframe
              :src="embedUrl"
              frameborder="0"
              allow="autoplay; encrypted-media"
              allowfullscreen
              class="absolute top-0 left-0 w-full h-full"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </template>
 <script>
 import axios from '../../plugins/axios';
 import franceFlag from '../../assets/france-flag.png';
 import ukFlag from '../../assets/uk-flag.png';
 
 export default {
   name: 'CheckoutViewV2',
   data() {
     return {
       addons: [],
       selectedAddons: [],
       isLoading: true,
       paymentData: {
         quantity: 1,
         type: '',
         totalPrice: 0,
         unitPrice: 0,
         currency: '',
         priceId: '',
       },
       flags: {
         fr: franceFlag,
         en: ukFlag,
       },
       currentLanguage: localStorage.getItem('langue'),
       isMobile: false,
       showLanguageDropdown: false,
       showToast: false,
       toastMessage: "",
       showModal: false,
       tempo_api: [],
       tableVideo: {
        fr: [
          'https://youtu.be/ScjtLAh__DM',
          'https://youtu.be/gOHYYsbP9l0',
          'https://youtu.be/ADvvjAEaS_4',
          'https://youtu.be/YL5Q4YfyjLU',
          'https://youtu.be/R0zMjndr-2o',
        ],
        en: [
          'https://youtu.be/mY87Jfk_Qr8',
          'https://youtu.be/pDAvf8HfTIg',
          'https://youtu.be/1Xr72VJvGXs',
          'https://youtu.be/2QFcycNL2wo',
          'https://youtu.be/obG_HxBTGOQ',
        ],
      },
     };
   },
   computed: {
     currentFlag() {
        const languageKey = this.currentLanguage.split('-')[0];
      return this.flags[languageKey] || this.flags.en; 
    },
    localizedTableVideo() {
      const language = this.$i18n.locale || 'fr'; 
      return this.tableVideo[language] || this.tableVideo['fr']; 
    },
   },
   methods: {

    isSelected(index) {
      return index === 0 && this.selectedAddons.includes(this.addons[0]);
    },
    openModal(index) {
      console.log(index);
      const videoUrl = this.localizedTableVideo[index];
      if (videoUrl) {
        const videoId = this.extractYouTubeId(videoUrl);
        if (videoId) {
          this.embedUrl = `https://www.youtube.com/embed/${videoId}?autoplay=1`;
          this.showModal = true;
        } else {
          console.error('ID de vidéo YouTube non trouvé.');
        }
      } else {
        console.error('URL de vidéo non définie pour cet index.');
      }
    },
    closeModal() {
      this.showModal = false;
      this.embedUrl = '';
    },
    extractYouTubeId(url) {
      const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
      const match = url.match(regExp);
      return (match && match[2].length === 11) ? match[2] : null;
    },
    updateIsMobile() {
        this.isMobile = window.innerWidth <= 768; 
      },
    handleAddonSelection(selectedIndex) {
      if (selectedIndex === 0 || selectedIndex === 1) {
        const selectedAddon = this.addons[selectedIndex];
        const otherIndex = selectedIndex === 0 ? 1 : 0;
        const otherAddon = this.addons[otherIndex];
        if (this.selectedAddons.includes(selectedAddon)) {
          otherAddon.disabled = true;
        } else {
          otherAddon.disabled = false;
        }
      }
    },
    handleDisabledClick(index) {
      if (this.isDisabled(index)) {
        this.showToastMessage(this.$t('checkout.automationToolLimit'));
      }
    },
    showToastMessage(message) {
      this.toastMessage = message;
      this.showToast = true;
      setTimeout(() => {
        this.showToast = false;
        this.toastMessage = '';
      }, 3000); 
    },
    isDisabled(index) {
      return this.addons[index]?.disabled || false;
    },
    getCurrencySymbol(currency) {
      if (!currency) return '';
      const symbols = {
        eur: '€',
        usd: '$',
      };
      return symbols[currency.toLowerCase()] || currency.toUpperCase();
    },
    toggleLanguageDropdown() {
      this.showLanguageDropdown = !this.showLanguageDropdown;
    },
    setLanguage(lang) {
      this.currentLanguage = lang;
      this.$i18n.locale = lang;
      this.showLanguageDropdown = false;
      localStorage.setItem('langue', lang)
      localStorage.setItem('lang', lang);
      
      const currencyMap = {
        eur: 'EU',
        usd: 'US',
      };
      const currencyLabel = currencyMap[this.paymentData.currency.toLowerCase()];

      const filteredProducts = this.tempo_api.filter(product => product.prices.price_label === currencyLabel);
      const totalQuantity = Array.isArray(this.paymentData)
            ? this.paymentData.reduce((acc, item) => acc + item.quantity, 0)
            : this.paymentData.quantity || 0;
      this.addons = filteredProducts.map((product, index) => {
        const unitAmount = product.prices.unit_price || 0;
        this.isLoading = false;

        const addonId = `${product.nameUS || product.nameEU}_${product.prices.price_label}`;

        return {
          id: addonId,
          name: this.currentLanguage.startsWith('fr') ? product.nameEU : product.nameUS,
          description: this.currentLanguage.startsWith('fr') ? product.descriptionEU : product.descriptionUS,
          price: unitAmount,
          image: product.images,
          totalPrice: unitAmount * totalQuantity,
          logo: `../assets/addon-logo-${index + 1}.png`,
          priceId: null, 
          mode: product.prices.mode,
          type: product.prices.type,
        };
      });
      console.log(this.addons);
    },
    async fetchAddons() {
      try {
        const response = await axios.get('/api/products-prices?type=addon');
        const products = response.data.products;
        this.tempo_api =response.data.products;
        console.log(response.data.products);
        if (!products || !Array.isArray(products)) {
          console.error('Invalid products data:', products);
          return;
        }

        if (!this.paymentData.currency) {
          console.error('Payment currency not set.');
          return;
        }

        const currencyMap = {
          eur: 'EU',
          usd: 'US',
        };
        const currencyLabel = currencyMap[this.paymentData.currency.toLowerCase()];

        const filteredProducts = products.filter(product => product.prices.price_label === currencyLabel);
        
        
        

        this.addons = filteredProducts.map((product, index) => {
          const unitAmount = product.prices.unit_price || 0;
          this.isLoading = false;

          const addonId = `${product.nameUS || product.nameEU}_${product.prices.price_label}`;

          const totalQuantity = Array.isArray(this.paymentData)
            ? this.paymentData.reduce((acc, item) => acc + item.quantity, 0)
            : this.paymentData.quantity || 0;

          return {
            id: addonId,
            name: this.currentLanguage.startsWith('fr') ? product.nameEU : product.nameUS,
            description: this.currentLanguage.startsWith('fr') ? product.descriptionEU : product.descriptionUS,
            price: unitAmount,
            image: product.images,
            totalPrice: unitAmount * totalQuantity,
            logo: `../assets/addon-logo-${index + 1}.png`,
            priceId: null, 
            mode: product.prices.mode,
            type: product.prices.type,
          };
        });
        const storedPaymentData = localStorage.getItem("paymentDataFulltempo");
        console.log(storedPaymentData)
        if (storedPaymentData != "null") {
          const data = JSON.parse(storedPaymentData);
          this.selectedAddons = data;
          this.selectedAddons.forEach((item) => {
              if (item.name === "HeyReach Business") {
                this.addons[1].disabled = true;
              } else if (item.name === "Waalaxy Business") {
                this.addons[0].disabled = true;
              } 
          });
          

        } 
      } catch (error) {
        console.error('Erreur lors de la récupération des add-ons :', error);
      }
    },
     goToPreviousStep() {
      const currentStep = 1;

        localStorage.setItem('currentStep', currentStep);

        const stepEvent = new StorageEvent('storage', {
          key: 'currentStep',
          newValue: currentStep.toString(),
        });

        window.dispatchEvent(stepEvent);
       this.$router.push('/checkout/v1');
     },
     proceedToPayment() {
      this.selectedAddons = [...new Set(this.selectedAddons)];
      this.selectedAddons = this.selectedAddons.reduce((unique, addon) => {
        if (!unique.some(item => item.id === addon.id)) {
          unique.push(addon);
        }
        return unique;
      }, []);

      let totalQuantity = 0;
      if (Array.isArray(this.paymentData)) {
        totalQuantity = this.paymentData.reduce((acc, item) => acc + item.quantity, 0);
      } else {
        totalQuantity = this.paymentData.quantity;
      }

      const selectedAddons = this.selectedAddons.map((addon) => ({
        id: addon.id,
        name: addon.name,
        price: addon.price,
        totalPrice: addon.price * totalQuantity,
        priceId: addon.priceId,
      }));

      const paymentDataWithAddons = {
        paymentData: this.paymentData,
        addons: selectedAddons,
      };

      console.log('Données complètes pour le paiement :', paymentDataWithAddons);

      let baseItems = [];
      if (Array.isArray(this.paymentData)) {
        baseItems = this.paymentData.map((line) => ({
          name: line.type,
          currency: line.currency,
          quantity: line.quantity,
        }));
      } else {
        baseItems = [
          {
            name: this.paymentData.type,
            currency: this.paymentData.currency,
            quantity: this.paymentData.quantity,
          },
        ];
      }

      const addonItems = selectedAddons.map((addon) => ({
        priceId: addon.id, 
        currency: baseItems[0]?.currency || 'EUR', 
        quantity: totalQuantity, 
      }));

      const apiData = {
        items: [
          ...baseItems,
          ...addonItems,
        ],
      };

      const paymentInfoStripe = {
        paymentData: this.paymentData,    
        paymentDataWithAddons,           
        apiData,                          
        currency: baseItems[0]?.currency || 'EUR',
      };

      console.log('Données complètes pour Stripe et autres :', paymentInfoStripe);

      localStorage.setItem('paymentDataFull', JSON.stringify(paymentInfoStripe));
      localStorage.setItem('paymentDataFulltempo', JSON.stringify(this.selectedAddons));

      const Event = new StorageEvent('storage', {
        key: 'paymentDataFull',
        newValue: JSON.stringify(paymentInfoStripe),
      });
      window.dispatchEvent(Event);

      const currentStep = 3;
      localStorage.setItem('currentStep', currentStep);
      const stepEvent = new StorageEvent('storage', {
        key: 'currentStep',
        newValue: currentStep.toString(),
      });
      window.dispatchEvent(stepEvent);

      this.$router.push('/checkout/v3');
    },
    initializePaymentData() {
      const storedPaymentData = localStorage.getItem('paymentData');
      if (storedPaymentData) {
        const data = JSON.parse(storedPaymentData);
        console.log('Données initiales de paiement :', data);
        this.paymentData = data;

        if (!this.paymentData.currency) {
          this.paymentData.currency = 'eur'; 
        }
      } else {
        console.error('No payment data found in localStorage.');
      }
    },
  },
  mounted() {
    this.initializePaymentData();
    this.fetchAddons();
    this.updateIsMobile();
    window.addEventListener("resize", this.updateIsMobile);
  },
 };
 </script>
 

  <style scoped>

.button-continu {
      width: 40%;
      background: linear-gradient(90deg, #1E93FD 0%, #3154C8 100%);
      box-shadow: 0 4px 10px rgba(30, 147, 253, 0.3);
      transition: background 1s ease, box-shadow 1s ease; 
    }

    .button-continu:hover {
      background: linear-gradient(90deg, #3154C8 0%, #3154C8 100%);
      box-shadow: 0 4px 10px rgba(30, 147, 253, 0.3);
    }
  .checkout-v2-container {
    font-family: 'Montserrat', sans-serif;
  }

  .prices-wrapper {
      flex-grow: 1; 
      display: flex;
      justify-content: center; 
      align-items: center; 
      min-width: 200px;
      max-width: 200px;
    }

  .prices-wrapper .border-l {
      width: 100%; 
      max-width: 300px; 
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; 
    }

  .addon-item {
    border-radius: 1.5rem;
    transition: all 0.3s ease;
  }
  .addons-list .addon-item:hover {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }
  .addon-item label:first-child {
    flex: 0 0 150px; 
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(90deg, #1E93FD 0%, #3153C7 100%);
    border-radius: 1.5rem 0 0 1.5rem;
  }
  .addon-item label:first-child img {
    max-width: 100%;
    height: auto;
    object-fit: contain;
  }

  .addon-item .price-container {
    flex: 0 0 300px; 
    max-width: 300px;
    border-left: 1px solid #ccc; 
    padding-left: 20px; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: right;
    height: 100%; 
  }

  .addon-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }


  .addon-label .price-container {
    flex: 0 0 300px; 
    max-width: 300px; 
    text-align: right; 
  }

  .title-addon{
    color: #000;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; 
  }

  .text-addon{
    color: #000;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; 
  }

  .fade-enter-active,
.fade-leave-active {
  transition: opacity 0.7s ease-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}


.price-addon{
  color: #000;
  font-family: Montserrat;
  font-style: normal;
  line-height: 140%;
  }


  .price-month-addon{
    font-size: 10px;
    font-weight: 400;
  }
  .price-detail-addon{
    font-size: 20.104px;
    font-weight: 700;
  }

  .price-monthly{
    padding: 10px;
    border-radius: 5px;
    background: #F4F4F4;
    color: #000;
    font-family: Montserrat;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }

  .price-monthly span{
    font-size: 12px;
    font-weight: 600;
  }
  .info-text{
      text-align: left;
      color: rgb(107 114 128 / var(--tw-text-opacity));
      font-family: Montserrat;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .info-text i{
      font-size: 18px;
    }
    .bg-blue-info{
      background: rgba(227, 233, 240, 0.248);
      border-radius: 3px;
    }


    .text-info{
      color: #000;
      font-family: Inter;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-align: left;
    }

/* Mobile */
@media (max-width: 767px) {
  .addon-item {
    width: 90%;
    margin: 10px auto;
    font-size: 0.875rem;
  }
  .addon-item label:first-child {
    flex: 0 0 100px; 
  }
  .addon-item .price-container {
    max-width: none;
    width: 100%;
    border-left: none;
    padding-left: 0;
  }

  .addon-label .price-container {
    flex: 0 0 200px;
    max-width: 200px;
  }

  .addon-item .title-addon {
    font-size: 16px;
    font-weight: 600;
  }

  .addon-item .text-addon {
    font-size: 12px;
    font-weight: 400;
  }

  .addon-item .price-addon .price-detail-addon {
    font-size: 16px;
    font-weight: 700;
  }

  .addon-item .price-monthly {
    font-size: 10px;
    font-weight: 400;
  }

  .addon-item label {
    min-width: 150px;
  }
}

/* Tablette */
@media (min-width: 768px) and (max-width: 1023px) {
  .addon-item {
    width: 90%;
    margin: 15px auto;
    font-size: 1rem;
  }

  .addon-label .price-container {
    flex: 0 0 250px;
    max-width: 250px;
  }

  .addon-item label:first-child {
    flex: 0 0 140px; 
  }
  .addon-item .price-container {
    max-width: 260px;
  }

  .addon-item .title-addon {
    font-size: 18px;
    font-weight: 600;
  }

  .addon-item .text-addon {
    font-size: 14px;
    font-weight: 400;
  }

  .addon-item .price-addon .price-detail-addon {
    font-size: 18px;
    font-weight: 700;
  }

  .addon-item .price-monthly {
    font-size: 12px;
    font-weight: 400;
  }

  .addon-item label {
    min-width: 180px;
  }
}

/* Desktop */
@media (min-width: 1024px) {
  .addon-item {
    width: 90%;
    margin: 20px auto;
    font-size: 1.125rem;
  }

  .price-month-addon{
    font-size: 15px;
    font-weight: 400;
  }

  .addon-label .price-container {
    flex: 0 0 350px; 
    max-width: 350px;
  }

  .addon-item label:first-child {
    flex: 0 0 200px; 
  }
  .addon-item .price-container {
    max-width: 350px; 
  }

  .addon-item .title-addon {
    font-size: 20px;
    font-weight: 600;
  }

  .addon-item .text-addon {
    font-size: 16px;
    font-weight: 400;
  }

  .addon-item .price-addon .price-detail-addon {
    font-size: 30px;
    font-weight: 700;
  }

  .addon-item .price-monthly {
    font-size: 15px;
    font-weight: 400;
  }
  
  .price-monthly span {
    font-size: 15px;
  }



  .text-info{
      font-size: 12px;
    }
}

    .styled-checkbox {
      appearance: none;
      -webkit-appearance: none;
      border: 2px solid var(--gradient, #1E93FD);
      background: transparent;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s ease, border-color 0.3s ease;
    }

    .styled-checkbox:checked {
      background: linear-gradient(90deg, #1E93FD 0%, #3153C7 100%);
      border: 0px;
      border-radius: 5px;
    }

    .styled-checkbox:checked::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
      width: 5px;
      height: 9px;
      border: solid white;
      border-width: 0 2px 2px 0;
      display: block;
      transition: transform 0.2s ease;
    }

    .styled-checkbox:hover {
      border-color: #1E93FD;
    }

    .styled-checkbox:disabled {
      cursor: not-allowed;
      opacity: 0.5;
      background-color: #f0f0f0; 
    }

    .main-title-addon{
      color: #000;
      font-family: Montserrat;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%; 
    }

    .faculatif-title-addon{
      color: #C0C0C0;
      font-family: Montserrat;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
    }


   

    .custom-border{
      border-radius: 62px;
      border: 1px solid var(--gradient, #1E93FD);
    }

    .addon-label .flex.items-center.h-full {
      height: 100%;
    }

    .camera-icon {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 24px; 
      height: 24px;
      object-fit: contain;
    }
    .addon-item-mobile{
      width: 80%;
      transition: outline 0.1s ease;

    }

    .Camera_Icon{
      cursor: pointer;
    }

   
  </style>
  