<template>
  <div class="w-full flex flex-col items-center">
    <div class="header w-full">
      <div class="flex justify-between items-center w-full mb-4">
        <h1 class="title-main-account">{{ $t('settings.parameters') }}</h1>
        

      </div>
      <div class="mt-4 mb-6 border-t border-blue-200"></div>
    </div>

    <div class="w-full flex flex-col items-center">
      <div class="my-account-section mt-8 flex flex-col gap-6 p-6 bg-white rounded-lg shadow-lg w-full max-w-4xl">
      <h2 class="title-settings mb-4">{{ $t('settings.myAccount') }}</h2>
      
      <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div class="flex items-center justify-between">
          <div class="flex flex-col gap-3">
            <span class="text-settings">{{ $t('settings.email') }}:</span>
            <span class="link-settings">{{ accountInfo.email }}</span>
          </div>
        </div>

        <div class="flex items-center justify-between">
          <div class="flex flex-col  gap-3">
            <span class="text-settings">{{ $t('settings.company') }}:</span>
            <span class="link-settings">{{ accountInfo.entreprise }}</span>
          </div>
          <button @click="openEditModal" class="link-settings  " :class="{'mr-[35%]': !isMobile}">
            <i class="fas fa-edit"></i>
          </button>
        </div>
        
        <div class="flex items-center justify-between">
          <div class="flex flex-col  gap-3">
            <span class="text-settings">{{ $t('settings.password') }}:</span>
            <span class="link-settings">********</span>
          </div>
          <button @click="goToSetPassword" class="link-settings  " :class="{'mr-[35%]': !isMobile}">
            <i class="fas fa-edit"></i>
          </button>
        </div>
        

        <div class="flex items-center justify-between">
          <div class="flex flex-col gap-3">
            <span class="text-settings">{{ $t('settings.language') }}:</span>
            <select
              v-model="displayLanguage"
              @change="changeLanguage"
              class="border rounded px-2 py-1 link-settings"
            >
              <option value="Français">Français</option>
              <option value="English">English</option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <!-- Section "Intégrations" -->
    <!-- <div class="integrations-section mt-8 flex flex-col gap-6 p-6 bg-white rounded-lg shadow-lg w-full max-w-4xl" >
      <h2 class="title-settings mb-4">{{ $t('settings.integrations') }}</h2>
      
      <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div class="flex items-center justify-between">
          <div class="flex flex-col gap-3">
            <span class="text-settings">{{ $t('settings.apiKey') }}:</span>
            <span class="link-settings">
              {{ showApiKey ? apiKey : '**** **** **** ****' }}
            </span>
          </div>
          <button @click="toggleApiKey" class="link-settings underline " :class="{'mr-[35%]': !isMobile}">
            <i class="fas fa-eye"></i>
          </button>
        </div>
      </div>
    </div> -->
    </div>
  </div>

  <div
      v-if="showModal"
      class="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50"
    >
      <div class="bg-white p-6 rounded shadow-md w-96">
        <h2 class="text-lg font-semibold mb-4">{{ $t('settings.editName') }}</h2>
        <form @submit.prevent="updateName">
          <label for="name" class="block text-sm font-medium text-gray-700">
            {{ $t('settings.newName') }}
          </label>
          <input
            v-model="newName"
            id="name"
            type="text"
            placeholder="{{ $t('settings.namePlaceholder') }}"
            required
            class="mt-1 w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-bleu-mirrorProfiles focus:border-bleu-mirrorProfiles"
          />
          <div class="flex justify-end mt-4">
            <button
              type="button"
              @click="closeModal"
              class="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 mr-2"
            >
              {{ $t('settings.cancel') }}
            </button>
            <button
              type="submit"
              class="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              {{ $t('settings.save') }}
            </button>
          </div>
        </form>
      </div>
    </div>
    <div 
      v-if="showToast" 
      :class="[
        'fixed top-5 left-1/2 transform -translate-x-1/2 px-6 py-3 text-white font-semibold rounded-lg shadow-lg transition-opacity duration-300',
        toastType === 'success' ? 'bg-green-500' : 'bg-red-500'
      ]"
      style="z-index: 9999;"
    >
      <i 
        :class="toastType === 'success' ? 'fas fa-check-circle mr-2' : 'fas fa-exclamation-circle mr-2'"
      ></i>
      {{ toastMessage }}
    </div>
</template>

  
<script>
import api from '../plugins/axios';

export default {
  name: 'SettingsView',
  data() {
    return {
      accountInfo: {
        email: '',
        mot_de_passe: '',
        entreprise: '',
        langue: this.$i18n.locale === 'fr' ? 'fr-FR' : 'en-US' 
      },
      showModal: false,
      newName: "",
      apiKey: '',
      showApiKey: false,
      isMobile: false,
      toastMessage: '',
      showToast: false,
      toastType: 'success',
    };
  },
  computed: {
    displayLanguage: {
      get() {
        return this.accountInfo.langue === 'fr-FR' ? 'Français' : 'English';
      },
      set(value) {
        this.accountInfo.langue = value === 'Français' ? 'fr-FR' : 'en-US';
        this.changeLanguage();
      }
    }
  },
  methods: {
    showToastMessage(message) {
        this.toastMessage = message;
        this.showToast = true;

        setTimeout(() => {
          this.showToast = false;
          this.toastMessage = '';
        }, 3000);
      },
    openEditModal() {
      this.newName = this.accountInfo.entreprise; 
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    async updateName() {
      try {
        await api.post("/api/update-name", {
          Nom: this.newName,
        });

        this.accountInfo.entreprise = this.newName; 
        this.closeModal(); 
        this.toastType = "success";
        this.showToastMessage(this.$t('settings.nameUpdateSuccess'));

      } catch (error) {
        this.toastType = "error";
        this.showToastMessage(this.$t('settings.nameUpdateError'));

      }
    },

    goToSetPassword() {
      this.$router.push({ name: 'SetPassword' });
    },
    updateIsMobile() {
      this.isMobile = window.innerWidth <= 768; 
    },
    async fetchAccountInfo() {
      try {
        const response = await api.get('/api/account');
        this.accountInfo = {
          ...response.data,
          langue: response.data.langue || (this.$i18n.locale === 'fr' ? 'fr-FR' : 'en-US')
        };
      } catch (error) {
        console.error('Error fetching account information:', error);
      }
    },
    async fetchApiKey() {
      try {
        const response = await api.get('/api/integrations/api-key');
        this.apiKey = response.data.api_key;
      } catch (error) {
        console.error('Error fetching API key:', error);
      }
    },
    toggleApiKey() {
      if (!this.showApiKey && !this.apiKey) {
        this.fetchApiKey();
      }
      this.showApiKey = !this.showApiKey; 
    },
    changeLanguage() {
      const newLang = this.accountInfo.langue === 'fr-FR' ? 'fr' : 'en';
      this.$i18n.locale = newLang;
      localStorage.setItem('lang', newLang);
      localStorage.setItem('langue', newLang);

      this.updateAccountLanguage();
    },
    async updateAccountLanguage() {
      try {
        await api.post('/api/account/language', { 
          email: this.accountInfo.email, 
          newLanguage: this.accountInfo.langue 
        });
        this.toastType = "success";
        this.showToastMessage(this.$t('settings.languageUpdateSuccess'));
      } catch (error) {
        this.toastType = "error";
        this.showToastMessage(this.$t('settings.languageUpdateError'));      }
    }

  },
  async mounted() {
    await this.fetchAccountInfo();
    this.updateIsMobile();
    window.addEventListener("resize", this.updateIsMobile);
  }
};
</script>

  
  <style scoped>
  .settings-container {
    margin: auto;
  }
  .my-account-section,
  .integrations-section {
    max-width: 80%;
    padding: 2rem;
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 10px 40px 0px rgba(30, 147, 253, 0.20);
    
  }


  .title-settings{
    color: #2C2C2C;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; 
  }

  .text-settings{
    color: #000;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .link-settings{
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background: var(--gradient, linear-gradient(90deg, #1E93FD 0%, #3154C8 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .link-settings i{
    font-size: 1.5rem;
  }

  </style>
  