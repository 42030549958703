<template>
  <div class="w-full flex flex-col items-center">
    <div class="header w-full mb-3">
      <div class="flex justify-between items-center w-full mb-4">
        <h1 class="title-main-academy">{{ $t('academy.title') }}</h1>
      </div>
      
      <div class="mt-4 mb-3 border-t border-blue-200"></div>
      <span class="description-academy">{{ $t('academy.description') }}</span>
    </div>
  
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 p-4">
      <div
        v-for="module in modules"
        :key="module.id"
        class="module-card bg-white rounded-lg shadow-md p-6 flex flex-col justify-between space-y-4"
        style="box-shadow: 0px 10px 40px rgba(30, 147, 253, 0.2);"
      >
        <div>
          <div class="relative rounded-lg overflow-hidden">
            <div class="video-thumbnail" @click="openModal(module.videoUrl)">
              <img
                :src="require(`@/assets/video${module.id}.png`)"
                alt="Image du module"
                class="w-full h-auto rounded-lg"
              />
              <div
              :class="['absolute inset-0 left-0 flex justify-center', isMobile ? 'top-[40%]' : 'top-[40%]']"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  :width="isMobile ? '36' : '36'"
                  :height="isMobile ? '36' : '36'"
                  viewBox="0 0 26 30"
                  fill="white"
                >
                  <path
                    d="M26 15C26.001 15.3918 25.8981 15.7771 25.7013 16.1185C25.5046 16.46 25.2207 16.7459 24.8773 16.9485L3.59273 29.6604C3.23388 29.8749 2.82287 29.992 2.40216 29.9996C1.98145 30.0072 1.56628 29.905 1.19955 29.7036C0.836299 29.5053 0.533703 29.2162 0.322878 28.8659C0.112053 28.5156 0.000606949 28.1167 0 27.7104V2.28955C0.000606949 1.88325 0.112053 1.48444 0.322878 1.13413C0.533703 0.783825 0.836299 0.494661 1.19955 0.296379C1.56628 0.0949681 1.98145 -0.00721052 2.40216 0.000395673C2.82287 0.00800186 3.23388 0.125117 3.59273 0.339646L24.8773 13.0515C25.2207 13.2541 25.5046 13.54 25.7013 13.8815C25.8981 14.2229 26.001 14.6082 26 15Z"
                    fill="white"
                  />
                </svg>
              </div>
            </div>
            <div class="pt-0 p-6">
              <h2 class="title-academy" v-html="$t(`academy.modules.${module.id}.title`)"></h2>
              <p class="text-academy mt-4" v-html="$t(`academy.modules.${module.id}.description`)"></p>
            </div>
          </div>
        </div>
        <button
          @click="accessModule(module)"
          class="action-button-academy border px-4 py-2 rounded-full text-sm font-semibold flex items-center justify-center transition-colors"
        >
          <span>{{ $t('academy.accessButton') }}</span>
        </button>
      </div>
    </div>

    <div class="w-full flex flex-col items-center">
      <div
        v-if="showModal"
        @click.self="closeModal"
        class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
      >
        <div class="bg-white rounded-lg overflow-hidden relative w-full max-w-3xl mx-4 sm:mx-6 lg:mx-8">
          <button
            @click="closeModal"
            class="absolute top-4 right-4 z-50 text-white hover:text-gray-300"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-8 w-8"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
          <div class="relative" style="padding-bottom: 56.25%; height: 0;">
            <iframe
              :src="embedUrl"
              frameborder="0"
              allow="autoplay; encrypted-media"
              allowfullscreen
              class="absolute top-0 left-0 w-full h-full"
            ></iframe>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
export default {
  name: 'AcademyView',
  data() {
    return {
      showModal: false,
      embedUrl: '',
      isMobile: false,
      langue: localStorage.getItem('langue') || 'Unknown',
      modules: [
        {
          id: 1,
          videoUrl: {fr: 'https://www.youtube.com/watch?v=O5mVKaN-28I' , en: 'https://youtu.be/w8svnn6HTBI'}, 
          lienUrl: {fr: 'https://mirrorprofiles.com/formation/boostez-acquisition-linkedin/?utm_campaign=Display_RT_WW' , en: 'https://mirrorprofiles.com/en/formation/boostez-acquisition-linkedin/?utm_campaign=Display_RT_WW'},
        },
        {
          id: 2,
          videoUrl: {fr: 'https://youtu.be/YLSYqhM0aJU' , en: 'https://youtu.be/w8svnn6HTBI'}, 
          lienUrl:  {fr: 'https://mirrorprofiles.com/formation/expert-copywriting-linkedin/?utm_campaign=Display_RT_WW', en: 'https://mirrorprofiles.com/en/formation/expert-copywriting-linkedin/?utm_campaign=Display_RT_WW'},  
        },
        {
          id: 3,
          videoUrl:  {fr: 'https://youtu.be/Q09TeyyzvGo' , en: 'https://youtu.be/5MpwhSvNoTg'},  
          lienUrl:  {fr: 'https://mirrorprofiles.com/formation/kpi-linkedin-objectifs/?utm_campaign=Display_RT_WW' , en: 'https://mirrorprofiles.com/en/formation/kpi-linkedin-objectifs/?utm_campaign=Display_RT_WW'},  
        },
        {
          id: 4,
          videoUrl:  {fr: 'https://youtu.be/4gNamITQavA', en: 'https://youtu.be/IyANH4eEzCo'},  
          lienUrl:  {fr: 'https://mirrorprofiles.com/formation/fichier-prospection-salesnavigator/?utm_campaign=Display_RT_WW', en: 'https://mirrorprofiles.com/en/formation/fichier-prospection-salesnavigator/?utm_campaign=Display_RT_WW' },  
        },
        {
          id: 5,
          videoUrl:  {fr: 'https://youtu.be/Ksb0kTCKSKQ', en: 'https://youtu.be/5ih3f53hTI0'},  
          lienUrl:  {fr: 'https://mirrorprofiles.com/formation/gerer-reponses-linkedin/?utm_campaign=Display_RT_WW', en: 'https://mirrorprofiles.com/en/formation/gerer-reponses-linkedin/?utm_campaign=Display_RT_WW'},  
        },
        {
          id: 6,
          videoUrl:  {fr: 'https://youtu.be/CxseyCveOX8', en: 'https://youtu.be/i04eAEZcbpQ'},  
          lienUrl:  {fr: 'https://mirrorprofiles.com/formation/mirrorprofiles-autonomie/?utm_campaign=Display_RT_WW', en: 'https://mirrorprofiles.com/en/formation/mirrorprofiles-autonomie/?utm_campaign=Display_RT_WW'},  
        },
        ]
      };
    },
    mounted() {
      this.updateIsMobile();
      window.addEventListener("resize", this.updateIsMobile);
    },
    methods: {
    updateIsMobile() {
      this.isMobile = window.innerWidth <= 768; 
    },
    accessModule(module) {
      console.log(this.langue);
      console.log(module);
      const lienUrl = module.lienUrl[this.langue];
      if (lienUrl) {
        window.open(lienUrl, '_blank');
      } else {
        console.error(`Lien non défini pour la langue ${this.langue}`);
      }
    },
    openModal(videoUrl) {
      console.log(videoUrl);
      const language =this.langue;
      const videoId = this.extractYouTubeId(videoUrl[language]);
      if (videoId) {
        this.embedUrl = `https://www.youtube.com/embed/${videoId}?autoplay=1`;
        this.showModal = true;
      } else {
        console.error(`ID de vidéo YouTube non trouvé pour la langue ${language}`);
      }
    },
    closeModal() {
      this.showModal = false;
      this.embedUrl = '';
    },
    extractYouTubeId(url) {
      const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
      const match = url.match(regExp);
      return (match && match[2].length === 11) ? match[2] : null;
    },
  },
};
</script>
  
  <style>

.action-button-academy {
  margin-right: 10px;
  border: 1px solid var(--gradient, #1E93FD);
  font-family: Montserrat, sans-serif;
  font-style: normal;
  text-transform: uppercase; 
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  color: transparent;
}

.action-button-academy span {
  background: var(--gradient, linear-gradient(90deg, #1E93FD 0%, #3154C8 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; 
  color: transparent;
}


.bg-gradient-text {
  background: var(--gradient, linear-gradient(90deg, #1E93FD 0%, #3154C8 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; 
}

.action-button-academy:hover {
  background: var(--gradient, linear-gradient(90deg, #3154C8 0%, #1E93FD 100%)); 
  color: #ffffff; 
}

.action-button-academy:hover span {
  color: #ffffff !important; 
  -webkit-text-fill-color: #ffffff !important;
}

/* Style par défaut (écrans moyens) */
.title-academy {
  color: #000;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 1rem;
}

.text-academy {
  color: #000;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media (max-width: 768px) {
  .title-academy {
    font-size: 14px;
    margin-top: 0.8rem;
  }

  .text-academy {
    font-size: 12px;
  }
}

@media (min-width: 1200px) {
  .title-academy {
    font-size: 18px;
    margin-top: 1.2rem;
  }

  .text-academy {
    font-size: 14px;
  }
}


.title-main-academy{
  color: #2C2C2C;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; 
}

.description-academy{
  color: #000;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; 
}

.module-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center; 
  padding: 1.5rem;
  gap: 1rem;
  cursor: pointer;
  transition: transform 0.2s ease;
  border-radius: 8px; 
  box-shadow: 0px 10px 40px rgba(30, 147, 253, 0.2);
}

.module-card:hover {
  transform: scale(1.02); 
}

.video-thumbnail {
  position: relative; /* Permet de positionner les enfants absolus */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; /* Assure que le conteneur couvre toute l'image */
  height: 100%; /* Même hauteur que l'image */
  cursor: pointer; /* Ajoute une main au survol */
}

.video-thumbnail svg {
  position: relative; /* Relatif pour permettre un centrage naturel */
  z-index: 2; /* Assure que le SVG est au-dessus */
  width: 36px; /* Taille par défaut */
  height: 36px; /* Taille par défaut */
}

.video-thumbnail:hover svg {
  transform: scale(1.1); /* Zoom léger au survol */
  transition: transform 0.3s ease-in-out;
}

.video-thumbnail::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Ajoute un effet sombre au survol */
  z-index: 1; /* Place sous le logo */
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.video-thumbnail:hover::before {
  opacity: 1; /* Affiche l'effet sombre au survol */
}


</style>